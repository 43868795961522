import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  container: {
    padding: '32px 32px 0 32px',
    '@media(max-width: 414px)': {
      padding: '18px 0 0 0'
    }
  },
  bottomSpacer: {
    height: 100
  }
};

const ScreenPadding = ({ classes, className, children }) => (
  <div className={clsx(classes.container, className)}>
    {children}
    <div className={classes.bottomSpacer} />
  </div>
);

ScreenPadding.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  children: PropTypes.node
};

ScreenPadding.defaultProps = {
  className: '',
  children: null
};

export default withStyles(styles)(ScreenPadding);
