import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import Background from '../../assets/images/auth-background.svg';
import LogoSquare from '../../assets/images/logo-square.svg';
import Card from '../../components/card';
import theme from '../../theme';

const useStyles = makeStyles({
  pageContainer: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.colors.beige
  },
  backgroundContainer: {
    position: 'absolute',
    bottom: '30px',
    width: '100%'
  },
  backgroundImage: {
    minHeight: '35vh',
    width: '100%',
    objectFit: 'cover',
    objectPosition: '0 50%'
  },
  contentContainer: {
    height: '100%',
    width: '100%',
    overflowY: 'auto',
    zIndex: 1
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  logoContainer: {
    marginTop: '48px',
    display: 'flex',
    justifyContent: 'center',
    '@media (max-width: 414px)': {
      marginTop: '24px'
    }
  },
  logo: {
    width: '94px',
    height: '88px'
  },
  welcomeText: {
    fontSize: '40px',
    fontWeight: '100',
    color: theme.colors.pesto,
    marginTop: '40px',
    '@media (max-height: 760px)': {
      display: 'none'
    },
    '@media (max-width: 630px)': {
      display: 'none'
    }
  },
  card: {
    marginTop: '64px',
    width: '360px',
    borderRadius: '5px',
    zIndex: '1',
    backgroundColor: '#f7f3ef',
    padding: '24px',
    marginBottom: '64px',
    '@media (max-width: 414px)': {
      width: '90%',
      marginTop: '32px'
    }
  }
});

const Root = ({ children }) => {
  const styles = useStyles();

  return (
    <div className={styles.pageContainer}>
      <div className={styles.backgroundContainer}>
        <img src={Background} className={styles.backgroundImage} alt="Background" />
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.content}>
          <div className={styles.logoContainer}>
            <img src={LogoSquare} className={styles.logo} alt="Logo" />
          </div>
          <div className={styles.welcomeText}>Welcome to Branch!</div>
          <Card className={styles.card}>{children}</Card>
        </div>
      </div>
    </div>
  );
};

Root.propTypes = {
  children: PropTypes.node.isRequired
};

export default Root;
