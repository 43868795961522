import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  modalBody: {
    marginTop: 24,
    marginBottom: 48,
    marginLeft: 48,
    marginRight: 48,
    '@media(max-width: 560px)': {
      marginLeft: 24,
      marginRight: 24
    }
  }
};

function ModalBody({ classes, className, children }) {
  return <div className={clsx(classes.modalBody, className)}>{children}</div>;
}

ModalBody.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  children: PropTypes.node
};

ModalBody.defaultProps = {
  className: '',
  children: null
};

export default withStyles(styles)(ModalBody);
