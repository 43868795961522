import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import theme from '../theme';

const styles = {
  title: {
    fontSize: 20,
    color: theme.colors.turtleGreen,
    marginTop: 16,
    marginBottom: 16,
    '@media(max-width: 414px)': {
      marginLeft: 16,
      fontWeight: 'normal'
    }
  }
};

const PageSectionSubtitle = ({ classes, className, children }) => (
  <div className={className}>
    <div className={classes.title}>{children}</div>
  </div>
);

PageSectionSubtitle.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  children: PropTypes.node
};

PageSectionSubtitle.defaultProps = {
  className: '',
  children: null
};

export default withStyles(styles)(PageSectionSubtitle);
