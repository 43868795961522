import { stateToTzMap } from '@ourbranch/state-to-tz-map';
import { zonedTimeToUtc } from 'date-fns-tz';
import PropTypes from 'prop-types';
import React from 'react';
import Card from '../../../../components/card';
import CardContent from '../../../../components/card/card-content';
import CardFieldGroup from '../../../../components/card/card-field-group';
import CardLabel from '../../../../components/card/card-label';
import CardText from '../../../../components/card/card-text';
import { formatDate } from '../../../../util/strings';

const PolicyIdCard = ({ className, autoPolicyData }) => {
  const policyId = autoPolicyData.id;

  const policyStateTimezone = stateToTzMap[autoPolicyData.state];
  const effectiveDate = zonedTimeToUtc(autoPolicyData.effectiveDate, policyStateTimezone);
  const endDate = zonedTimeToUtc(autoPolicyData.endDate, policyStateTimezone);

  return (
    <Card cardColor="light" className={className}>
      <CardContent>
        <CardFieldGroup>
          <CardLabel>Policy ID</CardLabel>
          <CardText>{policyId}</CardText>
        </CardFieldGroup>
        <CardFieldGroup>
          <CardLabel>Effective</CardLabel>
          <CardText>{`${formatDate(effectiveDate)} to ${formatDate(endDate)}`}</CardText>
        </CardFieldGroup>
      </CardContent>
    </Card>
  );
};

PolicyIdCard.propTypes = {
  className: PropTypes.string,
  autoPolicyData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    effectiveDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired
  }).isRequired
};

PolicyIdCard.defaultProps = {
  className: ''
};

export default PolicyIdCard;
