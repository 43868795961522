import Grow from '@material-ui/core/Grow';
import { withStyles } from '@material-ui/core/styles';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React from 'react';
import { useQuery } from 'react-apollo';
import { Redirect } from 'react-router-dom';
import BackButton from '../../components/back-button';
import LoadingScreen from '../../components/loading-screen';
import PageSectionSubtitle from '../../components/page-section-subtitle';
import PageSectionTitle from '../../components/page-section-title';
import ScreenPadding from '../../components/screen-padding';
import { formatAddress } from '../../util/strings';
import HomeCoverageCard from './components/home-coverage-card';
import UmbrellaCoverageCard from './components/umbrella-coverage-card';

const GET_HOME_COVERAGES_QUERY = gql`
  {
    getMyPolicies {
      id
      policyDetails {
        includeUmbrella
        umbrellaCoverage {
          liabilityCoverageLimit
          numVehiclesTotal
          numLicensedDriversUnder25
          numMotorcyclesScooters
          uninsuredMotoristLimit
          numATVs
          numRVs
          numWatercraft
          numPersonalWatercraft
          numRentalProperties
          numOtherProperties
          presenceOfBusiness
        }
        home {
          homeLocation {
            address
            address2
            city
            state
            zip
          }
          replacementCostEstimate {
            total
          }
        }
        homeCoverage {
          coverageA # Dwelling limit
          coverageBPctOfA # Other structures limit
          coverageCPctOfA # Personal property limit
          coverageDPctOfA # Additional living expense limit
          coverageX # Family liability limit
          coverageY # Guest medical limit
          coverageWaterBackup
          coverageIBSC # Additonal coverage over dwelling coverage (as percentage)
          coverageBP # Business property
          coverageF # Fire department charges
          coverageG # Loss assessments
          coverageCA # Cameras
          coverageDC # Day Care
          coverageJ # Jewelry, watches, and furs
          coverageMI # Musical instruments
          coverageST # Silverware theft
          coverageSP # Sports equipment
          coverageDR # Electronic data recovery
          coverageMS # Mine subsidence
          coveragePetBite
          coverageRSExtended
          coverageYardAndGarden
          coverageSinkhole
          coverageOilStorageTank
          homeownerProtection
          coverageBuildingMaterialsTheft
          coverageFairRental
          deductibleWindHail
          deductibleHurricane
          coverageCourseOfConstruction
          coverageExtendedPremises
          deductibleAllOther
        }
      }
    }
  }
`;

const styles = {
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16
  },
  cardsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    // https://jaketrent.com/post/spacing-between-multiline-flexbox-child-elements/
    margin: '-16px -16px',
    '@media(max-width: 414px)': {
      margin: 0
    }
  },
  card: {
    margin: 16,
    '@media(max-width: 414px)': {
      margin: 0
    }
  },
  umbrellaCoverageCard: {
    composes: '$card',
    width: 327
  }
};

const HomeCoverage = ({ classes, match }) => {
  const { loading, data } = useQuery(GET_HOME_COVERAGES_QUERY);

  if (loading) {
    return <LoadingScreen />;
  }

  const matchedPolicy = data.getMyPolicies.find((policy) => policy.id === match.params.policyId);

  if (!matchedPolicy) {
    return <Redirect to="/app/home" />;
  }

  const { home } = matchedPolicy.policyDetails;

  return (
    <ScreenPadding>
      <Grow in appear timeout={250}>
        <div>
          <div className={classes.headerContainer}>
            <BackButton />
            <PageSectionTitle>{formatAddress(home.homeLocation)}</PageSectionTitle>
          </div>
          <PageSectionSubtitle>All Coverages</PageSectionSubtitle>
          <div className={classes.cardsContainer}>
            <HomeCoverageCard className={classes.card} homePolicyData={matchedPolicy} />
            {matchedPolicy.policyDetails.includeUmbrella && (
              <UmbrellaCoverageCard className={classes.umbrellaCoverageCard} homePolicyData={matchedPolicy} />
            )}
          </div>
        </div>
      </Grow>
    </ScreenPadding>
  );
};

HomeCoverage.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default withStyles(styles)(HomeCoverage);
