import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import Button from '../../../../components/button';
import Card from '../../../../components/card';
import CardContent from '../../../../components/card/card-content';
import theme from '../../../../theme';
import ZendeskWidgetController from '../../../../util/zendesk-widget-controller';

const useStyles = makeStyles({
  card: {
    backgroundColor: theme.colors.pesto
  },
  title: {
    fontSize: '32px',
    lineHeight: 1.25,
    fontWeight: 100,
    color: '#fff'
  },
  button: {
    marginTop: 24,
    width: '100%'
  }
});

const GetAQuoteCard = ({ className }) => {
  const styles = useStyles();

  return (
    <Card className={clsx(styles.card, className)}>
      <CardContent>
        <div className={styles.title}>Wondering how much a new policy or change will cost?</div>
        <Button primary className={styles.button} onClick={ZendeskWidgetController.openWidget}>
          Get a Quote
        </Button>
      </CardContent>
    </Card>
  );
};

GetAQuoteCard.propTypes = {
  className: PropTypes.string
};

GetAQuoteCard.defaultProps = {
  className: ''
};

export default GetAQuoteCard;
