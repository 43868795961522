import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import CardLabel from '../../../components/card/card-label';
import CardSubtitle2 from '../../../components/card/card-subtitle-2';
import CardText from '../../../components/card/card-text';
import TwoColumnGrid from '../../../components/two-column-grid';
import { TOOLTIPS } from '../../../util/constants';
import { roofShape, roofType } from '../../../util/terminology';

const styles = {
  title: {
    marginTop: 26,
    marginBottom: 8
  }
};

const RoofInfo = ({ classes, className, homePolicyData }) => {
  const { home } = homePolicyData.policyDetails;

  return (
    <div className={className}>
      <CardSubtitle2 className={classes.title}>Roof</CardSubtitle2>
      <TwoColumnGrid className={className}>
        <div>
          <CardLabel>Original</CardLabel>
          <CardText>{home.roofOriginal ? 'Yes' : 'No'}</CardText>
        </div>
        <div>
          <CardLabel>Year</CardLabel>
          <CardText>{home.roofYear}</CardText>
        </div>
        <div>
          <CardLabel tooltip={TOOLTIPS.HOME_INFO_ROOF_TYPE}>Type</CardLabel>
          <CardText>{roofType[home.roofType]}</CardText>
        </div>
        <div>
          <CardLabel tooltip={TOOLTIPS.HOME_INFO_ROOF_SHAPE}>Shape</CardLabel>
          <CardText>{roofShape[home.roofShape]}</CardText>
        </div>
      </TwoColumnGrid>
    </div>
  );
};

RoofInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  homePolicyData: PropTypes.shape({
    policyDetails: PropTypes.shape({
      home: PropTypes.shape({
        roofOriginal: PropTypes.bool.isRequired,
        roofYear: PropTypes.number.isRequired,
        roofType: PropTypes.string.isRequired,
        roofShape: PropTypes.string.isRequired
      }).isRequired
    }).isRequired
  }).isRequired
};

RoofInfo.defaultProps = {
  className: ''
};

export default withStyles(styles)(RoofInfo);
