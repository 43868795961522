import PropTypes from 'prop-types';
import React from 'react';
import CardFieldGroup from '../../../components/card/card-field-group';
import CardLabel from '../../../components/card/card-label';
import CardSubtitle2 from '../../../components/card/card-subtitle-2';
import CardText from '../../../components/card/card-text';
import { TOOLTIPS } from '../../../util/constants';
import { formatDate } from '../../../util/strings';
import { education, homeOwnershipStatus } from '../../../util/terminology';

const ResidentsColumn = ({ className, homePolicyData }) => {
  const { home, people } = homePolicyData.policyDetails;

  const highestEducationKey = people.reduce((currentHighestKey, person) => {
    if (
      person.highestEducation &&
      education[person.highestEducation].precedence > education[currentHighestKey].precedence
    ) {
      return person.highestEducation;
    }
    return currentHighestKey;
  }, 'H'); // Initial value of `currentHighestKey` is "High school or less)

  const highestEducation = education[highestEducationKey].name;
  const ageOfOldestResident = people.reduce((currentOldestAge, person) => Math.max(currentOldestAge, person.age), 0);

  return (
    <div className={className}>
      <CardSubtitle2>Residents</CardSubtitle2>
      <CardFieldGroup>
        <CardLabel>Ownership</CardLabel>
        <CardText>{homeOwnershipStatus[home.ownershipStatus]}</CardText>
      </CardFieldGroup>
      <CardFieldGroup>
        <CardLabel tooltip={TOOLTIPS.HOME_INFO_RESIDENTS}>Residents</CardLabel>
        <CardText>{home.numOccupants}</CardText>
      </CardFieldGroup>
      <CardFieldGroup>
        <CardLabel>Age of Oldest Resident</CardLabel>
        <CardText>{ageOfOldestResident}</CardText>
      </CardFieldGroup>
      <CardFieldGroup>
        <CardLabel>Highest Education of Residents</CardLabel>
        <CardText>{highestEducation}</CardText>
      </CardFieldGroup>
      <CardFieldGroup>
        <CardLabel>House Purchased</CardLabel>
        <CardText>{formatDate(home.purchaseDate)}</CardText>
      </CardFieldGroup>
    </div>
  );
};

ResidentsColumn.propTypes = {
  className: PropTypes.string,
  homePolicyData: PropTypes.shape({
    policyDetails: PropTypes.shape({
      people: PropTypes.arrayOf(
        PropTypes.shape({
          highestEducation: PropTypes.string.isRequired,
          age: PropTypes.number.isRequired
        })
      ).isRequired,
      home: PropTypes.shape({
        latitude: PropTypes.number.isRequired,
        longitude: PropTypes.number.isRequired,
        ownershipStatus: PropTypes.string.isRequired,
        numOccupants: PropTypes.number.isRequired,
        purchaseDate: PropTypes.string.isRequired
      })
    }).isRequired
  }).isRequired
};

ResidentsColumn.defaultProps = {
  className: ''
};

export default ResidentsColumn;
