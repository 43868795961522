import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import DocumentsColumn from '../../../components/documents-column';
import Masonry from '../../../components/masonry';
import BillingColumn from './billing-column';
import CoverageColumn from './coverage-column';
import GoToHomeDetailsButton from './go-to-home-details-button';
import PolicyIdColumn from './policy-id-column';

const styles = {
  container: {
    marginBottom: 16
  },
  viewHomeInfoButton: {
    '@media(max-width: 414px)': {
      marginLeft: 16
    }
  }
};

const HomePolicySection = ({ classes, className, homePolicyData }) => (
  <div className={clsx(classes.container, className)}>
    <GoToHomeDetailsButton className={classes.viewHomeInfoButton} policyId={homePolicyData.id} />
    <Masonry>
      <PolicyIdColumn homePolicyData={homePolicyData} />
      <CoverageColumn homePolicyData={homePolicyData} />
      <BillingColumn homePolicyData={homePolicyData} />
      <DocumentsColumn policyId={homePolicyData.id} />
    </Masonry>
  </div>
);

HomePolicySection.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  homePolicyData: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired
};

HomePolicySection.defaultProps = {
  className: ''
};

export default withStyles(styles)(HomePolicySection);
