import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { CardStyleContext } from '.';
import theme from '../../theme';

const styles = {
  subtitle2: {
    fontSize: 14,
    fontWeight: 300,
    textTransform: 'uppercase'
  },
  dark: {
    color: theme.colors.carrot
  },
  light: {
    color: theme.colors.pesto
  }
};

const CardSubtitle2 = ({ className, classes, children }) => (
  <CardStyleContext.Consumer>
    {(cardStyle) => (
      <div
        className={clsx(
          classes.subtitle2,
          {
            [classes.dark]: cardStyle === 'dark',
            [classes.light]: cardStyle === 'light'
          },
          className
        )}
      >
        {children}
      </div>
    )}
  </CardStyleContext.Consumer>
);

CardSubtitle2.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  children: PropTypes.node
};

CardSubtitle2.defaultProps = {
  className: '',
  children: null
};

export default withStyles(styles)(CardSubtitle2);
