import Auth from '@aws-amplify/auth';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { newPasswordRequired } from '../../helpers';
import UserBeingAuthenticatedContainer from '../../user-being-authenticated-container';

function DefaultAuthenticator({ history }) {
  const userBeingAuthenticatedContainer = UserBeingAuthenticatedContainer.useContainer();

  useEffect(() => {
    async function effect() {
      try {
        const user = await Auth.currentAuthenticatedUser();

        if (user.username === 'anonymous') {
          await Auth.signOut();
          history.replace('/auth/signin');
          return;
        }

        userBeingAuthenticatedContainer.setUser(user);

        if (newPasswordRequired(user)) {
          history.replace('/auth/newpassword');
          return;
        }

        history.replace('/app');
      } catch {
        history.replace('/auth/signin');
      }
    }

    effect();
  }, [history, userBeingAuthenticatedContainer]);

  return null;
}

DefaultAuthenticator.propTypes = {
  history: PropTypes.object.isRequired
};

export default withRouter(DefaultAuthenticator);
