import { makeStyles } from '@material-ui/core';
import theme from '../theme';

const useStyles = makeStyles({
  title: {
    margin: '8px 0',
    fontSize: '20px',
    color: theme.colors.turtleGreen
  },
  explanationText: {
    marginTop: '16px'
  },
  textField: {
    marginTop: '32px',
    width: '100%'
  },
  textFieldWithLabelContainer: {
    marginTop: '32px',
    width: '100%'
  },
  checkboxOrRadioField: {
    marginTop: '16px',
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  checkboxOrRadioFieldText: {
    fontSize: '14px',
    marginLeft: '4px',
    color: theme.colors.pesto
  },
  textFieldWithLabel: {
    marginTop: '8px',
    width: '100%'
  },
  primaryButtonAndErrorTextContainer: {
    marginTop: '20px',
    width: '100%'
  },
  primaryButton: {
    marginTop: '12px',
    width: '100%'
  },
  secondaryButton: {
    marginTop: '8px'
  }
});

export default useStyles;
