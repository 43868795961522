import { makeStyles } from '@material-ui/core';
import queryString from 'query-string';
import React from 'react';
import BranchSpinner from '../../../components/branch-spinner';
import AutoLoginAuthenticator from './auto-login-authenticator';
import DefaultAuthenticator from './default-authenticator';
import TemporaryPasswordEmailAuthenticator from './temporary-password-email-authenticator';

const useStyles = makeStyles({
  spinnerOuterContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  spinnerInnerContainer: {
    width: '50%'
  }
});

export default function Initial() {
  const styles = useStyles();

  const authStrategy = {
    default: false,
    autoLogin: false,
    temporaryPasswordEmail: false // See https://github.com/gobranch/branch/issues/2624 for what this is / how it works
  };

  switch (queryString.parse(window.location.search).source) {
    case 'accountmobile': {
      authStrategy.autoLogin = true;
      break;
    }
    case 'temporarypasswordemail': {
      authStrategy.temporaryPasswordEmail = true;
      break;
    }
    default: {
      authStrategy.default = true;
    }
  }

  return (
    <div className={styles.spinnerOuterContainer}>
      <div className={styles.spinnerInnerContainer}>
        <BranchSpinner />
        {authStrategy.default && <DefaultAuthenticator />}
        {authStrategy.autoLogin && <AutoLoginAuthenticator />}
        {authStrategy.temporaryPasswordEmail && <TemporaryPasswordEmailAuthenticator />}
      </div>
    </div>
  );
}
