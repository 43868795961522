import Auth from '@aws-amplify/auth';
import * as Sentry from '@sentry/browser';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../components/button';
import CardTitle from '../../components/card/card-title';
import TextField from '../../components/text-field';
import { track } from '../../util/analytics';
import { logError } from '../../util/devmode-logger';
import ZendeskWidgetController from '../../util/zendesk-widget-controller';
import useSharedStyles from '../shared-styles';

function ForgotPasswordStep1() {
  const sharedStyles = useSharedStyles();
  const history = useHistory();

  const [accountId, setAccountId] = useState('');
  const [loading, setLoading] = useState(false);

  const sendResetcode = (e) => {
    e.preventDefault();
    setLoading(true);

    Auth.forgotPassword(accountId)
      .catch((err) => {
        Sentry.captureException(err);
        logError(err);
        if (
          err.message &&
          err.message === 'Cannot reset password for the user as there is no registered/verified email or phone_number'
        ) {
          track('Account Tried Forgot Password for Unverified User', { accountId });
        }
      })
      .finally(() => {
        history.push(`/auth/forgotpasswordstep2?accountId=${encodeURIComponent(accountId)}`);
      });
  };

  return (
    <>
      <CardTitle className={sharedStyles.title}>Reset password</CardTitle>
      <form onSubmit={sendResetcode}>
        <TextField
          type="text"
          placeholder="Account ID or Email"
          disabled={loading}
          className={sharedStyles.textField}
          value={accountId}
          onChange={(e) => setAccountId(e.target.value)}
        />
        <div className={sharedStyles.primaryButtonAndErrorTextContainer}>
          <Button
            primary
            type="submit"
            loading={loading}
            disabled={loading || !accountId.length}
            className={sharedStyles.primaryButton}
          >
            Reset
          </Button>
        </div>
        <Button className={sharedStyles.secondaryButton} onClick={() => ZendeskWidgetController.openChat()}>
          {"Can't"} remember? Contact support
        </Button>
      </form>
    </>
  );
}

export default ForgotPasswordStep1;
