import PropTypes from 'prop-types';
import React from 'react';
import CardColumn from '../../../../components/card-column';
import CardColumnHeader from '../../../../components/card-column/card-column-header';
import CardColumnSubtitle from '../../../../components/card-column/card-column-subtitle';
import PolicyBillingCard from '../../../../components/policy-billing-card';
import AutoCoverageCard from './auto-coverage-card';
import PolicyIdCard from './policy-id-card';
import RentersCoverageCard from './renters-coverage-card';

const PolicyDataColumn = ({ className, autoPolicyData }) => {
  const { includeRenters } = autoPolicyData.policyDetails;

  return (
    <CardColumn className={className}>
      <CardColumnHeader>
        <CardColumnSubtitle>Policy</CardColumnSubtitle>
      </CardColumnHeader>
      <PolicyIdCard autoPolicyData={autoPolicyData} />
      <CardColumnHeader>
        <CardColumnSubtitle>Coverage</CardColumnSubtitle>
      </CardColumnHeader>
      <AutoCoverageCard autoPolicyData={autoPolicyData} />
      {includeRenters && <RentersCoverageCard autoPolicyData={autoPolicyData} />}
      <CardColumnHeader>
        <CardColumnSubtitle>Billing</CardColumnSubtitle>
      </CardColumnHeader>
      <PolicyBillingCard policyId={autoPolicyData.id} />
    </CardColumn>
  );
};

PolicyDataColumn.propTypes = {
  className: PropTypes.string,
  autoPolicyData: PropTypes.object.isRequired
};

PolicyDataColumn.defaultProps = {
  className: ''
};

export default PolicyDataColumn;
