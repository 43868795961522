import PropTypes from 'prop-types';
import React from 'react';
import Card from '../../../components/card';
import CardContent from '../../../components/card/card-content';
import CardFieldGroup from '../../../components/card/card-field-group';
import CardLabel from '../../../components/card/card-label';
import CardText from '../../../components/card/card-text';
import CardTitle from '../../../components/card/card-title';
import { formatDollars, prettifyLiabilityString } from '../../../util/strings';

const RentersCoverageCard = ({ className, autoPolicyData }) => (
  <Card cardColor="dark" className={className}>
    <CardContent>
      <CardTitle>Renters Coverage</CardTitle>
      <CardFieldGroup>
        <CardLabel>Personal Liability Coverage</CardLabel>
        <CardText>{formatDollars(autoPolicyData.policyDetails.rentersCoverage.coverageX)}</CardText>
      </CardFieldGroup>
      <CardFieldGroup>
        <CardLabel>Personal Property / Additional Living Expenses</CardLabel>
        <CardText>
          {prettifyLiabilityString(autoPolicyData.policyDetails.rentersCoverage.coverageCD, {
            interpretAsThousands: false
          })}
        </CardText>
      </CardFieldGroup>
      <CardFieldGroup>
        <CardLabel>Guest Medical Coverage</CardLabel>
        <CardText>{formatDollars(autoPolicyData.policyDetails.rentersCoverage.coverageY)}</CardText>
      </CardFieldGroup>
      <CardFieldGroup>
        <CardLabel>Deductible</CardLabel>
        <CardText>{formatDollars(autoPolicyData.policyDetails.rentersCoverage.deductible)}</CardText>
      </CardFieldGroup>
    </CardContent>
  </Card>
);

RentersCoverageCard.propTypes = {
  className: PropTypes.string,
  autoPolicyData: PropTypes.shape({
    policyDetails: PropTypes.shape({
      rentersCoverage: PropTypes.shape({
        coverageCD: PropTypes.string.isRequired,
        coverageX: PropTypes.number.isRequired,
        coverageY: PropTypes.number.isRequired,
        deductible: PropTypes.number.isRequired
      }).isRequired
    }).isRequired
  }).isRequired
};

RentersCoverageCard.defaultProps = {
  className: ''
};

export default RentersCoverageCard;
