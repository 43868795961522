import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import CircularProgress from '../circular-progress';
import CardListItem from './card-list-item';

const styles = {
  loadingContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center'
  }
};

function CardListLoading({ classes }) {
  return (
    <CardListItem>
      <div className={classes.loadingContainer}>
        <CircularProgress size={32} />
      </div>
    </CardListItem>
  );
}

CardListLoading.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CardListLoading);
