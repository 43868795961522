/* eslint-disable no-console */

const runInDevOnly = (func) => {
  if (process.env.NODE_ENV === 'development') {
    func();
  }
};

export function log(...args) {
  runInDevOnly(() => console.log(...args));
}

export function logError(...args) {
  runInDevOnly(() => console.error(...args));
}

export function logTable(...args) {
  runInDevOnly(() => console.table(...args));
}
