import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { CardStyleContext } from '.';
import theme from '../../theme';

const styles = {
  common: {
    fontSize: 16
  },
  dark: {
    composes: '$common',
    color: theme.colors.beige
  },
  black: {
    composes: '$common',
    color: theme.colors.beige
  },
  green: {
    composes: '$common',
    color: theme.colors.beige
  },
  light: {
    composes: '$common',
    color: theme.colors.turtleGreen
  }
};

const CardText = ({ className, classes, children }) => (
  <CardStyleContext.Consumer>
    {(cardStyle) => (
      <span
        className={clsx(
          {
            [classes.dark]: cardStyle === 'dark',
            [classes.light]: cardStyle === 'light',
            [classes.black]: cardStyle === 'black',
            [classes.green]: cardStyle === 'green'
          },
          className
        )}
      >
        {children}
      </span>
    )}
  </CardStyleContext.Consumer>
);

CardText.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  children: PropTypes.node
};

CardText.defaultProps = {
  className: '',
  children: null
};

export default withStyles(styles)(CardText);
