import { useState } from 'react';
import { createContainer } from 'unstated-next';

function useUserBeingAuthenticated() {
  const [user, setUser] = useState(null);
  return { user, setUser };
}

const UserBeingAuthenticatedContainer = createContainer(useUserBeingAuthenticated);

export default UserBeingAuthenticatedContainer;
