import Grow from '@material-ui/core/Grow';
import React from 'react';
import Masonry from '../../components/masonry';
import ScreenPadding from '../../components/screen-padding';
import AutoPoliciesColumn from './components/auto-policies-column';
import HomePoliciesColumn from './components/home-policies-column';
import MoreColumn from './components/more-column';
import RentersPoliciesColumn from './components/renters-policies-column';
import UmbrellaPoliciesColumn from './components/umbrella-policies-column';
import { withMicroDeposit } from './components/validate-bank-account';

const AllProducts = () => (
  <ScreenPadding>
    <Grow in appear timeout={250}>
      <div>
        <Masonry>
          <AutoPoliciesColumn />
          <HomePoliciesColumn />
          <RentersPoliciesColumn />
          <UmbrellaPoliciesColumn />
          <MoreColumn />
        </Masonry>
      </div>
    </Grow>
  </ScreenPadding>
);

export default withMicroDeposit(AllProducts);
