import PropTypes from 'prop-types';
import React from 'react';
import Card from '../../../../components/card';
import CardContent from '../../../../components/card/card-content';
import CardFieldGroup from '../../../../components/card/card-field-group';
import CardLabel from '../../../../components/card/card-label';
import CardText from '../../../../components/card/card-text';
import CardTitle from '../../../../components/card/card-title';
import { formatDate, joinIgnoringFalsy } from '../../../../util/strings';

const CarCard = ({ className, car }) => {
  const displayName = joinIgnoringFalsy([car.year, car.make, car.model]);

  let primaryUse = '';
  switch (car.primaryUse) {
    case 'P':
      primaryUse = 'Pleasure';
      break;
    case 'B':
      primaryUse = 'Business';
      break;
    case 'C':
      primaryUse = 'Commuting';
      break;
    case 'F':
      primaryUse = 'Farm';
      break;
    default:
      primaryUse = 'Other';
  }

  return (
    <Card cardColor="light" className={className}>
      <CardContent>
        <CardTitle>{displayName}</CardTitle>
        <CardFieldGroup>
          <CardLabel>VIN</CardLabel>
          <CardText>{car.VIN}</CardText>
        </CardFieldGroup>
        <CardFieldGroup>
          <CardLabel>Primary Use</CardLabel>
          <CardText>{primaryUse}</CardText>
        </CardFieldGroup>
        {Boolean(car.purchaseDate) && (
          <CardFieldGroup>
            <CardLabel>Purchase Date</CardLabel>
            <CardText>{formatDate(car.purchaseDate)}</CardText>
          </CardFieldGroup>
        )}
      </CardContent>
    </Card>
  );
};

CarCard.propTypes = {
  className: PropTypes.string,
  car: PropTypes.shape({
    make: PropTypes.string.isRequired,
    model: PropTypes.string.isRequired,
    year: PropTypes.number.isRequired,
    VIN: PropTypes.string.isRequired,
    primaryUse: PropTypes.string.isRequired,
    purchaseDate: PropTypes.string.isRequired
  }).isRequired
};

CarCard.defaultProps = {
  className: ''
};

export default CarCard;
