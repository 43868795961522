import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { withApollo } from 'react-apollo';
import UnsignedApplicationModal from './unsigned-application-modal';

const UNSIGNED_APPLICATION_POLICIES_QUERY = gql`
  query unsignedApplicationPolicies {
    unsignedApplicationPolicies {
      policyId
      applicationUrl
    }
  }
`;

function UnsignedApplicationModalContainer({ client: apolloClient }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [unsignedApplicationUrl, setUnsignedApplicationUrl] = useState('');

  useEffect(() => {
    async function effect() {
      const {
        data: { unsignedApplicationPolicies }
      } = await apolloClient.query({
        query: UNSIGNED_APPLICATION_POLICIES_QUERY
      });

      if (unsignedApplicationPolicies.length) {
        setModalOpen(true);
        setUnsignedApplicationUrl(unsignedApplicationPolicies[0].applicationUrl);
      }
    }

    effect();
  }, [apolloClient]);

  return (
    <UnsignedApplicationModal
      open={modalOpen}
      applicationUrl={unsignedApplicationUrl}
      onClose={() => setModalOpen(false)}
    />
  );
}

UnsignedApplicationModalContainer.propTypes = {
  client: PropTypes.object.isRequired
};

export default withApollo(UnsignedApplicationModalContainer);
