import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import Auth from '@aws-amplify/auth';
import AppBar from '@material-ui/core/AppBar';
import ButtonBase from '@material-ui/core/ButtonBase';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';

import hamburger from '../../assets/icons/hamburger.svg';
import profile from '../../assets/icons/profile.svg';
import logo from '../../assets/images/logo.svg';
import theme from '../../theme';
import ZendeskWidgetController from '../../util/zendesk-widget-controller';
import AccountMenu from './account-menu';
import AppBarLink from './appbar-link';
import FloatingButtons from './floating-buttons';
import HamburgerMenu from './hamburger-menu';
import { ShellAppbarLinksContext, ShellAppbarLinksContextProvider } from './shell-appbar-links-context';
import UnsignedApplicationModalContainer from './unsigned-application-modal-container';
import BixConversionNotice from './bix-conversion-notice';

const styles = {
  appBar: {
    backgroundColor: theme.colors.beige,
    display: 'flex',
    justifyContent: 'center',
    padding: '24px 16px 0 16px',
    boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.24)',
    [`@media(min-width: 560px)`]: {
      padding: '24px 40px 0 40px'
    }
  },
  toolbarDesktop: {
    display: 'flex',
    padding: 0,
    minHeight: 0,
    paddingBottom: 22,
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (min-width: 560px) and (max-width: 808px)': {
      paddingBottom: 0
    }
  },
  toolbarTablet: {
    composes: '$toolbarDesktop',
    display: 'none',
    padding: '16px 0 16px 0',
    '@media (min-width: 560px) and (max-width: 808px)': {
      display: 'flex'
    }
  },
  imageContainer: {
    height: 40,
    [`@media(min-width: 560px)`]: {
      height: 48
    }
  },
  logoImageContainer: {
    composes: '$imageContainer',
    marginRight: 24
  },
  circularImageContainer: {
    composes: '$imageContainer',
    marginLeft: 24,
    borderRadius: '50%'
  },
  image: {
    height: '100%'
  },
  circularImageRipple: {
    color: 'black'
  },
  appBarItems: {
    display: 'flex',
    height: '100%',
    alignItems: 'center'
  },
  appBarLinksDesktopContainer: {
    '@media(max-width: 808px)': {
      display: 'none'
    }
  },
  appBarLinksTabletContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center'
  },
  appBarLinks: {
    display: 'flex',
    height: '100%',
    alignItems: 'center'
  },
  link: {
    textDecoration: 'none'
  },
  hamburgerMenuIcon: {
    composes: '$circularImageContainer',
    marginLeft: 16,
    '@media(min-width: 560px)': {
      display: 'none'
    }
  },
  floatingActionButtons: {
    position: 'fixed',
    bottom: 14,
    right: 14
  },
  menu: {
    marginTop: 50
  },
  appContent: {
    width: '100%',
    // Matching header heights at different screen widths
    // We could do clever things like using a ResizeObserver but
    // this is more performant, the header heights are unlikely
    // to change, and even if they do this is very easy to update
    // and very hard to miss if it's not updated.
    marginTop: 98,
    height: 'calc(100vh - 98px)',
    '@media(max-width: 808px)': {
      marginTop: 156,
      height: 'calc(100vh - 156px)'
    },
    '@media(max-width: 559px)': {
      marginTop: 86,
      height: 'calc(100vh - 86px)'
    }
  },
  appContentBottomSpacer: {
    width: '100%',
    height: 100
  }
};

export function getAppBarHeight() {
  const width = window.innerWidth;

  if (width <= 559) {
    return 86;
  }

  if (width <= 808) {
    return 156;
  }

  return 98;
}

const AppBarLinks = withStyles(styles)(({ classes }) => {
  const shellAppbarLinksContext = useContext(ShellAppbarLinksContext);

  return (
    <div className={classes.appBarLinks}>
      <AppBarLink to="/app/" exact title="All Products" />
      {shellAppbarLinksContext.autoVisible && <AppBarLink to="/app/auto" title="Auto" />}
      {shellAppbarLinksContext.homeVisible && <AppBarLink to="/app/home" title="Homeowners" />}
      <AppBarLink to="/app/billing" title="Billing" />
    </div>
  );
});

class Shell extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
  };

  static defaultProps = {
    children: null
  };

  state = {
    hamburgerMenuAnchorEl: null,
    accountMenuAnchorEl: null
  };

  async componentDidMount() {
    try {
      const user = await Auth.currentAuthenticatedUser();

      const username = user.signInUserSession.idToken.payload['cognito:user'];
      /* eslint-disable camelcase */
      const {
        attributes: { name, family_name, email, phone_number }
      } = user;

      ZendeskWidgetController.setPrefillData({
        accountId: username,
        name: name.concat(family_name ? ` ${family_name}` : ''),
        email,
        phoneNumber: phone_number.startsWith('+1') ? phone_number.substr('+1'.length) : phone_number
      });
      /* eslint-enable camelcase */
    } catch {
      /* no-op */
    }
  }

  hamburgerMenuOpen = (e) => {
    this.setState({ hamburgerMenuAnchorEl: e.currentTarget });
  };

  hamburgerMenuClose = () => {
    this.setState({ hamburgerMenuAnchorEl: null });
  };

  accountMenuOpen = (e) => {
    this.setState({ accountMenuAnchorEl: e.currentTarget });
  };

  accountMenuClose = () => {
    this.setState({ accountMenuAnchorEl: null });
  };

  render() {
    const { classes, children, location } = this.props;
    const { accountMenuAnchorEl, hamburgerMenuAnchorEl } = this.state;
    const isFromSigningPolicy = new URLSearchParams(location.search).has('fromSigningPolicy');
    return (
      <ShellAppbarLinksContextProvider>
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolbarDesktop}>
            <Link to="/app" className={classes.logoImageContainer}>
              <img src={logo} alt="Branch Insurance" className={classes.image} />
            </Link>
            <div className={classes.appBarItems}>
              <div className={classes.appBarLinksDesktopContainer}>
                <AppBarLinks />
              </div>
              <ButtonBase
                onClick={this.accountMenuOpen}
                className={classes.circularImageContainer}
                TouchRippleProps={{ className: classes.circularImageRipple }}
              >
                <img src={profile} alt="Profile" className={classes.image} />
              </ButtonBase>
              <ButtonBase
                onClick={this.hamburgerMenuOpen}
                className={classes.hamburgerMenuIcon}
                TouchRippleProps={{ className: classes.circularImageRipple }}
              >
                <img src={hamburger} alt="menu" className={classes.image} />
              </ButtonBase>
            </div>
          </Toolbar>
          <Toolbar className={classes.toolbarTablet}>
            <div className={classes.appBarLinksTabletContainer}>
              <AppBarLinks />
            </div>
          </Toolbar>
        </AppBar>
        <div className={classes.appContent}>{children}</div>
        <FloatingButtons className={classes.floatingActionButtons} />
        <AccountMenu
          anchorEl={accountMenuAnchorEl}
          open={Boolean(accountMenuAnchorEl)}
          onClose={this.accountMenuClose}
          className={classes.menu}
        />
        <HamburgerMenu
          anchorEl={hamburgerMenuAnchorEl}
          open={Boolean(hamburgerMenuAnchorEl)}
          onClose={this.hamburgerMenuClose}
          className={classes.menu}
        />
        {/* Preventing the scenario when the user just sign their policies, happens for monoline sell */}
        {!isFromSigningPolicy && <UnsignedApplicationModalContainer />}
        <BixConversionNotice />
      </ShellAppbarLinksContextProvider>
    );
  }
}

export default withStyles(styles)(withRouter(Shell));
