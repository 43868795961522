import ListItem from '@material-ui/core/ListItem';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import theme from '../../theme';

const styles = {
  cardListItem: {
    '&:hover': {
      backgroundColor: theme.colors.linen
    },
    '@media(max-width: 414px)': {
      paddingLeft: 9
    }
  }
};

const CardListItem = withStyles(styles)(({ classes, className, children, ...props }) => (
  <ListItem button className={clsx(classes.cardListItem, className)} {...props}>
    {children}
  </ListItem>
));

CardListItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

CardListItem.defaultProps = {
  className: '',
  children: null
};

export default CardListItem;
