import gql from 'graphql-tag';

export const GET_NEXT_PAYMENT_DATE_QUERY = gql`
  query GetNextPaymentQuery($policyId: String!) {
    getMyBillingDetails(policyId: $policyId) {
      nextPaymentAmount
      nextPaymentDate
    }
  }
`;

export const GET_REJECT_CODES_QUERY = gql`
  query GetRejectCodesQuery {
    getMyPolicies {
      offer {
        quote {
          offerings {
            homeownersRejectCode
            autoRejectCode
            monolineAutoRejectCode
          }
        }
      }
    }
  }
`;
