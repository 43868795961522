import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import woodBurningStoveIcon from '../../../assets/icons/burning-stoves.svg';
import fireplaceIcon from '../../../assets/icons/fireplace.svg';
import fullBathroomIcon from '../../../assets/icons/full-bath.svg';
import garageSpaceIcon from '../../../assets/icons/garage-space.svg';
import garageTypeIcon from '../../../assets/icons/garage-type.svg';
import halfBathroomIcon from '../../../assets/icons/half-bath.svg';
import sqFtIcon from '../../../assets/icons/sqft.svg';
import storiesIcon from '../../../assets/icons/stories.svg';
import CardText from '../../../components/card/card-text';
import Tooltip from '../../../components/tooltip';
import TwoColumnGrid from '../../../components/two-column-grid';
import { garageType } from '../../../util/terminology';
import IconField from './icon-field';

const useStyles = makeStyles({
  tooltipLayout: {
    position: 'unset'
  }
});

const HomeInfoIcons = ({ className, homePolicyData }) => {
  const styles = useStyles();
  const { home } = homePolicyData.policyDetails;

  return (
    <TwoColumnGrid className={className}>
      <IconField iconSrc={sqFtIcon} iconAlt="Square Footage">
        <CardText>{home.sqFt.toLocaleString('en-US', { minimumFractionDigits: 2 })} sqft</CardText>
        <Tooltip text="Square footage" layoutOverrideClassName={styles.tooltipLayout} />
      </IconField>
      <IconField iconSrc={storiesIcon} iconAlt="Stories">
        <CardText>
          {home.numStories} {home.numStories === 1 ? 'story' : 'stories'}
        </CardText>
        <Tooltip text="Number of stories" layoutOverrideClassName={styles.tooltipLayout} />
      </IconField>
      <IconField iconSrc={fullBathroomIcon} iconAlt="Full Bathrooms">
        <CardText>{home.numFullBathrooms}</CardText>
        <Tooltip text="Full bathrooms" layoutOverrideClassName={styles.tooltipLayout} />
      </IconField>
      <IconField iconSrc={halfBathroomIcon} iconAlt="Half Bathrooms">
        <CardText>{home.numHalfBathrooms}</CardText>
        <Tooltip text="Half bathrooms" layoutOverrideClassName={styles.tooltipLayout} />
      </IconField>
      <IconField iconSrc={fireplaceIcon} iconAlt="Fireplaces">
        <CardText>{home.numFireplaces}</CardText>
        <Tooltip text="Fireplaces" layoutOverrideClassName={styles.tooltipLayout} />
      </IconField>
      <IconField iconSrc={woodBurningStoveIcon} iconAlt="Wood Burning Stoves">
        <CardText>{home.numWoodBurningStoves}</CardText>
        <Tooltip text="Wood burning stoves" layoutOverrideClassName={styles.tooltipLayout} />
      </IconField>
      <IconField iconSrc={garageTypeIcon} iconAlt="Garage Type">
        <CardText>{garageType[home.garageType]}</CardText>
        <Tooltip text="Garage type" layoutOverrideClassName={styles.tooltipLayout} />
      </IconField>
      <IconField iconSrc={garageSpaceIcon} iconAlt="Garage Car Capacity">
        <CardText>{home.garageCarCapacity}</CardText>
        <Tooltip text="Garage car capacity" layoutOverrideClassName={styles.tooltipLayout} />
      </IconField>
    </TwoColumnGrid>
  );
};

HomeInfoIcons.propTypes = {
  className: PropTypes.string,
  homePolicyData: PropTypes.shape({
    policyDetails: PropTypes.shape({
      home: PropTypes.shape({
        sqFt: PropTypes.number.isRequired,
        numStories: PropTypes.number.isRequired,
        numFullBathrooms: PropTypes.number.isRequired,
        numHalfBathrooms: PropTypes.number.isRequired,
        numFireplaces: PropTypes.number.isRequired,
        numWoodBurningStoves: PropTypes.number.isRequired,
        garageType: PropTypes.string.isRequired,
        garageCarCapacity: PropTypes.number.isRequired
      }).isRequired
    }).isRequired
  }).isRequired
};

HomeInfoIcons.defaultProps = {
  className: ''
};

export default HomeInfoIcons;
