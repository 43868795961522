import { withStyles } from '@material-ui/core/styles';
import { stateToTzMap } from '@ourbranch/state-to-tz-map';
import * as Sentry from '@sentry/browser';
import clsx from 'clsx';
import { isToday } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React from 'react';
import { Query } from 'react-apollo';
import theme from '../../theme';
import { TOOLTIPS } from '../../util/constants';
import { logError } from '../../util/devmode-logger';
import { autoPolicyTitleString, formatAddress, formatDate, formatDollars } from '../../util/strings';
import Button from '../button';
import Card from '../card';
import CardListDivider from '../card-list/card-list-divider';
import CardContent from '../card/card-content';
import CardContentLoading from '../card/card-content-loading';
import CardFieldGroup from '../card/card-field-group';
import CardLabel from '../card/card-label';
import CardText from '../card/card-text';
import NoMarginCardFieldGroup from '../card/no-margin-card-field-group';
import ModifyPaymentMethodDialog from './modify-payment-method-dialog';
import PaymentMethodDisplay from './payment-method-display';
import PreviousPaymentsDialog from './previous-payments-dialog';

const styles = {
  flexRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginTop: 16
  },
  noMarginFlexRow: {
    composes: '$flexRow',
    marginTop: 0
  },
  noMinHeightButton: {
    minHeight: 0
  },
  paymentMethodDisplayContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  cardCompanyText: {
    fontSize: 12
  },
  maskingDots: {
    marginLeft: 8,
    marginRight: 8,
    fontSize: 9,
    color: theme.colors.tallow
  },
  previousPaymentsButton: {
    marginTop: 16
  },
  pastDueBackgroundColor: {
    backgroundColor: theme.colors.terracotta
  },
  pastDueLabelColor: {
    color: theme.colors.beige,
    opacity: 0.6
  },
  pastDueTextColor: {
    color: theme.colors.beige
  }
};

const GET_BILLING_DATA_QUERY = gql`
  query GetBillingDataQuery($policyId: String!) {
    getMyPolicies {
      id
      policyType
      premium
      surplusContribution
      paymentMethod
      paymentType
      fees {
        amount
      }
      effectiveDate
      term
      endDate
      state
      policyDetails {
        cars {
          year
          make
          model
        }
        home {
          homeLocation {
            address
            address2
            city
            state
            zip
          }
        }
      }
    }
    getMyBillingDetails(policyId: $policyId) {
      nextPaymentDate
      nextPaymentAmount
      activePaymentMethodBrand
      activePaymentMethodLast4
      transactions {
        id
        paymentAmount
        paymentAmountFormatted
        paymentRefunded
        paymentRefundedFormatted
        paymentDate
        paymentMethod
        paymentMethodBrand
        paymentMethodLast4
        paymentStatus
      }
    }
  }
`;

class PolicyBillingCard extends React.Component {
  constructor(props) {
    // Required step: always call the parent class' constructor
    super(props);
    this.state = {
      previousPaymentsDialogOpen: false,
      modifyPaymentMethodDialogOpen: false
    };
  }

  togglePreviousPaymentsDialog = () => {
    this.setState(({ previousPaymentsDialogOpen }) => ({
      previousPaymentsDialogOpen: !previousPaymentsDialogOpen
    }));
  };

  toggleModifyPaymentMethodDialog = () => {
    this.setState(({ modifyPaymentMethodDialogOpen }) => ({
      modifyPaymentMethodDialogOpen: !modifyPaymentMethodDialogOpen
    }));
  };

  fillWithRenewalOrCurrentPolicyData = ({ classes, nextPaymentDate, policyState, nextPaymentAmount, isRenewal }) => {
    let nextPaymentDateTZAdjusted;
    if (nextPaymentDate) {
      nextPaymentDateTZAdjusted = zonedTimeToUtc(nextPaymentDate, stateToTzMap[policyState]);
    }

    const pastDue =
      nextPaymentDateTZAdjusted && nextPaymentDateTZAdjusted < new Date() && !isToday(nextPaymentDateTZAdjusted);

    return (
      <CardContent className={clsx(pastDue && classes.pastDueBackgroundColor)}>
        <div className={classes.noMarginFlexRow}>
          <NoMarginCardFieldGroup>
            {pastDue ? (
              <>
                <CardLabel className={classes.pastDueLabelColor}>Past Due</CardLabel>
                <CardText className={classes.pastDueTextColor}>{formatDollars(nextPaymentAmount)}</CardText>
              </>
            ) : (
              <>
                <CardLabel>Next Payment</CardLabel>
                <CardText>
                  {nextPaymentDate && nextPaymentAmount ? formatDollars(nextPaymentAmount) : 'On Policy Renewal'}
                </CardText>
              </>
            )}
          </NoMarginCardFieldGroup>
          {nextPaymentDateTZAdjusted && nextPaymentAmount && (
            <NoMarginCardFieldGroup>
              <CardText className={clsx(pastDue && classes.pastDueTextColor)}>
                <div>{`on ${formatDate(nextPaymentDateTZAdjusted)}`}</div>
                {isRenewal && <div>(Policy Renewal)</div>}
              </CardText>
            </NoMarginCardFieldGroup>
          )}
        </div>
        <Button className={classes.previousPaymentsButton} onClick={this.togglePreviousPaymentsDialog}>
          View previous payments
        </Button>
      </CardContent>
    );
  };

  formatNextPaymentDateAndAmount = ({ classes, renewalPolicies, nextPaymentDate, policyState, nextPaymentAmount }) => {
    let currentTermFullyPaid = false;
    let renewalPolicyProcessed = false;

    if (!nextPaymentDate && !nextPaymentAmount) {
      currentTermFullyPaid = true;
    }

    if (renewalPolicies && renewalPolicies.length) {
      renewalPolicyProcessed = true;
    }

    if (renewalPolicyProcessed && currentTermFullyPaid) {
      return (
        <Query query={GET_BILLING_DATA_QUERY} variables={{ policyId: renewalPolicies[0].id }}>
          {({ data: renewalData, error: renewalError, loading: renewalLoading }) => {
            if (renewalError) {
              logError(renewalError);
              Sentry.captureException(renewalError);
              return null;
            }

            if (renewalLoading) {
              return <div>...</div>;
            }

            const { nextPaymentDate: renewalPaymentDate, nextPaymentAmount: renewalPaymentAmount } =
              (renewalData && renewalData.getMyBillingDetails) || {};

            return this.fillWithRenewalOrCurrentPolicyData({
              classes,
              nextPaymentDate: renewalPaymentDate,
              policyState,
              nextPaymentAmount: renewalPaymentAmount,
              isRenewal: true
            });
          }}
        </Query>
      );
    }

    return this.fillWithRenewalOrCurrentPolicyData({
      classes,
      nextPaymentDate,
      policyState,
      nextPaymentAmount,
      isRenewal: false
    });
  };

  render() {
    const { policyId, classes } = this.props;
    const { previousPaymentsDialogOpen, modifyPaymentMethodDialogOpen } = this.state;

    return (
      <Query query={GET_BILLING_DATA_QUERY} variables={{ policyId }}>
        {({ loading, data, error, refetch }) => {
          if (error) {
            logError(error);
            Sentry.captureException(error);
            return null;
          }

          if (loading) {
            return (
              <Card cardColor="light">
                <CardContentLoading />
              </Card>
            );
          }

          const isRenewalPolicy = (policy) => {
            const effectiveDate = zonedTimeToUtc(policy.effectiveDate, stateToTzMap[policy.state]);
            return policy.term >= 2 && effectiveDate >= new Date();
          };

          const policy = data.getMyPolicies.find((p) => p.id === policyId);
          const renewalPolicies = data.getMyPolicies.filter(
            (p) => isRenewalPolicy(p) && p.policyType === policy.policyType
          );

          const {
            activePaymentMethodBrand,
            activePaymentMethodLast4,
            nextPaymentDate,
            nextPaymentAmount,
            transactions
          } = (data && data.getMyBillingDetails) || {};

          const title =
            policy.policyType === 'A'
              ? autoPolicyTitleString(policy.policyDetails.cars)
              : formatAddress(policy.policyDetails.home.homeLocation);
          const totalTitle = `Total ${policy.policyType === 'A' ? 6 : 12} Month Price`;
          const totalValue = policy.fees.reduce(
            (acc, curr) => acc + curr.amount,
            policy.premium + (policy.surplusContribution || 0)
          );

          const { paymentMethod } = policy;

          return (
            <>
              <Card cardColor="light">
                <CardContent>
                  <CardFieldGroup>
                    <CardLabel>Policy</CardLabel>
                    <CardText>{title}</CardText>
                  </CardFieldGroup>
                  <div className={classes.flexRow}>
                    <NoMarginCardFieldGroup>
                      <CardLabel
                        tooltip={
                          policy.policyType === 'A'
                            ? TOOLTIPS.AUTO_TOTAL_SIX_MONTH_PRICE
                            : TOOLTIPS.HOME_TOTAL_TWELVE_MONTH_PRICE
                        }
                      >
                        {totalTitle}
                      </CardLabel>
                      <CardText>{formatDollars(totalValue)}</CardText>
                    </NoMarginCardFieldGroup>
                  </div>
                  <div className={classes.flexRow}>
                    <NoMarginCardFieldGroup>
                      <CardLabel>Payment Method</CardLabel>
                      <CardText className={classes.paymentMethodDisplayContainer}>
                        <PaymentMethodDisplay
                          method={paymentMethod || ''}
                          brand={activePaymentMethodBrand || ''}
                          last4={activePaymentMethodLast4 || ''}
                        />
                      </CardText>
                    </NoMarginCardFieldGroup>
                    <Button className={classes.noMinHeightButton} onClick={this.toggleModifyPaymentMethodDialog}>
                      Modify
                    </Button>
                  </div>
                </CardContent>
                <CardListDivider />
                {this.formatNextPaymentDateAndAmount({
                  classes,
                  renewalPolicies,
                  policyState: policy.state,
                  nextPaymentDate,
                  nextPaymentAmount
                })}
              </Card>
              <PreviousPaymentsDialog
                policyData={policy}
                transactions={transactions || []}
                open={previousPaymentsDialogOpen}
                onClose={this.togglePreviousPaymentsDialog}
              />
              <ModifyPaymentMethodDialog
                policyData={policy}
                activePaymentMethodBrand={activePaymentMethodBrand}
                activePaymentMethodLast4={activePaymentMethodLast4}
                open={modifyPaymentMethodDialogOpen}
                onClose={this.toggleModifyPaymentMethodDialog}
                onModified={() => {
                  this.setState({ modifyPaymentMethodDialogOpen: false });
                  refetch();
                }}
              />
            </>
          );
        }}
      </Query>
    );
  }
}

PolicyBillingCard.propTypes = {
  classes: PropTypes.object.isRequired,
  policyId: PropTypes.string.isRequired
};

export default withStyles(styles)(PolicyBillingCard);
