import Auth from '@aws-amplify/auth';
import * as Sentry from '@sentry/browser';
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { logError } from '../../../util/devmode-logger';
import { base64Decode } from '../../../util/strings';

export default function AutoLoginAuthenticator() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [fallback, setFallback] = useState(false);

  useEffect(() => {
    async function effect() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        if (user.username === 'anonymous') {
          await Auth.signOut();
        } else {
          return setLoggedIn(true);
        }
      } catch {
        /* no-op */
      }

      window.BranchMobileAuthReceiver = {
        sendBase64EncodedCredentials: async (base64EncodedAccountId, base64EncodedPassword) => {
          const accountId = base64Decode(base64EncodedAccountId);
          const password = base64Decode(base64EncodedPassword);

          try {
            await Auth.signIn(accountId, password);
            setLoggedIn(true);
          } catch (e) {
            Sentry.withScope((scope) => {
              logError(e);
              scope.setExtra('Error', e);
              Sentry.captureException(new Error('Auto login with app credentials failed'));
            });
            setFallback(true);
          }
        },

        fallbackBecauseOfError(e) {
          Sentry.withScope((scope) => {
            logError(e);
            scope.setExtra('Error', e);
            Sentry.captureException(new Error('App triggered auto login fallback'));
          });
          setFallback(true);
        }
      };

      try {
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage('request_auth_credentials');
        }
      } catch (e) {
        logError(e);
        Sentry.captureException(e);
        setFallback(true);
      }
    }

    effect();
  }, []);

  if (fallback) {
    return <Redirect to="/auth/signin" />;
  }

  if (loggedIn) {
    return <Redirect to="/app" />;
  }

  return null;
}
