import { format } from 'date-fns-tz';
import parseISO from 'date-fns/parseISO';
import { Base64 } from 'js-base64';
import { conformToMask } from 'text-mask-core';

/**
 * @typedef Car
 * @property {string} year
 * @property {string} make
 * @property {string} model
 */

/**
 * @param {Array<Car>} cars
 * @returns {string}
 */
export function autoPolicyTitleString(cars) {
  const firstCar = cars[0];
  let titleString = `${firstCar.year} ${firstCar.make} ${firstCar.model}`;

  const numCars = cars.length;
  if (numCars > 1) {
    titleString += ` and ${numCars - 1} more car${numCars === 2 ? '' : 's'}`;
  }

  return titleString;
}

/**
 * @param {Array<string>} strings Ex: ['A', null, 'B', '', 'C']
 * @returns {string} Ex: 'A B C'
 */
export function joinIgnoringFalsy(strings, separator = ' ') {
  return strings.filter((str) => !!str).join(separator);
}

/**
 * @typedef {object} AddressObject
 * @property {string} address
 * @property {string} city
 * @property {string} state
 * @property {string} zip
 */

/**
 * Formats an address object into an address string.
 * @param {AddressObject} addressObject Address object to format
 * @returns {string} Formatted address
 */
export function formatAddress({ address, city, state, zip }) {
  const everythingExceptZip = joinIgnoringFalsy([address, city, state], ', ');

  if (zip) {
    return `${everythingExceptZip} ${zip}`;
  }

  return everythingExceptZip;
}

/**
 * @typedef {object} PrettifyLiabilityStringOptions
 * @property {boolean} interpretAsThousands
 */

/**
 * @param {string} liabilityString Ex: "NONE", "500", "300/500/600", "500 CSL"
 * @param {PrettifyLiabilityStringOptions} options
 * @returns {string} Ex: $300K | $500K | $600K
 */
export function prettifyLiabilityString(liabilityString, { interpretAsThousands = true } = {}) {
  if (['NONE', 'NO COVERAGE'].includes(liabilityString.toUpperCase())) {
    return 'No Coverage';
  }

  const formatValue = (value) => {
    const valueAsLocaleString = `$${Number(value).toLocaleString('en-US')}`;
    return interpretAsThousands ? `${valueAsLocaleString}K` : valueAsLocaleString;
  };

  // "300/500/600" -> "$300 | $500 | $600" or "$300K | $500K | $600K"
  if (liabilityString.includes('/')) {
    return liabilityString.split('/').map(formatValue).join(' | ');
  }

  // "500 CSL" -> "$500K CSL"
  if (liabilityString.includes(' ')) {
    const [value, suffix] = liabilityString.split(' ');
    return [formatValue(value), suffix].join(' ');
  }

  // "500" -> "$500" or "$500 K"
  return formatValue(liabilityString);
}

/**
 * @param {string|Date} date
 * @returns {string} Ex: Jan 31, 2018
 */
export function formatDate(date) {
  // For a date string like "2020-11-10":
  try {
    const newDate = typeof date === 'string' ? parseISO(date) : date;
    return format(newDate, 'MM/dd/yyyy');
  } catch (e) {
    return date;
  }
}

/**
 * @param {Date} datetime
 * @returns {string} Ex: Jan 31, 2018
 */
export function formatDateTime(dateTime) {
  const options = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  };
  return dateTime.toLocaleDateString('en-US', options);
}

/**
 * 12345.67 -> "$12,345.67"
 * @param {number} amount
 */
export function formatDollars(amount) {
  return amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}

/**
 * .01 -> "1%"
 * @param {number} amount
 */
export function formatPercentage(amount) {
  return amount.toLocaleString('en-US', { style: 'percent' });
}

/**
 * .01 -> "1%"
 * 1234 -> "$1,234"
 * @param {number} amount
 */
export function formatDeductible(amount) {
  return amount < 1 ? formatPercentage(amount) : formatDollars(amount);
}

/**
 * @param {string} str
 * @returns {string}
 */
export function base64Decode(str) {
  return Base64.decode(str);
}

/**
 *
 * @param {string} str
 * @returns {string}
 */
export function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * @param {string} phoneNumberStr Phone number string
 * @returns {string} Formatted phone number
 * @example
 * formatPhoneNumber("") // ->
 */
export function formatPhoneNumber(phoneNumberStr) {
  const phoneNumberMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  let sanitizedPhoneNumber = phoneNumberStr;
  if (phoneNumberStr.startsWith('+1')) {
    sanitizedPhoneNumber = phoneNumberStr.substr('+1'.length);
  }

  return conformToMask(sanitizedPhoneNumber, phoneNumberMask).conformedValue;
}
