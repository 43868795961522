import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import closeIconOrange from '../../assets/icons/close-orange.svg';
import closeIconWhite from '../../assets/icons/close-white.svg';
import theme from '../../theme';

const styles = {
  backdrop: {
    backgroundColor: `${theme.colors.hunterGreen}d9` // 'd9' is the alpha value
  },
  modalRoot: {
    '@media(max-width: 560px)': {
      padding: '0px !important'
    }
  },
  modalPaper: {
    backgroundColor: theme.colors.lightBeige,
    borderRadius: 6,
    boxShadow: '0 2px 2px 0 #0000003d, 0 0 2px 0 #0000001e',
    flexDirection: 'column',
    overflowY: 'hidden',
    maxWidth: 'unset',
    '@media(max-width: 560px)': {
      width: '100%',
      height: '100%',
      maxHeight: '100%',
      borderRadius: 0,
      margin: 0
    }
  },
  modalHeader: {
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'flex-end',
    margin: 8
  },
  modalCloseButton: {
    boxSizing: 'content-box',
    height: 24,
    width: 24
  },
  modalCloseButtonIcon: {
    height: 24,
    width: 24
  }
};

function Modal({ classes, open, onClose, children, className, noCloseButton, closeButtonColor }) {
  const closeIconToUse = closeButtonColor === 'orange' ? closeIconOrange : closeIconWhite;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{
        root: classes.modalRoot,
        container: classes.backdrop,
        paper: clsx(classes.modalPaper, className)
      }}
    >
      <div className={classes.modalHeader}>
        {!noCloseButton && (
          <IconButton className={classes.modalCloseButton} onClick={onClose}>
            <img src={closeIconToUse} alt="Close" className={classes.modalCloseButtonIcon} />
          </IconButton>
        )}
      </div>
      <div>{children}</div>
    </Dialog>
  );
}

Modal.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  open: PropTypes.bool,
  noCloseButton: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
  closeButtonColor: PropTypes.oneOf(['orange', 'white'])
};

Modal.defaultProps = {
  className: '',
  open: false,
  children: null,
  noCloseButton: false,
  onClose: () => null,
  closeButtonColor: 'orange'
};

export default withStyles(styles)(Modal);
