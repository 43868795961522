import { isBefore, isAfter, parseISO } from 'date-fns';

const filterPoliciesBasedOnTerm = (policies) => {
  const priorPolicies = policies.filter((policy) => isBefore(parseISO(policy.endDate), new Date()));
  const currentPolicies = policies.filter(
    (policy) => isBefore(parseISO(policy.effectiveDate), new Date()) && isAfter(parseISO(policy.endDate), new Date())
  );
  const renewalPolicies = policies.filter((policy) => isAfter(parseISO(policy.effectiveDate), new Date()));

  return { priorPolicies, currentPolicies, renewalPolicies };
};

export default filterPoliciesBasedOnTerm;
