import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import theme from '../../../theme';
import { autoPolicyTitleString, formatAddress } from '../../../util/strings';
import CardFieldGroup from '../../card/card-field-group';
import CardLabel from '../../card/card-label';
import CardText from '../../card/card-text';
import Modal from '../../modal';
import ModalBody from '../../modal/modal-body';
import ModalTitle from '../../modal/modal-title';
import PaymentMethodDisplay from '../payment-method-display';
import ModifyBankAccountForm from './modify-bank-account-form';
import ModifyCardForm from './modify-card-form';

const styles = {
  subtitleText: {
    fontSize: 24,
    fontWeight: 300,
    marginTop: 16,
    color: theme.colors.pesto
  },
  changeMethodContainer: {
    marginTop: 24
  }
};

function ModifyPaymentMethodDialog({
  classes,
  open,
  onClose,
  policyData,
  activePaymentMethodBrand,
  activePaymentMethodLast4,
  onModified
}) {
  const { policyType, policyDetails, paymentMethod, id: policyId } = policyData;

  const policyTitle =
    policyType === 'A' ? autoPolicyTitleString(policyDetails.cars) : formatAddress(policyDetails.home.homeLocation);

  return (
    <Modal open={open} onClose={onClose}>
      <ModalTitle responsive>Modify payment method</ModalTitle>
      <ModalBody>
        <CardFieldGroup>
          <CardText>{policyTitle}</CardText>
        </CardFieldGroup>
        <div className={classes.subtitleText}>Current payment method</div>
        <CardFieldGroup>
          <CardLabel>Type</CardLabel>
          <CardText>{paymentMethod === 'C' ? 'Credit Card' : 'ACH/ETF'}</CardText>
        </CardFieldGroup>
        <CardFieldGroup>
          <CardLabel>Details</CardLabel>
          <PaymentMethodDisplay
            method={paymentMethod}
            brand={activePaymentMethodBrand}
            last4={activePaymentMethodLast4}
          />
        </CardFieldGroup>
        <div className={classes.changeMethodContainer}>
          {paymentMethod === 'C' ? (
            <ModifyCardForm policyId={policyId} onCompleted={onModified} />
          ) : (
            <ModifyBankAccountForm policyId={policyId} onCompleted={onModified} />
          )}
        </div>
      </ModalBody>
    </Modal>
  );
}

ModifyPaymentMethodDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  policyData: PropTypes.object.isRequired,
  activePaymentMethodBrand: PropTypes.string.isRequired,
  activePaymentMethodLast4: PropTypes.string.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onModified: PropTypes.func.isRequired
};

ModifyPaymentMethodDialog.defaultProps = {
  open: false
};

export default withStyles(styles)(ModifyPaymentMethodDialog);
