import React, { useEffect, useState } from 'react';
import Button from '../../button';
import ZendeskWidgetController from '../../../util/zendesk-widget-controller';
import Auth from '@aws-amplify/auth';
import logo from '../../../assets/images/logo.svg';
import iconBlog from '../../../assets/icons/blog.svg';
import iconChat from '../../../assets/icons/chat.svg';
import iconTelephone from '../../../assets/icons/telephone.svg';
import clsx from 'clsx';
import backgroundLeft from '../../../assets/images/background-left.svg';
import backgroundRight from '../../../assets/images/background-right.svg';
import PropTypes from 'prop-types';
import { makeStyles, Modal as MUIModal } from '@material-ui/core';
import { NoticeCopyTextPriceDecrease, NoticeCopyTextPriceIncrease } from './bix-conversion-notice-copy-text';
import Modal from '../../modal';
import ModalBody from '../../modal/modal-body';
import ModalTitle from '../../modal/modal-title';
import theme from '../../../theme';

const breakpoint = '850px';
const bixBlogPostLink = 'https://ourbranch.com/s/blog/branch_insurance_exchange';

const useStyles = makeStyles({
  overlayModal: {
    height: '100vh',
    width: '100vw',
    backgroundColor: theme.colors.beige,
    overflowY: 'auto'
  },
  header: {
    display: 'flex',
    alignItems: 'center'
  },
  headerLogo: {
    height: 48,
    marginLeft: 40,
    marginTop: 24,
    marginBottom: 24,
    [`@media(max-width: ${breakpoint})`]: {
      marginLeft: 24
    }
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  footer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `2px solid ${theme.colors.hunterGreen}`,
    transform: 'translate(0, -150px)',
    pointerEvents: 'none',
    [`@media(max-width: ${breakpoint})`]: {
      display: 'none'
    }
  },
  footerBackgroundImage: {
    height: 353,
    userSelect: 'none'
  },
  content: {
    width: '100%',
    maxWidth: 800,
    marginTop: 56,
    [`@media(max-width: ${breakpoint})`]: {
      maxWidth: 'unset',
      paddingLeft: 24,
      paddingRight: 24
    }
  },
  greetingText: {
    fontFamily: theme.fonts.LibreFranklin,
    fontWeight: 600,
    fontSize: 18,
    color: theme.colors.turtleGreen
  },
  titleText: {
    fontFamily: theme.fonts.LibreFranklin,
    fontWeight: 600,
    fontSize: 32,
    color: theme.colors.pesto,
    marginTop: 8
  },
  learnMoreSection: {
    marginTop: 16,
    display: 'flex',
    '& $learnMoreSectionItem': {
      marginRight: 16
    },
    [`@media(max-width: ${breakpoint})`]: {
      flexDirection: 'column',
      '& $learnMoreSectionItem': {
        marginBottom: 8
      }
    }
  },
  learnMoreSectionItem: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  learnMoreSectionItemIcon: {
    height: 40,
    width: 40,
    marginRight: 8
  },
  learnMoreSectionItemText: {
    fontFamily: theme.fonts.LibreFranklin,
    fontWeight: 600,
    fontSize: 16,
    color: theme.colors.carrot
  },
  actionsContainer: {
    marginTop: 40,
    padding: '32px 24px',
    borderRadius: 8,
    backgroundColor: theme.colors.darkBeige,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [`@media(max-width: ${breakpoint})`]: {
      marginBottom: 32
    }
  },
  actionText: {
    fontFamily: theme.fonts.LibreFranklin,
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 1.33,
    color: theme.colors.turtleGreen,
    textAlign: 'center'
  },
  actionButtonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    '& $actionButtonWrapper:not(:last-child)': {
      marginRight: 16,
      [`@media(max-width: ${breakpoint})`]: {
        marginRight: 'unset',
        marginTop: 16
      }
    },
    [`@media(max-width: ${breakpoint})`]: {
      width: '100%',
      flexDirection: 'column-reverse',
      alignItems: 'center'
    }
  },
  actionButtonWrapper: {
    width: 200,
    [`@media(max-width: ${breakpoint})`]: {
      width: '100%'
    }
  },
  actionButton: {
    width: '100%'
  },
  ctaButton: {
    backgroundColor: theme.colors.carrot,
    '&:hover': {
      backgroundColor: theme.colors.ochre
    }
  },
  passOnOfferText: {
    fontFamily: theme.fonts.LibreFranklin,
    fontSize: 16,
    textAlign: 'center',
    color: theme.colors.turtleGreen,
    marginTop: 24
  },
  notInterestedModal: {
    maxWidth: 600,
    [`@media(max-width: ${breakpoint})`]: {
      maxWidth: 'unset'
    }
  },
  notInterestedModalTitleText: {
    fontFamily: theme.fonts.LibreFranklin,
    fontSize: 32,
    fontWeight: 600,
    color: theme.colors.pesto,
    lineHeight: 1.25
  },
  notInterestedModalBodyText: {
    fontFamily: theme.fonts.LibreFranklin,
    fontSize: 16,
    color: theme.colors.turtleGreen
  },
  notInterestedModalButtonsContainer: {
    display: 'flex',
    marginTop: 32,
    [`@media(max-width: ${breakpoint})`]: {
      width: '100%',
      flexDirection: 'column-reverse',
      alignItems: 'center'
    }
  },
  notInterestedModalButtonWrapper: {
    marginRight: 16,
    [`@media(max-width: ${breakpoint})`]: {
      marginRight: 'unset',
      marginBottom: 16,
      width: '100%'
    }
  }
});

function BixConversionNotice({
  visible,
  onAskMeLaterClicked,
  onSignNowClicked,
  policyBeingRenewedPolicyId,
  oldPriceString,
  newPriceString,
  isPriceDecrease,
  renewalDateString,
  isMandatory,
  onUserNotInterestedInConverting
}) {
  const [firstName, setFirstName] = useState('');
  const [notInterestedModalOpen, setNotInterestedModalOpen] = useState(false);

  useEffect(() => {
    async function effect() {
      const { attributes } = await Auth.currentAuthenticatedUser();
      setFirstName(attributes.name);
    }
    effect();
  }, []);

  const styles = useStyles();

  const onNotInterestedModalPassOnOfferClicked = () => {
    setNotInterestedModalOpen(false);
    onUserNotInterestedInConverting();
  };

  if (visible) {
    return (
      <MUIModal open>
        <div className={styles.overlayModal}>
          <div className={styles.header}>
            <img src={logo} className={styles.headerLogo} alt="" />
          </div>
          <div className={styles.body}>
            <div className={styles.content}>
              <h2 className={styles.greetingString}>HI {firstName && ` ${firstName}`},</h2>
              <h1 className={styles.titleText}>We have some big news for you!</h1>
              {isPriceDecrease ? (
                <NoticeCopyTextPriceDecrease
                  policyBeingRenewedPolicyId={policyBeingRenewedPolicyId}
                  renewalDateString={renewalDateString}
                  oldPriceString={oldPriceString}
                  newPriceString={newPriceString}
                />
              ) : (
                <NoticeCopyTextPriceIncrease
                  policyBeingRenewedPolicyId={policyBeingRenewedPolicyId}
                  renewalDateString={renewalDateString}
                  newPriceString={newPriceString}
                />
              )}
              <div className={styles.learnMoreSection}>
                <a
                  href={bixBlogPostLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.learnMoreSectionItem}
                >
                  <img src={iconBlog} className={styles.learnMoreSectionItemIcon} alt="" />
                  <span className={styles.learnMoreSectionItemText}>Read more</span>
                </a>
                <div onClick={() => ZendeskWidgetController.openChat()} className={styles.learnMoreSectionItem}>
                  <img src={iconChat} className={styles.learnMoreSectionItemIcon} alt="" />
                  <span className={styles.learnMoreSectionItemText}>Chat with us</span>
                </div>
                <a href="tel:(833)-427-2624" className={styles.learnMoreSectionItem}>
                  <img src={iconTelephone} className={styles.learnMoreSectionItemIcon} alt="" />
                  <span className={styles.learnMoreSectionItemText}>Call us at (833) 427-2624</span>
                </a>
              </div>
              <div className={styles.actionsContainer}>
                <h3 className={styles.actionText}>
                  Do you want to sign now to renew your policy and become eligible for the exciting features Branch
                  Insurance Exchange has to offer?
                </h3>
                <div className={styles.actionButtonsContainer}>
                  <div className={styles.actionButtonWrapper}>
                    <Button primary onClick={onAskMeLaterClicked} className={styles.actionButton}>
                      Ask me later
                    </Button>
                  </div>
                  <div className={styles.actionButtonWrapper}>
                    <Button primary onClick={onSignNowClicked} className={clsx(styles.actionButton, styles.ctaButton)}>
                      Yes, Sign Now
                    </Button>
                  </div>
                </div>
                <div className={styles.passOnOfferText}>
                  {isMandatory
                    ? 'Please complete the steps to finalize your renewal, lock in your new prices and avoid potentially having your policy non-renewed by clicking "Sign Now" above.'
                    : 'Want to maintain your current association?'}
                </div>
                {!isMandatory && <Button onClick={() => setNotInterestedModalOpen(true)}>Pass on this offer</Button>}
              </div>
            </div>
          </div>
          <div className={styles.footer}>
            <img src={backgroundLeft} className={styles.footerBackgroundImage} alt="" />
            <img src={backgroundRight} className={styles.footerBackgroundImage} alt="" />
          </div>
          <Modal
            open={notInterestedModalOpen}
            onClose={() => setNotInterestedModalOpen(false)}
            className={styles.notInterestedModal}
            classes={{ modalRoot: styles.notInterestedModalRoot }}
          >
            <ModalTitle responsive textClassName={styles.notInterestedModalTitleText}>
              Are you sure you want to pass on being part of the Branch Insurance Exchange?
            </ModalTitle>
            <ModalBody>
              <p className={styles.notInterestedModalBodyText}>
                {isMandatory
                  ? 'Your policy may be non-renewed.'
                  : 'You will pass on this offer until your next policy renewal.'}
              </p>
              <div className={styles.notInterestedModalButtonsContainer}>
                <div className={styles.notInterestedModalButtonWrapper}>
                  <Button primary onClick={() => setNotInterestedModalOpen(false)} className={styles.actionButton}>
                    No, go back
                  </Button>
                </div>
                <div className={styles.notInterestedModalButtonWrapper}>
                  <Button
                    primary
                    onClick={onNotInterestedModalPassOnOfferClicked}
                    className={clsx(styles.actionButton, styles.ctaButton)}
                  >
                    Yes, Pass on Offer
                  </Button>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </div>
      </MUIModal>
    );
  }

  return null;
}

BixConversionNotice.propTypes = {
  visible: PropTypes.bool.isRequired,
  policyBeingRenewedPolicyId: PropTypes.string.isRequired,
  oldPriceString: PropTypes.string.isRequired,
  newPriceString: PropTypes.string.isRequired,
  isPriceDecrease: PropTypes.bool.isRequired,
  renewalDateString: PropTypes.string.isRequired,
  isMandatory: PropTypes.bool.isRequired,
  onAskMeLaterClicked: PropTypes.func.isRequired,
  onSignNowClicked: PropTypes.func.isRequired,
  onUserNotInterestedInConverting: PropTypes.func.isRequired
};

export default BixConversionNotice;
