import { PolicyTerm } from './terminology';

export const getPolicyCardBackgroundColor = (policyTerm) => {
  if (policyTerm === PolicyTerm.Current) {
    return 'dark';
  }

  if (policyTerm === PolicyTerm.Prior) {
    return 'black';
  }

  if (policyTerm === PolicyTerm.Renewal) {
    return 'green';
  }
  return 'empty';
};
