import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import theme from '../../../theme';
import Button from '../../button';
import Modal from '../../modal';
import ModalBody from '../../modal/modal-body';
import ModalTitle from '../../modal/modal-title';

const useStyles = makeStyles({
  modal: {
    backgroundColor: theme.colors.ochre,
    padding: 20,
    maxWidth: 600
  },
  modalTitleText: {
    color: '#fff'
  },
  modalBodyText: {
    fontSize: 20,
    fontWeight: 'bold',
    color: theme.colors.turtleGreen
  },
  signDocumentsButtonContainer: {
    display: 'flex',
    marginTop: 64,
    width: '100%',
    justifyContent: 'flex-end'
  }
});
function UnsignedApplicationModal({ open, onClose, applicationUrl }) {
  const styles = useStyles();

  const signButtonClicked = () => {
    window.open(applicationUrl, '_blank');
  };

  return (
    <Modal open={open} onClose={onClose} className={styles.modal} closeButtonColor="white">
      <ModalTitle textClassName={styles.modalTitleText}>Your policy documents still require a signature.</ModalTitle>
      <ModalBody>
        <div className={styles.modalBodyText}>Sign your policy documents now to maintain your coverage</div>
        <div className={styles.signDocumentsButtonContainer}>
          <Button primary onClick={signButtonClicked}>
            Sign documents
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
}

UnsignedApplicationModal.propTypes = {
  applicationUrl: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired
};

UnsignedApplicationModal.defaultProps = {
  applicationUrl: '',
  open: false
};

export default UnsignedApplicationModal;
