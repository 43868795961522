import PropTypes from 'prop-types';
import React from 'react';

export default class PageSectionAccordion extends React.Component {
  static propTypes = {
    initialExpandedIndex: PropTypes.number,
    children: PropTypes.node
  };

  static defaultProps = {
    initialExpandedIndex: undefined,
    children: null
  };

  state = {
    expandedPanelIndex: this.props.initialExpandedIndex
  };

  onChange = (idx) => {
    if (this.state.expandedPanelIndex === idx) {
      return this.setState({ expandedPanelIndex: undefined });
    }

    this.setState({ expandedPanelIndex: idx });
  };

  render() {
    const { expandedPanelIndex } = this.state;
    const { children } = this.props;

    return (
      <>
        {React.Children.map(children, (child, idx) =>
          React.cloneElement(child, {
            expanded: expandedPanelIndex !== undefined && idx === expandedPanelIndex,
            onClick: () => this.onChange(idx)
          })
        )}
      </>
    );
  }
}
