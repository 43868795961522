export const TOOLTIPS = {
  AUTO_BODILY_INJURY_PROPERTY_DAMAGE_LIABILITY:
    'This is Auto Liability Coverage. 1st # = bodily injury limit per person. 2nd # = bodily injury limit per accident. 3rd # = property damage limit per accident.',
  AUTO_UN_OR_UNDER_INSURED_MOTORIST_BODILY_INJURY:
    'Coverage for an insured who has an accident with someone who has no insurance, or who has limits less than needed to pay for bodily injury damages.',
  AUTO_TOTAL_SIX_MONTH_PRICE:
    'This is the total amount you will pay over the course of 6 months for your auto insurance.',
  AUTO_MEDICAL_PAYMENTS_LIABILITY:
    'Coverage for medical service expenses required by insured persons as a result of a covered motor vehicle accident.',
  AUTO_COLLISION_DEDUCTIBLE:
    "The amount you'll pay out of pocket in the event your auto is damaged in a collision. We pay the rest, up to the limits outlined in your policy.",
  AUTO_COMPREHENSIVE_DEDUCTIBLE:
    "This is the amount you'll pay out of pocket in the event your auto is damaged through an event not covered under collision. Ex: contact with an animal, fire, hail or vandalism.",
  AUTO_UN_OR_UNDER_INSURED_MOTORIST_PROPERTY_DAMAGE:
    'Coverage for an insured who has an accident with someone who has no insurance, or who has limits less than needed to pay for property damage.',
  AUTO_ADDITIONAL_CUSTOM_PARTS:
    'The option to increase your coverage for permanently installed, appearance or performance enhancing non-manufacturer made changes to an auto.',
  AUTO_TRANSPORTATION_LIMIT:
    'Coverage in the event you require alternative transportation (e.g. a rental car) while your auto is being fixed as a result of a covered collision or comprehensive loss.',
  HOME_DWELLING_COVERAGE:
    'This is the limit Branch will pay to repair or replace your home in the event of a total loss.',
  HOME_FAMILY_LIABILITY_LIMIT:
    'Coverage for non-auto related injury to other people or damage to their property that an insured may be found legally responsible for in the event of a covered loss.',
  HOME_TOTAL_TWELVE_MONTH_PRICE:
    'This is the total amount you will pay over the course of 12 months for your home insurance.',
  HOME_UMBRELLA_COVERAGE_LIMIT: 'Additional liability protection above what your home & auto policies already offer.',
  HOME_INFO_RESIDENTS: 'The total number of residents, including you, who live in the home being insured.',
  HOME_INFO_FAMILIES_IN_DWELLING: 'The total number of families living within the structure.',
  HOME_INFO_NUMBER_OF_MORTGAGES: 'The total number of current mortgage(s) you have on the home.',
  HOME_INFO_CONSTRUCTION_TYPE:
    '"Brick" if your home is made of actual bricks, concrete, or blocks. Otherwise it is frame.',
  HOME_INFO_BASEMENT_TYPE:
    'If you have a full basement, indicates finished it is. If you have a crawlspace, then "Raised", otherwise "Slab."',
  HOME_INFO_EXTERIOR_WALL_TYPE:
    '"Brick" if your exterior wall is actual brick, concrete, or block. If your exterior wall is sided, then the closest type of siding. Otherwise "stucco" or "wood."',
  HOME_INFO_ROOF_TYPE:
    'If your home has asphalt or composition shingles, then "Composition." Otherwise the closest appropriate option.',
  HOME_INFO_ROOF_SHAPE:
    'If you have an A-shaped roof, then "Gable." If you have a roof with where all sides slope downwards to the walls then "Hip." If you have a shed, gambrel, or mansard roof, then "Other"',
  HOME_ADDITIONAL_LIVING_EXPENSE_LIMIT:
    'If you have a covered loss that makes your residence uninhabitable, this is the limit of reimbursement for costs associated with temporary housing.',
  HOME_PERSONAL_PROPERTY_LIMIT:
    'Coverage for personal property in your home in the event of things like theft or loss due to fire, up to this limit.',
  HOME_OTHER_STRUCTURES_LIMIT:
    'Coverage for property that is permantly affixed to the ground, but seperated from the dwelling, up to this limit. Examples are sheds, in ground pools, & detached garages.',
  HOME_GUEST_MEDICAL_LIMIT:
    'Coverage for medical expenses in the event a person sustains an injury on the insured premises, up to this limit.',
  HOME_DEDUCTIBLE:
    'For all claims on your policy that do not have a specific different deductible, this is your deductible.',
  HOME_DEDUCTIBLE_WIND_HAIL:
    'An out of pocket payment that may be required in the event of loss caused by wind. This deductible would apply instead of the general deductible.',
  HOME_DEDUCTIBLE_HURRICANE:
    'An out of pocket payment that may be required in the event of loss caused by a hurricane. This deductible would apply instead of the general deductible.'
};
