import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import theme from '../../theme';

const styles = {
  subtitle: {
    marginTop: 24,
    fontSize: 16,
    fontWeight: 600,
    color: theme.colors.carrot
  }
};

const CardSubtitle = ({ className, classes, children }) => (
  <div className={clsx(classes.subtitle, className)}>{children}</div>
);

CardSubtitle.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  children: PropTypes.node
};

CardSubtitle.defaultProps = {
  className: '',
  children: null
};

export default withStyles(styles)(CardSubtitle);
