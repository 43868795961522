import Auth from '@aws-amplify/auth';
import * as Sentry from '@sentry/browser';
import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import { useHistory } from 'react-router-dom';
import Button from '../../components/button';
import CardLabel from '../../components/card/card-label';
import CardText from '../../components/card/card-text';
import CardTitle from '../../components/card/card-title';
import TextField from '../../components/text-field';
import { track } from '../../util/analytics';
import { logError } from '../../util/devmode-logger';
import { analyticsIdentifyUser } from '../helpers';
import useSharedStyles from '../shared-styles';
import UserBeingAuthenticatedContainer from '../user-being-authenticated-container';

function PhoneSignInStep2() {
  const sharedStyles = useSharedStyles();
  const history = useHistory();

  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState('');

  const userBeingAuthenticatedContainer = UserBeingAuthenticatedContainer.useContainer();
  const user = userBeingAuthenticatedContainer.user;
  if (!user) {
    history.replace('/auth');
    return null;
  }

  const submitOtp = (e) => {
    e.preventDefault();
    setErrorText('');
    setLoading(true);
    Auth.sendCustomChallengeAnswer(user, otp)
      .then((user) => {
        analyticsIdentifyUser(user);
        track('Account Phone Sign In Completed');
        history.replace('/app');
      })
      .catch((err) => {
        Sentry.captureException(err);
        logError(err);
        setErrorText('Something went wrong. You may have entered the wrong code.');
        setLoading(false);
      });
  };

  return (
    <>
      <CardTitle className={sharedStyles.title}>Enter sign in code</CardTitle>
      <CardText className={sharedStyles.explanationText}>Enter the sign in code we texted to your phone.</CardText>
      <form onSubmit={submitOtp}>
        <InputMask
          inputMode="numeric"
          autoComplete="one-time-code"
          mask="999999"
          // 123___ -> 123
          // (mask characters, in this case underscores, show up in value)
          onChange={(e) => setOtp(e.target.value.replace(/\D/g, ''))}
          placeholder="Sign in code"
          value={otp}
          disabled={loading}
        >
          <TextField className={sharedStyles.textField} />
        </InputMask>
        <div className={sharedStyles.primaryButtonAndErrorTextContainer}>
          {errorText && <CardLabel error>{errorText}</CardLabel>}
          <Button
            primary
            type="submit"
            loading={loading}
            disabled={loading || otp.length !== 6}
            className={sharedStyles.primaryButton}
          >
            Sign In
          </Button>
        </div>
      </form>
    </>
  );
}

export default PhoneSignInStep2;
