import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import theme from '../../theme';

const styles = {
  container: {
    height: 18,
    width: 18
  },
  textStyle: {
    width: 108,
    height: 19,
    fontFamily: theme.fonts.LibreFranklin,
    fontSize: 16,
    fontWeight: 500,
    color: theme.colors.carrot
  }
};

const CardColumnSubLabel = ({ className, classes, children }) => (
  <div className={clsx(classes.container, classes.textStyle, className)}>{children}</div>
);

CardColumnSubLabel.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  children: PropTypes.node
};

CardColumnSubLabel.defaultProps = {
  className: '',
  children: null
};

export default withStyles(styles)(CardColumnSubLabel);
