import PropTypes from 'prop-types';
import React from 'react';
import Card from '../../../components/card';
import CardContent from '../../../components/card/card-content';
import CardFieldGroup from '../../../components/card/card-field-group';
import CardLabel from '../../../components/card/card-label';
import CardText from '../../../components/card/card-text';
import CardTitle from '../../../components/card/card-title';
import { TOOLTIPS } from '../../../util/constants';
import { formatDollars, prettifyLiabilityString } from '../../../util/strings';

const CarCoverageCard = ({ className, car }) => (
  <Card cardColor="dark" className={className}>
    <CardContent>
      <CardTitle>{`${car.year} ${car.make} ${car.model}`}</CardTitle>
      <CardFieldGroup>
        <CardLabel>VIN</CardLabel>
        <CardText>{car.VIN}</CardText>
      </CardFieldGroup>
      <CardFieldGroup>
        <CardLabel tooltip={TOOLTIPS.AUTO_COLLISION_DEDUCTIBLE}>Collision Deductible</CardLabel>
        <CardText>{prettifyLiabilityString(car.deductibleCollision, { interpretAsThousands: false })}</CardText>
      </CardFieldGroup>
      <CardFieldGroup>
        <CardLabel tooltip={TOOLTIPS.AUTO_COMPREHENSIVE_DEDUCTIBLE}>Comprehensive Deductible</CardLabel>
        <CardText>{prettifyLiabilityString(car.deductibleComprehensive, { interpretAsThousands: false })}</CardText>
      </CardFieldGroup>
      {car.limitUMPD && (
        <CardFieldGroup>
          <CardLabel tooltip={TOOLTIPS.AUTO_UN_OR_UNDER_INSURED_MOTORIST_PROPERTY_DAMAGE}>
            Un/Underinsured Motorist Property Damage
          </CardLabel>
          <CardText>{prettifyLiabilityString(car.limitUMPD, { interpretAsThousands: false })}</CardText>
        </CardFieldGroup>
      )}
      <CardFieldGroup>
        <CardLabel tooltip={TOOLTIPS.AUTO_ADDITIONAL_CUSTOM_PARTS}>Additional Custom Parts</CardLabel>
        <CardText>{prettifyLiabilityString(car.limitACPE, { interpretAsThousands: false })}</CardText>
      </CardFieldGroup>
      <CardFieldGroup>
        <CardLabel tooltip={TOOLTIPS.AUTO_TRANSPORTATION_LIMIT}>Transportation Limit</CardLabel>
        <CardText>
          {car.limitRental === 'NONE' ? 'No Coverage' : `${formatDollars(Number(car.limitRental))} / day for 30 days`}
        </CardText>
      </CardFieldGroup>
      <CardFieldGroup>
        <CardLabel>Roadside Assistance</CardLabel>
        <CardText>{car.roadsideAssistance ? 'Yes' : 'No Coverage'}</CardText>
      </CardFieldGroup>
    </CardContent>
  </Card>
);

CarCoverageCard.propTypes = {
  className: PropTypes.string,
  car: PropTypes.shape({
    VIN: PropTypes.string.isRequired,
    year: PropTypes.number.isRequired,
    make: PropTypes.string.isRequired,
    model: PropTypes.string.isRequired,
    deductibleCollision: PropTypes.string.isRequired,
    deductibleComprehensive: PropTypes.string.isRequired,
    limitUMPD: PropTypes.string,
    limitACPE: PropTypes.string.isRequired,
    limitRental: PropTypes.string.isRequired,
    roadsideAssistance: PropTypes.bool.isRequired
  }).isRequired
};

CarCoverageCard.defaultProps = {
  className: ''
};

export default CarCoverageCard;
