export default {
  colors: {
    beige: '#EAE2D6',
    lightBeige: '#F7F3EF',
    darkBeige: '#E1D7C9',
    linen: '#EDEAE1',
    pesto: '#727831',
    tallow: '#A09E89',
    turtleGreen: '#343b1b',
    hunterGreen: '#1b2514',
    yellow: '#FFD120',
    darkYellow: '#EAC11E',
    carrot: '#F89521',
    ochre: '#DD710E',
    terracotta: '#9e2921',
    gray: '#8C8880',
    sage: '#A09E89',
    black: '#000',
    green: '#535926',
    light: '#fff'
  },
  fonts: {
    // The `!important` makes sure that using a font explicitly
    // will override any font being inherited from higher up in the tree
    // (which will also be !important), but cannot be overridden by any
    // fonts coming from Material UI. (Ex: if you remove the !important,
    // tooltips from Material UI won't use the font you specify for the
    // selector '*')
    LibreFranklin:
      'Libre Franklin, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif !important'
  }
};
