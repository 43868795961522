import Auth from '@aws-amplify/auth';
import * as Sentry from '@sentry/browser';
import queryString from 'query-string';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Button from '../../components/button';
import CardLabel from '../../components/card/card-label';
import CardTitle from '../../components/card/card-title';
import Checkbox from '../../components/checkbox';
import Modal from '../../components/modal';
import ModalBody from '../../components/modal/modal-body';
import ModalTitle from '../../components/modal/modal-title';
import TextField from '../../components/text-field';
import { logError } from '../../util/devmode-logger';
import useSharedStyles from '../shared-styles';

function ForgotPasswordStep2() {
  const sharedStyles = useSharedStyles();
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [resetCode, setResetCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  const amplifySetNewPassword = (e) => {
    e.preventDefault();

    if (newPassword.length < 8 || !/\d/.test(newPassword)) {
      setErrorText("Password doesn't meet requirements");
      return;
    }

    setLoading(true);

    const { accountId } = queryString.parse(location.search);
    Auth.forgotPasswordSubmit(accountId, resetCode, newPassword)
      .then(() => {
        setErrorText('');
        setModalOpen(true);
      })
      .catch((err) => {
        Sentry.captureException(err);
        logError(err);
        setErrorText('Something went wrong. You might have entered the wrong code.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Modal open={modalOpen} onClose={() => history.replace('/auth/signin')}>
        <ModalTitle>All set!</ModalTitle>
        <ModalBody>You can now use your new password to sign in to your account.</ModalBody>
      </Modal>
      <CardTitle className={sharedStyles.title}>
        We sent a reset code to the verified contact method on your account
      </CardTitle>
      <form onSubmit={amplifySetNewPassword}>
        <div className={sharedStyles.textFieldWithLabelContainer}>
          <CardLabel>Enter your reset code</CardLabel>
          <TextField
            type="text"
            placeholder="Reset code"
            disabled={loading}
            className={sharedStyles.textFieldWithLabel}
            value={resetCode}
            onChange={(e) => setResetCode(e.target.value)}
          />
        </div>
        <div className={sharedStyles.textFieldWithLabelContainer}>
          <CardLabel>
            Enter your desired new password. Your password must be at least 8 characters and include 1 number.
          </CardLabel>
          <TextField
            type={showPassword ? 'text' : 'password'}
            placeholder="New password"
            disabled={loading}
            className={sharedStyles.textFieldWithLabel}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>
        <div className={sharedStyles.checkboxOrRadioField}>
          <Checkbox checked={showPassword} onChange={(e) => setShowPassword(e.target.checked)} />
          <span className={sharedStyles.checkboxOrRadioFieldText}>Show password</span>
        </div>
        <div className={sharedStyles.primaryButtonAndErrorTextContainer}>
          {errorText && <CardLabel error>{errorText}</CardLabel>}
          <Button
            primary
            type="submit"
            loading={loading}
            disabled={loading || !resetCode || !newPassword}
            className={sharedStyles.primaryButton}
          >
            Set password
          </Button>
        </div>
      </form>
    </>
  );
}

export default ForgotPasswordStep2;
