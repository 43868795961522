import { withStyles } from '@material-ui/core/styles';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React from 'react';
import { useQuery } from 'react-apollo';
import { track } from '../util/analytics';
import { formatDateTime } from '../util/strings';
import Card from './card';
import CardList from './card-list';
import CardListDivider from './card-list/card-list-divider';
import CardListItem from './card-list/card-list-item';
import CardListItemText from './card-list/card-list-item-text';
import CardListLoading from './card-list/card-list-loading';

const styles = {
  cardList: {
    maxHeight: 400,
    overflowY: 'auto'
  }
};

export const GET_MY_DOCS_QUERY = gql`
  {
    getMyDocs {
      policyId
      friendlyName
      lastModified
      url
    }
  }
`;

function DocumentCard({ policyId, classes, className }) {
  const { loading, data } = useQuery(GET_MY_DOCS_QUERY);

  return (
    <Card cardColor="light" className={className}>
      <CardList className={classes.cardList}>
        {loading ? (
          <CardListLoading />
        ) : (
          data.getMyDocs
            .filter((doc) => doc.policyId === policyId)
            .map((doc, idx) => (
              <React.Fragment key={doc.url}>
                {idx !== 0 && <CardListDivider />}
                <CardListItem
                  onClick={() => {
                    track('Account Downloaded Policy Doc', { name: doc.friendlyName });
                    window.open(doc.url, '_blank');
                  }}
                >
                  <CardListItemText primary={formatDateTime(new Date(doc.lastModified))} secondary={doc.friendlyName} />
                </CardListItem>
              </React.Fragment>
            ))
        )}
      </CardList>
    </Card>
  );
}

DocumentCard.propTypes = {
  policyId: PropTypes.string.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

DocumentCard.defaultProps = {
  className: ''
};

export default withStyles(styles)(DocumentCard);
