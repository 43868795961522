import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import Grow from '@material-ui/core/Grow';
import { withStyles } from '@material-ui/core/styles';

import buildQuoteUrl from '../../util/quote-url-builder';
import { PolicyType } from '../../util/terminology';
import LoadingScreen from '../../components/loading-screen';
import NoPolicyCard from '../../components/no-policy-card';
import PageSectionAccordion from '../../components/page-section-accordion';
import PageSectionAccordionPanel from '../../components/page-section-accordion/page-section-accordion-panel';
import PageSectionAccordionTitle from '../../components/page-section-accordion/page-section-accordion-title';
import RootRef from '../../components/root-ref';
import ScreenPadding from '../../components/screen-padding';
import { getAppBarHeight } from '../../components/shell';
import { autoPolicyTitleString } from '../../util/strings';
import { getPolicyIdFromUrlHash } from '../../util/url';
import AutoPolicySection from './components/auto-policy-section';

const styles = {
  noPolicyCardContainer: {
    maxWidth: 300
  }
};

const GET_AUTO_MAIN_DATA_QUERY = gql`
  query {
    getMyPolicies {
      id
      policyType
      effectiveDate
      endDate
      state
      premium
      surplusContribution
      policyDetails {
        drivers {
          firstName
          age
          middleName
          lastName
          gender
          maritalStatus
          isPrimary
          highestEducation
          ageFirstLicensed
          driversLicenseState
        }
        cars {
          VIN
          year
          make
          model
          bodyType
          primaryUse
          purchaseDate
        }
        autoCoverage {
          policyLimitBIPD
          policyLimitUMBI
          policyLimitMedicalPayments
        }
        includeRenters
        rentersCoverage {
          coverageX
          coverageY
          coverageCD
        }
      }
    }
  }
`;

class AutoMain extends React.Component {
  componentDidMount() {
    this.scrollToPolicyInHash();
  }

  scrollToPolicyInHash = () => {
    const policyId = getPolicyIdFromUrlHash();

    if (policyId && this[policyId]) {
      // If there's a policy ID in the URL hash, scroll to that policy
      window.scrollTo({
        top: this[policyId].offsetTop - getAppBarHeight(),
        behavior: 'smooth'
      });
    } else {
      // Otherwise scroll to the top of the document
      window.scrollTo({ top: 0 });
    }
  };

  addAutoPolicy = async () => {
    const url = await buildQuoteUrl(PolicyType.Auto);
    window.location.href = url;
  };

  render() {
    const { classes } = this.props;

    return (
      <Query query={GET_AUTO_MAIN_DATA_QUERY} onCompleted={this.scrollToPolicyInHash}>
        {({ loading, data }) => {
          if (loading) {
            return <LoadingScreen />;
          }

          const autoPolicies = data.getMyPolicies.filter((policy) => policy.policyType === 'A');

          if (!autoPolicies.length) {
            return (
              <ScreenPadding>
                <Grow in appear timeout={250}>
                  <div className={classes.noPolicyCardContainer}>
                    <NoPolicyCard
                      titleText="Want to cover your cars?"
                      actionText="Get Auto Insurance"
                      onClick={this.addAutoPolicy}
                    />
                  </div>
                </Grow>
              </ScreenPadding>
            );
          }

          const urlHashPolicyId = getPolicyIdFromUrlHash();
          const policyIndex = autoPolicies.findIndex((policy) => policy.id === urlHashPolicyId);
          const initialExpandedIndex = policyIndex === -1 ? 0 : policyIndex;

          return (
            <ScreenPadding>
              <Grow in appear timeout={250}>
                <div>
                  <PageSectionAccordion initialExpandedIndex={initialExpandedIndex}>
                    {autoPolicies.map((policy) => (
                      <RootRef
                        key={policy.id}
                        rootRef={(ref) => {
                          this[policy.id] = ref;
                        }}
                      >
                        <PageSectionAccordionPanel
                          noCollapse={autoPolicies.length === 1}
                          header={
                            <PageSectionAccordionTitle>
                              {autoPolicyTitleString(policy.policyDetails.cars)}
                            </PageSectionAccordionTitle>
                          }
                        >
                          <AutoPolicySection autoPolicyData={policy} />
                        </PageSectionAccordionPanel>
                      </RootRef>
                    ))}
                  </PageSectionAccordion>
                </div>
              </Grow>
            </ScreenPadding>
          );
        }}
      </Query>
    );
  }
}

AutoMain.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AutoMain);
