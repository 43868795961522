import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import CardFieldGroup from './card-field-group';

const styles = {
  noMarginCardFieldGroup: {
    marginTop: '0 !important'
  }
};

const NoMarginCardFieldGroup = ({ className, classes, ...props }) => (
  <CardFieldGroup className={classes.noMarginCardFieldGroup} {...props} />
);

NoMarginCardFieldGroup.propTypes = CardFieldGroup.propTypes;

export default withStyles(styles)(NoMarginCardFieldGroup);
