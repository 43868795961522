import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import theme from '../../theme';

const styles = {
  modalTitleContainer: {
    marginLeft: 48,
    marginRight: 48,
    '@media(max-width: 560px)': {
      marginLeft: 24,
      marginRight: 24
    }
  },
  modalTitle: {
    fontSize: 42,
    fontWeight: 100,
    color: theme.colors.pesto
  },
  modalTitleResponsive: {
    '@media(max-width: 560px)': {
      fontSize: 24,
      fontWeight: 300
    }
  }
};

// `responsive` causes the text to resize to be smaller on smaller screens as
// opposed to staying the same on all screens.
function ModalTitle({ classes, className, children, responsive, textClassName }) {
  return (
    <div className={clsx(classes.modalTitleContainer, className)}>
      <div className={clsx(classes.modalTitle, { [classes.modalTitleResponsive]: responsive }, textClassName)}>
        {children}
      </div>
    </div>
  );
}

ModalTitle.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  textClassName: PropTypes.string,
  children: PropTypes.node,
  responsive: PropTypes.bool
};

ModalTitle.defaultProps = {
  className: '',
  textClassName: '',
  children: null,
  responsive: false
};

export default withStyles(styles)(ModalTitle);
