import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import theme from '../theme';
import ZendeskWidgetController from '../util/zendesk-widget-controller';
import Button from './button';
import Card from './card';
import CardContent from './card/card-content';

const styles = {
  title: {
    fontSize: 32,
    fontWeight: 100,
    color: theme.colors.pesto,
    margin: 0
  },
  anchor: {
    textDecoration: 'none'
  },
  callToAction: {
    marginTop: 32,
    fontSize: 16,
    fontWeight: 500,
    color: theme.colors.carrot,
    marginBottom: 0
  }
};

const NoPolicyCard = ({ classes, className, titleText, actionText, onClick }) => (
  <Card cardColor="empty" className={className}>
    <CardContent>
      <h1 className={classes.title}>{titleText}</h1>
      <Button className={classes.callToAction} onClick={onClick || ZendeskWidgetController.openWidget}>
        {actionText}
      </Button>
    </CardContent>
  </Card>
);

NoPolicyCard.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  titleText: PropTypes.string.isRequired,
  actionText: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

NoPolicyCard.defaultProps = {
  className: '',
  onClick: null
};

export default withStyles(styles)(NoPolicyCard);
