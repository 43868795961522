import Grow from '@material-ui/core/Grow';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import BranchSpinner from './branch-spinner';

const styles = {
  loadingContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loadingIndicator: {
    height: 150,
    width: 150
  }
};

const LoadingScreen = ({ classes, className }) => (
  <div className={clsx(classes.loadingContainer, className)}>
    <Grow in timeout={250}>
      <BranchSpinner className={classes.loadingIndicator} />
    </Grow>
  </div>
);

LoadingScreen.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string
};

LoadingScreen.defaultProps = {
  className: ''
};

export default withStyles(styles)(LoadingScreen);
