import PropTypes from 'prop-types';
import React from 'react';
import { noMedPayStates } from '@ourbranch/lookups';
import Card from '../../../components/card';
import CardContent from '../../../components/card/card-content';
import CardFieldGroup from '../../../components/card/card-field-group';
import CardLabel from '../../../components/card/card-label';
import CardText from '../../../components/card/card-text';
import CardTitle from '../../../components/card/card-title';
import { TOOLTIPS } from '../../../util/constants';
import { formatDollars, prettifyLiabilityString } from '../../../util/strings';

const PolicyCoverageCard = ({ className, autoPolicyData }) => (
  <Card cardColor="dark" className={className}>
    <CardContent>
      <CardTitle>All Cars</CardTitle>
      <CardFieldGroup>
        <CardLabel tooltip={TOOLTIPS.AUTO_BODILY_INJURY_PROPERTY_DAMAGE_LIABILITY}>
          Bodily Injury / Property Damage Liability
        </CardLabel>
        <CardText>{prettifyLiabilityString(autoPolicyData.policyDetails.autoCoverage.policyLimitBIPD)}</CardText>
      </CardFieldGroup>
      <CardFieldGroup tooltips={TOOLTIPS.AUTO_UN_OR_UNDER_INSURED_MOTORIST_BODILY_INJURY}>
        <CardLabel>Un/Underinsured Motorist Bodily Injury</CardLabel>
        <CardText>{prettifyLiabilityString(autoPolicyData.policyDetails.autoCoverage.policyLimitUMBI)}</CardText>
      </CardFieldGroup>
      {!noMedPayStates[autoPolicyData.state] && (
        <CardFieldGroup>
          <CardLabel tooltip={TOOLTIPS.AUTO_MEDICAL_PAYMENTS_LIABILITY}>Medical Payments Liability</CardLabel>
          <CardText>
            {formatDollars(Number(autoPolicyData.policyDetails.autoCoverage.policyLimitMedicalPayments))}
          </CardText>
        </CardFieldGroup>
      )}
    </CardContent>
  </Card>
);

PolicyCoverageCard.propTypes = {
  className: PropTypes.string,
  autoPolicyData: PropTypes.shape({
    state: PropTypes.string.isRequired,
    policyDetails: PropTypes.shape({
      autoCoverage: PropTypes.shape({
        policyLimitBIPD: PropTypes.string.isRequired,
        policyLimitUMBI: PropTypes.string.isRequired,
        policyLimitMedicalPayments: PropTypes.string.isRequired
      }).isRequired
    }).isRequired
  }).isRequired
};

PolicyCoverageCard.defaultProps = {
  className: ''
};

export default PolicyCoverageCard;
