import { makeStyles, useMediaQuery } from '@material-ui/core';
import * as Sentry from '@sentry/browser';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React from 'react';
import { useQuery } from 'react-apollo';
import theme from '../../../theme';
import filterPoliciesBasedOnTerm from '../../../util/policy-filter';
import BranchSpinner from '../../branch-spinner';
import Modal from '../../modal';
import ModalBody from '../../modal/modal-body';
import ModalTitle from '../../modal/modal-title';
import AutoIconImage from './images/auto.svg';
import GlassIconImage from './images/glass.svg';
import HomeIconImage from './images/home.svg';
import RentersIconImage from './images/renters.svg';

const useStyles = makeStyles({
  modal: {
    overflowY: 'auto'
  },
  branchSpinnerContainerOuter: {
    display: 'flex',
    flex: 1,
    width: '100%',
    justifyContent: 'center'
  },
  branchSpinnerContainerInner: {
    height: 128,
    width: 128
  },
  optionsContainer: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    '@media(max-width: 643px)': {
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  optionItemCommon: {
    backgroundColor: '#FFF',
    borderRadius: 6,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  optionItemIconCommon: {
    height: 120,
    width: 120
  },
  titleTextCommon: {
    fontFamily: theme.fonts.LibreFranklin,
    fontWeight: 300,
    color: theme.colors.hunterGreen,
    fontSize: 20
  },
  subtitleTextCommon: {
    fontFamily: theme.fonts.LibreFranklin,
    fontWeight: 300,
    color: theme.colors.pesto,
    fontSize: 16
  },
  optionItemSquare: {
    composes: '$optionItemCommon',
    width: 240,
    height: 250,
    margin: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 8px'
  },
  optionItemSquareIcon: {
    composes: '$optionItemIconCommon',
    marginTop: 24
  },
  optionItemSquareTitleText: {
    composes: '$titleTextCommon',
    marginTop: 7,
    textAlign: 'center'
  },
  optionItemSquareSubtitleText: {
    composes: '$subtitleTextCommon',
    marginTop: 4,
    textAlign: 'center'
  },
  optionItemList: {
    composes: '$optionItemCommon',
    width: '100%',
    margin: '1px 0',
    padding: '16px',
    display: 'flex'
  },
  optionItemListIcon: {
    composes: '$optionItemIconCommon'
  },
  optionItemListTextContainer: {
    marginLeft: 8,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  optionItemListTitleText: {
    composes: '$titleTextCommon'
  },
  optionItemListSubtitleText: {
    composes: '$subtitleTextCommon',
    marginTop: 4
  }
});

const QUERY = gql`
  query {
    getMyPolicies {
      id
      policyType
      effectiveDate
      endDate
      state
      policyDetails {
        includeRenters
      }
    }
  }
`;

const glassClaimsLink =
  'https://submit.glassclaim.com/self-service/Default.aspx?ClientTag=0B3A3B02-5267-4302-A305-E742E1F1BFC5';
const regularClaimsLink = 'https://team-branch.zendesk.com/hc/en-us/requests/new?ticket_form_id=360000469452';

const claimItems = {
  glass: {
    icon: GlassIconImage,
    title: 'Glass Only Claim',
    subtitle: 'File a claim for your windshield or car glass only',
    link: glassClaimsLink
  },
  auto: {
    icon: AutoIconImage,
    title: 'Auto Claim (non-glass)',
    subtitle: 'File a claim for your auto policy',
    link: regularClaimsLink
  },
  home: {
    icon: HomeIconImage,
    title: 'Home Claim',
    subtitle: 'File a claim for your homeowners policy',
    link: regularClaimsLink
  },
  renters: {
    icon: RentersIconImage,
    title: 'Renters Claim',
    subtitle: 'File a claim for your renters policy',
    link: regularClaimsLink
  }
};

function OptionItemSquare({ icon, title, subtitle, onClick }) {
  const styles = useStyles();

  return (
    <div className={styles.optionItemSquare} onClick={onClick}>
      <img src={icon} className={styles.optionItemSquareIcon} alt="Icon" />
      <div className={styles.optionItemSquareTitleText}>{title}</div>
      <div className={styles.optionItemSquareSubtitleText}>{subtitle}</div>
    </div>
  );
}

function OptionItemList({ icon, title, subtitle, onClick }) {
  const styles = useStyles();

  return (
    <div className={styles.optionItemList} onClick={onClick}>
      <img src={icon} className={styles.optionItemListIcon} alt="Icon" />
      <div className={styles.optionItemListTextContainer}>
        <div className={styles.optionItemListTitleText}>{title}</div>
        <div className={styles.optionItemListSubtitleText}>{subtitle}</div>
      </div>
    </div>
  );
}

const optionItemPropTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

OptionItemSquare.propTypes = optionItemPropTypes;
OptionItemList.propTypes = optionItemPropTypes;

function ClaimsModal({ open, onClose }) {
  const styles = useStyles();

  const { loading, error, data } = useQuery(QUERY);

  if (error) {
    Sentry.captureException(error);
  }

  const policyTypesOwned = { home: false, auto: false, renters: false };
  const optionsToDisplay = [];

  if (data) {
    const { currentPolicies } = filterPoliciesBasedOnTerm(data.getMyPolicies);
    policyTypesOwned.home = currentPolicies.some((policy) => policy.policyType === 'H');
    policyTypesOwned.auto = currentPolicies.some((policy) => policy.policyType === 'A');
    policyTypesOwned.renters = currentPolicies.some((policy) => Boolean(policy.policyDetails.includeRenters));

    if (policyTypesOwned.auto) {
      optionsToDisplay.push('glass', 'auto');
    }

    if (policyTypesOwned.home) {
      optionsToDisplay.push('home');
    }

    if (policyTypesOwned.renters) {
      optionsToDisplay.push('renters');
    }
  }

  const mobileView = useMediaQuery('(max-width: 643px)');

  return (
    <Modal open={open} onClose={onClose} className={styles.modal}>
      <ModalTitle>What type of claim do you have?</ModalTitle>
      <ModalBody>
        {loading && (
          <div className={styles.branchSpinnerContainerOuter}>
            <div className={styles.branchSpinnerContainerInner}>
              <BranchSpinner />
            </div>
          </div>
        )}
        {!loading && data && (
          <div className={styles.optionsContainer}>
            {optionsToDisplay.map((option) => {
              const { icon, title, subtitle, link } = claimItems[option];
              const OptionComponent = mobileView ? OptionItemList : OptionItemSquare;
              return (
                <OptionComponent
                  key={option}
                  icon={icon}
                  title={title}
                  subtitle={subtitle}
                  onClick={() => window.open(link, '_blank')}
                />
              );
            })}
          </div>
        )}
      </ModalBody>
    </Modal>
  );
}

ClaimsModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired
};

ClaimsModal.defaultProps = {
  open: false
};

export default ClaimsModal;
