import config from '../aws-exports';
import Cookies from 'js-cookie';

export const identify = (userId, data) => {
  if (config.trackingEnabled && window.analytics) {
    window.analytics.identify(userId, data);
    import('logrocket').then(({ default: LogRocket }) => LogRocket.identify(userId, data));
  }
};

export const track = (event, data) => {
  if (config.trackingEnabled && window.analytics) {
    const sessionId = Cookies.get('ampSessionId') || Date.now();
    window.analytics.track(event, data, { integrations: { Amplitude: { sessionId } } });
    import('logrocket').then(({ default: LogRocket }) => LogRocket.track(event));
    Cookies.set('ampSessionId', sessionId, { expires: 0.5 / 24 });
  }
};
