import PropTypes from 'prop-types';
import React from 'react';
import CardColumn from '../../../../components/card-column';
import CardColumnHeader from '../../../../components/card-column/card-column-header';
import CardColumnLabel from '../../../../components/card-column/card-column-label';
import CardColumnSubtitle from '../../../../components/card-column/card-column-subtitle';
import DriverCard from './driver-card';

const DriversColumn = ({ className, autoPolicyData }) => {
  // Rearrange the drivers array so the primary driver is always at the top
  const { drivers } = autoPolicyData.policyDetails;
  if (!drivers[0].isPrimary) {
    const primaryDriverIndex = drivers.findIndex((driver) => driver.isPrimary);
    const [primaryDriver] = drivers.splice(primaryDriverIndex, 1);
    drivers.unshift(primaryDriver);
  }

  return (
    <CardColumn className={className}>
      <CardColumnHeader>
        <CardColumnSubtitle>Drivers</CardColumnSubtitle>
        <CardColumnLabel>{`${drivers.length} driver${drivers.length > 1 ? 's' : ''}`}</CardColumnLabel>
      </CardColumnHeader>
      {drivers.map((driver) => (
        <DriverCard driver={driver} key={`${driver.firstName}${driver.middleName}${driver.lastName}`} />
      ))}
    </CardColumn>
  );
};

DriversColumn.propTypes = {
  className: PropTypes.string,
  autoPolicyData: PropTypes.shape({
    policyDetails: PropTypes.shape({
      drivers: PropTypes.arrayOf(
        PropTypes.shape({
          firstName: PropTypes.string,
          middleName: PropTypes.string,
          lastName: PropTypes.string,
          isPrimary: PropTypes.bool.isRequired
        }).isRequired
      ).isRequired
    }).isRequired
  }).isRequired
};

DriversColumn.defaultProps = {
  className: ''
};

export default DriversColumn;
