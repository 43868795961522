import { jssPreset, StylesProvider } from '@material-ui/core';
import { create } from 'jss';
import jssCompose from 'jss-compose';
import PropTypes from 'prop-types';
import React from 'react';

const jss = create({
  plugins: [...jssPreset().plugins, jssCompose()]
});

const JssConfigProvider = ({ children }) => <StylesProvider jss={jss}>{children}</StylesProvider>;

JssConfigProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default JssConfigProvider;
