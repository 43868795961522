import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  card: {
    marginBottom: 16
  }
};

const CardColumn = ({ classes, className, children }) => {
  const styledChildren = React.Children.map(children, (child) => {
    if (!child) {
      return child;
    }

    return React.cloneElement(child, {
      className: clsx(child.props.className, classes.card)
    });
  });

  return <div className={clsx(classes.container, className)}>{styledChildren}</div>;
};
CardColumn.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  children: PropTypes.node
};

CardColumn.defaultProps = {
  className: '',
  children: null
};

export default withStyles(styles)(CardColumn);
