import PropTypes from 'prop-types';
import React from 'react';
import Card from '../../../../components/card';
import CardContent from '../../../../components/card/card-content';
import CardFieldGroup from '../../../../components/card/card-field-group';
import CardLabel from '../../../../components/card/card-label';
import CardSubtitle from '../../../../components/card/card-subtitle';
import CardText from '../../../../components/card/card-text';
import CardTitle from '../../../../components/card/card-title';
import { formatDollars } from '../../../../util/strings';
import { getPolicyCardBackgroundColor } from '../../../../util/get-policy-card-background-color';

const UmbrellaPolicyCard = ({ className, umbrellaPolicyData, policyTerm }) => {
  // this was loading without data, so made this check.
  let titleString;
  if (umbrellaPolicyData.policyDetails && umbrellaPolicyData.policyDetails.umbrellaCoverage) {
    const {
      liabilityCoverageLimit,
      numVehiclesTotal,
      numRentalProperties,
      numOtherProperties
    } = umbrellaPolicyData.policyDetails.umbrellaCoverage;

    titleString = `${numVehiclesTotal} vehicles and ${1 + numRentalProperties + numOtherProperties} properties`;

    return (
      <Card term={policyTerm} className={className} cardColor={getPolicyCardBackgroundColor(policyTerm)}>
        <CardContent>
          <CardLabel>Covering</CardLabel>
          <CardTitle>{titleString}</CardTitle>
          <CardSubtitle>Coverage</CardSubtitle>
          <CardFieldGroup>
            <CardLabel>Umbrella Coverage Limit</CardLabel>
            <CardText>{formatDollars(liabilityCoverageLimit)}</CardText>
          </CardFieldGroup>
          <CardSubtitle>Included in Home Premium</CardSubtitle>
        </CardContent>
      </Card>
    );
  }
  return null;
};

UmbrellaPolicyCard.propTypes = {
  className: PropTypes.string,
  umbrellaPolicyData: PropTypes.shape({
    policyDetails: PropTypes.shape({
      includeUmbrella: PropTypes.bool.isRequired,
      umbrellaCoverage: PropTypes.shape({
        numVehiclesTotal: PropTypes.number.isRequired,
        numRentalProperties: PropTypes.number.isRequired,
        numOtherProperties: PropTypes.number.isRequired,
        liabilityCoverageLimit: PropTypes.number.isRequired
      }).isRequired
    })
  }).isRequired,
  policyTerm: PropTypes.string.isRequired
};

UmbrellaPolicyCard.defaultProps = {
  className: ''
};

export default UmbrellaPolicyCard;
