import List from '@material-ui/core/List';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  cardList: {
    paddingTop: 32,
    paddingBottom: 32,
    backgroundColor: '#FFF'
  }
};

const CardList = withStyles(styles)(({ classes, className, children, ...props }) => (
  <List className={clsx(classes.cardList, className)} {...props}>
    {children}
  </List>
));

CardList.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

CardList.defaultProps = {
  className: '',
  children: null
};

export default CardList;
