import MuiCircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import theme from '../theme';

const styles = {
  colorPrimary: {
    color: theme.colors.hunterGreen
  },
  colorLight: {
    color: 'white'
  }
};

function CircularProgress({ classes, light, ...props }) {
  return (
    <MuiCircularProgress classes={{ colorPrimary: light ? classes.colorLight : classes.colorPrimary }} {...props} />
  );
}

CircularProgress.propTypes = {
  classes: PropTypes.object.isRequired,
  light: PropTypes.bool
};

CircularProgress.defaultProps = {
  light: false
};

export default withStyles(styles)(CircularProgress);
