import PropTypes from 'prop-types';
import React from 'react';
import CardColumn from '../../../components/card-column';
import CardColumnHeader from '../../../components/card-column/card-column-header';
import CardColumnSubtitle from '../../../components/card-column/card-column-subtitle';
import PolicyBillingCard from '../../../components/policy-billing-card';

const BillingColumn = ({ className, homePolicyData }) => (
  <CardColumn className={className}>
    <CardColumnHeader>
      <CardColumnSubtitle>Billing</CardColumnSubtitle>
    </CardColumnHeader>
    <PolicyBillingCard policyId={homePolicyData.id} />
  </CardColumn>
);

BillingColumn.propTypes = {
  className: PropTypes.string,
  homePolicyData: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired
};

BillingColumn.defaultProps = {
  className: ''
};

export default BillingColumn;
