import Grow from '@material-ui/core/Grow';
import { stateToTzMap } from '@ourbranch/state-to-tz-map';
import { zonedTimeToUtc } from 'date-fns-tz';
import gql from 'graphql-tag';
import React from 'react';
import { useQuery } from 'react-apollo';
import CardColumn from '../components/card-column';
import CardColumnHeader from '../components/card-column/card-column-header';
import CardColumnSubtitle from '../components/card-column/card-column-subtitle';
import LoadingScreen from '../components/loading-screen';
import Masonry from '../components/masonry';
import PolicyBillingCard from '../components/policy-billing-card';
import ScreenPadding from '../components/screen-padding';

const GET_BILLING_DATA_QUERY = gql`
  {
    getMyPolicies {
      id
      policyType
      premium
      surplusContribution
      effectiveDate
      state
      term
    }
  }
`;

const isRenewalPolicy = (policy) => {
  const effectiveDate = zonedTimeToUtc(policy.effectiveDate, stateToTzMap[policy.state]);
  return policy.term >= 2 && effectiveDate >= new Date();
};

const Billing = () => {
  const { loading, data } = useQuery(GET_BILLING_DATA_QUERY);

  if (loading) {
    return <LoadingScreen />;
  }

  const homePolicies = data.getMyPolicies.filter((p) => p.policyType === 'H' && !isRenewalPolicy(p));
  const autoPolicies = data.getMyPolicies.filter((p) => p.policyType === 'A' && !isRenewalPolicy(p));

  return (
    <ScreenPadding>
      <Grow in appear timeout={250}>
        <div>
          <Masonry centered={false}>
            {Boolean(homePolicies.length) && (
              <CardColumn>
                <CardColumnHeader>
                  <CardColumnSubtitle>Homeowners Policies</CardColumnSubtitle>
                </CardColumnHeader>
                {homePolicies.map((policy) => (
                  <PolicyBillingCard key={policy.id} policyId={policy.id} />
                ))}
              </CardColumn>
            )}
            {Boolean(autoPolicies.length) && (
              <CardColumn>
                <CardColumnHeader>
                  <CardColumnSubtitle>Auto Policies</CardColumnSubtitle>
                </CardColumnHeader>
                {autoPolicies.map((policy) => (
                  <PolicyBillingCard key={policy.id} policyId={policy.id} />
                ))}
              </CardColumn>
            )}
          </Masonry>
        </div>
      </Grow>
    </ScreenPadding>
  );
};

export default Billing;
