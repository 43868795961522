import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';
import theme from '../../theme';

const styles = {
  container: {
    backgroundColor: theme.colors.lightBeige,
    borderRadius: 2
  },
  menuList: {
    paddingTop: 0,
    paddingBottom: 0
  },
  menuItem: {
    fontSize: 16,
    color: theme.colors.turtleGreen
  },
  menuItemActive: {
    composes: '$menuItem',
    backgroundColor: theme.colors.linen,
    fontWeight: 500
  }
};

const HamburgerMenu = ({ anchorEl, open, onClose, className, classes }) => (
  <Menu
    anchorEl={anchorEl}
    open={open}
    onClose={onClose}
    MenuListProps={{ className: classes.menuList }}
    classes={{ paper: clsx(className, classes.container) }}
  >
    <MenuItem
      className={classes.menuItem}
      component={NavLink}
      to="/app"
      exact
      activeClassName={classes.menuItemActive}
      onClick={onClose}
    >
      All Products
    </MenuItem>
    <MenuItem
      className={classes.menuItem}
      component={NavLink}
      to="/app/auto"
      activeClassName={classes.menuItemActive}
      onClick={onClose}
    >
      Auto
    </MenuItem>
    <MenuItem
      className={classes.menuItem}
      component={NavLink}
      to="/app/home"
      activeClassName={classes.menuItemActive}
      onClick={onClose}
    >
      Homeowners
    </MenuItem>
    <MenuItem
      className={classes.menuItem}
      component={NavLink}
      to="/app/billing"
      activeClassName={classes.menuItemActive}
      onClick={onClose}
    >
      Billing
    </MenuItem>
  </Menu>
);

HamburgerMenu.propTypes = {
  anchorEl: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

HamburgerMenu.defaultProps = {
  anchorEl: undefined,
  open: false,
  onClose: () => null,
  className: ''
};

export default withStyles(styles)(HamburgerMenu);
