import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import * as Sentry from '@sentry/browser';

import { logError } from '../../../util/devmode-logger';
import BixConversionNotice from './bix-conversion-notice';
import { formatDate } from '../../../util/strings';

const GET_BIX_CONVERSION_NOTICES_TO_DISPLAY_QUERY = gql`
  query GetBixConversionNoticesToDisplay {
    getBixConversionNoticesToDisplay {
      notices {
        renewalPolicyId
        policyBeingRenewedPolicyId
        renewalDate
        preConversionPrice
        postConversionPrice
        isMandatory
        conversionApplicationLink
      }
    }
  }
`;

const BIX_CONVERSION_NOTICE_MARK_AS_NOT_INTERESTED_MUTATION = gql`
  mutation BixConversionNoticeMarkAsNotInterested($renewalPolicyId: ID!) {
    bixConversionNoticeMarkAsNotInterested(params: { renewalPolicyId: $renewalPolicyId }) {
      ok
    }
  }
`;

function BixConversionNoticeContainer() {
  const [noticeVisible, setNoticeVisible] = useState(false);
  const [noticeState, setNoticeState] = useState({
    renewalPolicyId: '',
    renewalDate: null,
    oldPrice: NaN,
    newPrice: NaN,
    priceDecrease: false,
    isMandatory: false,
    policyBeingRenewedPolicyId: '',
    conversionApplicationLink: ''
  });

  const { loading: queryLoading, data: queryData } = useQuery(GET_BIX_CONVERSION_NOTICES_TO_DISPLAY_QUERY, {
    onError: (apolloError) => {
      logError(JSON.stringify(apolloError, null, 2));
      Sentry.captureException(apolloError);
    }
  });

  const [bixConversionNoticeMarkAsNotInterestedMutation] = useMutation(
    BIX_CONVERSION_NOTICE_MARK_AS_NOT_INTERESTED_MUTATION,
    {
      onError: (apolloError) => {
        logError(JSON.stringify(apolloError, null, 2));
        Sentry.captureException(apolloError);
      }
    }
  );

  useEffect(() => {
    if (!queryLoading && queryData) {
      const notices = queryData.getBixConversionNoticesToDisplay.notices;
      if (!notices.length) {
        return;
      }

      // We'll only display the first notice, a scenario where there
      // are multiple notices to display is rare enough that we don't
      // want to bother handling it right now. The user will have to handle
      // the first notice (sign / mark as not interested) and then on reload
      // the second notice will be displayed.
      const notice = notices[0];
      setNoticeVisible(true);
      setNoticeState({
        renewalPolicyId: notice.renewalPolicyId,
        policyBeingRenewedPolicyId: notice.policyBeingRenewedPolicyId,
        renewalDate: new Date(notice.renewalDate),
        oldPrice: notice.preConversionPrice,
        newPrice: notice.postConversionPrice,
        priceDecrease: notice.postConversionPrice < notice.preConversionPrice,
        isMandatory: notice.isMandatory,
        conversionApplicationLink: notice.conversionApplicationLink
      });
    }
  }, [queryLoading, queryData]);

  const onSignNowClicked = () => {
    window.open(noticeState.conversionApplicationLink, '_blank');
    setNoticeVisible(false);
  };

  const onUserNotInterestedInConverting = () => {
    setNoticeVisible(false);
    bixConversionNoticeMarkAsNotInterestedMutation({
      variables: {
        renewalPolicyId: noticeState.renewalPolicyId
      }
    });
  };

  const oldPriceString = `$${Number(noticeState.oldPrice).toFixed(2)}`;
  const newPriceString = `$${Number(noticeState.newPrice).toFixed(2)}`;
  const renewalDateString = noticeState.renewalDate ? formatDate(noticeState.renewalDate) : '';

  return (
    <BixConversionNotice
      visible={noticeVisible}
      onAskMeLaterClicked={() => setNoticeVisible(false)}
      onSignNowClicked={onSignNowClicked}
      onUserNotInterestedInConverting={onUserNotInterestedInConverting}
      policyBeingRenewedPolicyId={noticeState.policyBeingRenewedPolicyId}
      oldPriceString={oldPriceString}
      newPriceString={newPriceString}
      renewalDateString={renewalDateString}
      isPriceDecrease={noticeState.priceDecrease}
      isMandatory={noticeState.isMandatory}
    />
  );
}

export default BixConversionNoticeContainer;
