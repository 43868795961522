import PropTypes from 'prop-types';
import React from 'react';
import { useQuery } from 'react-apollo';
import { Link } from 'react-router-dom';
import Card from '../../../../components/card';
import CardContent from '../../../../components/card/card-content';
import CardFieldGroup from '../../../../components/card/card-field-group';
import CardLabel from '../../../../components/card/card-label';
import CardSubtitle from '../../../../components/card/card-subtitle';
import CardText from '../../../../components/card/card-text';
import CardTitle from '../../../../components/card/card-title';
import { TOOLTIPS } from '../../../../util/constants';
import { getPolicyCardBackgroundColor } from '../../../../util/get-policy-card-background-color';
import { formatAddress, formatDate, formatDollars } from '../../../../util/strings';
import PolicyCardDocsList from '../policy-card-docs-list';
import { GET_NEXT_PAYMENT_DATE_QUERY } from '../shared-queries';
import { withMicrodepositsVerification } from '../validate-bank-account';

const HomePolicyCard = ({ className, homePolicyData, policyTerm }) => {
  const addressString = formatAddress(homePolicyData.policyDetails.home.homeLocation);
  const total12MonthPrice =
    homePolicyData.premium +
    (homePolicyData.surplusContribution || 0) +
    homePolicyData.fees.reduce((acc, curr) => acc + curr.amount, 0);

  const { loading, data } = useQuery(GET_NEXT_PAYMENT_DATE_QUERY, { variables: { policyId: homePolicyData.id } });

  return (
    <Card term={policyTerm} className={className} cardColor={getPolicyCardBackgroundColor(policyTerm)}>
      <CardContent>
        <CardLabel>Home</CardLabel>
        <Link to={`/app/home#${homePolicyData.id}`}>
          <CardTitle>{addressString}</CardTitle>
        </Link>
        <CardSubtitle>Coverage</CardSubtitle>
        <CardFieldGroup>
          <CardLabel tooltip={TOOLTIPS.HOME_DWELLING_COVERAGE}>Dwelling Coverage</CardLabel>
          <CardText>{formatDollars(homePolicyData.policyDetails.homeCoverage.coverageA)}</CardText>
        </CardFieldGroup>
        <CardFieldGroup>
          <CardLabel tooltip={TOOLTIPS.HOME_FAMILY_LIABILITY_LIMIT}>Family Liability Limit</CardLabel>
          <CardText>{formatDollars(homePolicyData.policyDetails.homeCoverage.coverageX)}</CardText>
        </CardFieldGroup>
        <CardSubtitle>Payment</CardSubtitle>
        <CardFieldGroup>
          <CardLabel tooltip={TOOLTIPS.HOME_TOTAL_TWELVE_MONTH_PRICE}>Total 12 Month Price</CardLabel>
          <CardText>{formatDollars(total12MonthPrice)}</CardText>
        </CardFieldGroup>
        <CardFieldGroup>
          <CardLabel>Next Payment</CardLabel>
          <CardText>
            {(() => {
              if (loading) {
                return '...';
              }

              const { nextPaymentDate, nextPaymentAmount } = (data && data.getMyBillingDetails) || {};

              return nextPaymentDate && nextPaymentAmount
                ? `${formatDollars(nextPaymentAmount)} on ${formatDate(nextPaymentDate)}`
                : 'On Policy Renewal';
            })()}
          </CardText>
        </CardFieldGroup>
      </CardContent>
      <PolicyCardDocsList policyId={homePolicyData.id} />
    </Card>
  );
};

HomePolicyCard.propTypes = {
  className: PropTypes.string,
  homePolicyData: PropTypes.shape({
    id: PropTypes.string,
    premium: PropTypes.number.isRequired,
    surplusContribution: PropTypes.number,
    fees: PropTypes.arrayOf(PropTypes.shape({ amount: PropTypes.number.isRequired })).isRequired,
    policyDetails: PropTypes.shape({
      home: PropTypes.shape({
        homeLocation: PropTypes.shape({
          address: PropTypes.string.isRequired,
          city: PropTypes.string.isRequired,
          state: PropTypes.string.isRequired,
          zip: PropTypes.string.isRequired
        }).isRequired
      }).isRequired,
      homeCoverage: PropTypes.shape({
        coverageA: PropTypes.number.isRequired,
        coverageX: PropTypes.number.isRequired
      }).isRequired
    }).isRequired
  }).isRequired,
  policyTerm: PropTypes.string.isRequired
};

HomePolicyCard.defaultProps = {
  className: ''
};

export default withMicrodepositsVerification(HomePolicyCard);
