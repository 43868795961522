import PropTypes from 'prop-types';
import React from 'react';
import Card from '../../../components/card';
import CardContent from '../../../components/card/card-content';
import CardFieldGroup from '../../../components/card/card-field-group';
import CardLabel from '../../../components/card/card-label';
import CardText from '../../../components/card/card-text';
import { formatDollars } from '../../../util/strings';

const UmbrellaCoverageCard = ({
  className,
  homePolicyData: {
    policyDetails: { umbrellaCoverage }
  }
}) => (
  <Card cardColor="dark" className={className}>
    <CardContent>
      <CardText>Umbrella Coverage</CardText>
      <CardFieldGroup>
        <CardLabel>Umbrella Coverage Limit</CardLabel>
        <CardText>{formatDollars(umbrellaCoverage.liabilityCoverageLimit)}</CardText>
      </CardFieldGroup>
      <CardFieldGroup>
        <CardLabel>Un/Underinsured Motorist Limit</CardLabel>
        <CardText>{formatDollars(umbrellaCoverage.uninsuredMotoristLimit)}</CardText>
      </CardFieldGroup>
      <CardFieldGroup>
        <CardLabel>Vehicles you own</CardLabel>
        <CardText>{umbrellaCoverage.numVehiclesTotal || 'Not Purchased'}</CardText>
      </CardFieldGroup>
      <CardFieldGroup>
        <CardLabel>Licensed Drivers Under Age 25</CardLabel>
        <CardText>{umbrellaCoverage.numLicensedDriversUnder25 || 'Not Purchased'}</CardText>
      </CardFieldGroup>
      <CardFieldGroup>
        <CardLabel>Golf Carts and Snowmobiles</CardLabel>
        <CardText>{umbrellaCoverage.numRVs || 'Not Purchased'}</CardText>
      </CardFieldGroup>
      <CardFieldGroup>
        <CardLabel>Motorcycles / Scooters</CardLabel>
        <CardText>{umbrellaCoverage.numMotorcyclesScooters || 'Not Purchased'}</CardText>
      </CardFieldGroup>
      <CardFieldGroup>
        <CardLabel>ATVs</CardLabel>
        <CardText>{umbrellaCoverage.numATVs || 'Not Purchased'}</CardText>
      </CardFieldGroup>
      <CardFieldGroup>
        <CardLabel>Personal Watercraft & Jet Skis</CardLabel>
        <CardText>{umbrellaCoverage.numPersonalWatercraft || 'Not Purchased'}</CardText>
      </CardFieldGroup>
      <CardFieldGroup>
        <CardLabel>Large Watercraft</CardLabel>
        <CardText>{umbrellaCoverage.numWatercraft || 'Not Purchased'}</CardText>
      </CardFieldGroup>
      <CardFieldGroup>
        <CardLabel>Rental Properties</CardLabel>
        <CardText>{umbrellaCoverage.numRentalProperties || 'Not Purchased'}</CardText>
      </CardFieldGroup>
      <CardFieldGroup>
        <CardLabel>Other Properties</CardLabel>
        <CardText>{umbrellaCoverage.numOtherProperties || 'Not Purchased'}</CardText>
      </CardFieldGroup>
      <CardFieldGroup>
        <CardLabel>Business at Home</CardLabel>
        <CardText>{umbrellaCoverage.presenceOfBusiness ? 'Yes' : 'No'}</CardText>
      </CardFieldGroup>
    </CardContent>
  </Card>
);

UmbrellaCoverageCard.propTypes = {
  className: PropTypes.string,
  homePolicyData: PropTypes.shape({
    policyDetails: PropTypes.shape({
      umbrellaCoverage: PropTypes.shape({
        uninsuredMotoristLimit: PropTypes.number.isRequired,
        presenceOfBusiness: PropTypes.bool.isRequired,
        liabilityCoverageLimit: PropTypes.number.isRequired,
        numVehiclesTotal: PropTypes.number.isRequired,
        numLicensedDriversUnder25: PropTypes.number.isRequired,
        numMotorcyclesScooters: PropTypes.number.isRequired,
        numATVs: PropTypes.number.isRequired,
        numRVs: PropTypes.number.isRequired,
        numPersonalWatercraft: PropTypes.number.isRequired,
        numWatercraft: PropTypes.number.isRequired,
        numRentalProperties: PropTypes.number.isRequired,
        numOtherProperties: PropTypes.number.isRequired
      }).isRequired
    }).isRequired
  }).isRequired
};

UmbrellaCoverageCard.defaultProps = {
  className: ''
};

export default UmbrellaCoverageCard;
