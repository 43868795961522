import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { Link, Route } from 'react-router-dom';
import theme from '../../theme';

const styles = {
  link: {
    textDecoration: 'none'
  },
  container: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16
  },
  activeIndicator: {
    position: 'absolute',
    bottom: 0,
    height: 4,
    width: '7%',
    backgroundColor: theme.colors.pesto,
    transition: 'height 0.1s linear'
  },
  activeIndicatorNoMatch: {
    height: 0
  },
  text: {
    fontSize: 18,
    fontWeight: 300,
    color: theme.colors.pesto
  },
  textActive: {
    fontWeight: 500,
    color: theme.colors.turtleGreen
  }
};

const AppBarLink = ({ title, to, exact, classes }) => (
  <Link to={to} className={classes.link}>
    <Route path={to} exact={exact}>
      {({ match }) => (
        <div className={classes.container}>
          <p className={clsx(classes.text, { [classes.textActive]: match })}>{title}</p>
          <div
            className={clsx(classes.activeIndicator, {
              [classes.activeIndicatorNoMatch]: !match
            })}
          />
        </div>
      )}
    </Route>
  </Link>
);

AppBarLink.propTypes = {
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  classes: PropTypes.object.isRequired
};

AppBarLink.defaultProps = {
  exact: false
};

export default withStyles(styles)(AppBarLink);
