import ConditionalWrap from 'conditional-wrap';
import { Collapse } from '@material-ui/core';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useQuery } from 'react-apollo';
import { Link } from 'react-router-dom';
import CardColumn from '../../../../components/card-column';
import CardColumnHeader from '../../../../components/card-column/card-column-header';
import CardColumnTitle from '../../../../components/card-column/card-column-title';
import NoPolicyCard from '../../../../components/no-policy-card';
import UmbrellaPolicyCard from './umbrella-policy-card';
import CardColumnSubLabel from '../../../../components/card-column/card-column-sub-label';
import CardColumnTermHeader from '../../../../components/card-column/card-column-term-header';
import filterPoliciesBasedOnTerm from '../../../../util/policy-filter';
import { PolicyTerm } from '../../../../util/terminology';

const GET_UMBRELLA_POLICIES_QUERY = gql`
  {
    getMyPolicies {
      id
      policyType
      effectiveDate
      endDate
      policyDetails {
        includeUmbrella
        umbrellaCoverage {
          liabilityCoverageLimit
          numVehiclesTotal
          numRentalProperties
          numOtherProperties
        }
      }
    }
  }
`;

const UmbrellaPoliciesColumn = ({ className }) => {
  const { loading, data } = useQuery(GET_UMBRELLA_POLICIES_QUERY);

  const [expandedPrior, setExpandedPrior] = useState(true);
  const [expandedCurrent, setExpandedCurrent] = useState(true);
  const [expandedRenewal, setExpandedRenewal] = useState(true);

  if (loading) {
    return null;
  }

  const hasHomePolicies = data.getMyPolicies.some((policy) => policy.policyType === 'H');
  const hasAutoPolicies = data.getMyPolicies.some((policy) => policy.policyType === 'A');

  const umbrellaPolicies = data.getMyPolicies.filter(
    // FIXME:
    // Auto policies also have `includeUmbrella` set to `true` for some reason.
    (policy) => policy.policyType === 'H' && policy.policyDetails.includeUmbrella
  );

  const hasUmbrellaPolicies = umbrellaPolicies.length > 0;
  const shouldDisplayUmbrella = (hasHomePolicies && hasAutoPolicies) || hasUmbrellaPolicies;

  const { priorPolicies, currentPolicies, renewalPolicies } = filterPoliciesBasedOnTerm(umbrellaPolicies);

  if (!shouldDisplayUmbrella) {
    return null;
  }

  return (
    <CardColumn className={className}>
      <ConditionalWrap condition={hasUmbrellaPolicies} wrap={(children) => <Link to="/app/home">{children}</Link>}>
        <CardColumnHeader>
          <CardColumnTitle>Umbrella</CardColumnTitle>
          {hasUmbrellaPolicies && <CardColumnSubLabel>More Details</CardColumnSubLabel>}
        </CardColumnHeader>
      </ConditionalWrap>
      {renewalPolicies?.length > 0 && (
        <>
          <CardColumnTermHeader expanded={expandedRenewal} setExpanded={setExpandedRenewal}>
            {currentPolicies?.length > 0 ? `Renewal${renewalPolicies.length === 1 ? '' : 's'}` : 'Future'}
          </CardColumnTermHeader>
          <Collapse in={expandedRenewal}>
            {renewalPolicies.map((policy) => (
              <UmbrellaPolicyCard umbrellaPolicyData={policy} className={className} policyTerm={PolicyTerm.Renewal} />
            ))}
          </Collapse>
        </>
      )}
      {currentPolicies.length > 0 && (
        <>
          <CardColumnTermHeader expanded={expandedCurrent} setExpanded={setExpandedCurrent}>
            {currentPolicies.length > 1 ? 'Current Terms' : 'Current Term'}
          </CardColumnTermHeader>
          <Collapse in={expandedCurrent}>
            {currentPolicies.map((policy) => (
              <UmbrellaPolicyCard umbrellaPolicyData={policy} className={className} policyTerm={PolicyTerm.Current} />
            ))}
          </Collapse>
        </>
      )}
      {priorPolicies.length > 0 && (
        <>
          <CardColumnTermHeader expanded={expandedPrior} setExpanded={setExpandedPrior}>
            {priorPolicies.length > 1 ? 'Prior Terms' : 'Prior Term'}
          </CardColumnTermHeader>
          <Collapse in={expandedPrior}>
            {priorPolicies.map((policy) => (
              <UmbrellaPolicyCard umbrellaPolicyData={policy} className={className} policyTerm={PolicyTerm.Prior} />
            ))}
          </Collapse>
        </>
      )}
      {!hasUmbrellaPolicies && (
        <NoPolicyCard titleText="Need more coverage? Add an umbrella policy!" actionText="Get Umbrella Insurance" />
      )}
    </CardColumn>
  );
};

UmbrellaPoliciesColumn.propTypes = {
  className: PropTypes.string
};

UmbrellaPoliciesColumn.defaultProps = {
  className: ''
};

export default UmbrellaPoliciesColumn;
