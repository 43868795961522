import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import theme from '../../theme';

const styles = {
  label: {
    fontSize: 14,
    color: theme.colors.turtleGreen,
    '@media(max-width: 414px)': {
      marginRight: 16
    }
  }
};

const CardColumnLabel = ({ classes, className, children }) => (
  <div className={clsx(classes.label, className)}>{children}</div>
);

CardColumnLabel.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  children: PropTypes.node
};

CardColumnLabel.defaultProps = {
  className: '',
  children: null
};

export default withStyles(styles)(CardColumnLabel);
