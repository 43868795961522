import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import theme from '../../../../theme';

const styles = {
  text: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.colors.pesto
  }
};

const PrimaryDriverLabel = ({ classes }) => <div className={classes.text}>Primary Driver</div>;

PrimaryDriverLabel.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PrimaryDriverLabel);
