import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import Card from '../../../../components/card';
import CardButton from '../../../../components/card/card-button';
import CardContent from '../../../../components/card/card-content';
import CardFieldGroup from '../../../../components/card/card-field-group';
import CardLabel from '../../../../components/card/card-label';
import CardText from '../../../../components/card/card-text';
import { TOOLTIPS } from '../../../../util/constants';
import { prettifyLiabilityString } from '../../../../util/strings';

const AutoCoverageCard = ({ className, autoPolicyData }) => (
  <Card cardColor="dark" className={className}>
    <CardContent>
      <CardFieldGroup>
        <CardLabel tooltip={TOOLTIPS.AUTO_BODILY_INJURY_PROPERTY_DAMAGE_LIABILITY}>
          Bodily Injury / Property Damage Liability
        </CardLabel>
        <CardText>{prettifyLiabilityString(autoPolicyData.policyDetails.autoCoverage.policyLimitBIPD)}</CardText>
      </CardFieldGroup>
      <CardFieldGroup>
        <CardLabel tooltip={TOOLTIPS.AUTO_UN_OR_UNDER_INSURED_MOTORIST_BODILY_INJURY}>
          Un/Underinsured Motorist Bodily Injury Liability
        </CardLabel>
        <CardText>{prettifyLiabilityString(autoPolicyData.policyDetails.autoCoverage.policyLimitUMBI)}</CardText>
      </CardFieldGroup>
      <Link to={`/app/auto/${autoPolicyData.id}`}>
        <CardButton>See More</CardButton>
      </Link>
    </CardContent>
  </Card>
);

AutoCoverageCard.propTypes = {
  className: PropTypes.string,
  autoPolicyData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    policyDetails: PropTypes.shape({
      autoCoverage: PropTypes.shape({
        policyLimitBIPD: PropTypes.string.isRequired,
        policyLimitUMBI: PropTypes.string.isRequired
      }).isRequired
    }).isRequired
  }).isRequired
};

AutoCoverageCard.defaultProps = {
  className: ''
};

export default AutoCoverageCard;
