import Auth from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import * as Sentry from '@sentry/browser';
import { InMemoryCache } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import React, { useEffect } from 'react';
import { ApolloProvider } from 'react-apollo';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { StripeProvider } from 'react-stripe-elements';
import awsExports from '../aws-exports';
import Shell from '../components/shell';
import { track } from '../util/analytics';
import AccountDetails from './account-details';
import AllProducts from './all-products';
import AutoCoverage from './auto-coverage';
import AutoMain from './auto-main';
import Billing from './billing';
import HomeCoverage from './home-coverage';
import HomeInfo from './home-info';
import HomeMain from './home-main';

const appSyncLinkOptions = {
  url: awsExports.graphqlEndpoint,
  region: awsExports.region,
  auth: {
    type: awsExports.authenticationType,
    jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken()
  }
};

const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    createAuthLink(appSyncLinkOptions),
    createSubscriptionHandshakeLink(
      awsExports.graphqlEndpoint,
      createHttpLink({ uri: awsExports.graphqlEndpoint, fetch: (...args) => window.fetch(...args) })
    )
  ]),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache'
    },
    mutate: {
      fetchPolicy: 'no-cache'
    },
    watchQuery: {
      fetchPolicy: 'no-cache'
    }
  }
});

const App = () => {
  const history = useHistory();

  useEffect(() => {
    Hub.listen('auth', (capsule) => {
      // eslint-disable-next-line default-case
      switch (capsule.payload.event) {
        case 'signIn': {
          Auth.currentAuthenticatedUser().then((user) => {
            if (user.username === 'anonymous') {
              Auth.signOut();
              history.replace('/auth');
            }
          });
          break;
        }
        // @TODO
        case 'signOut': {
          Promise.all([track('Account Sign Out', {}), apolloClient.clearStore(), () => Sentry.flush()]).then(() => {
            history.replace('/auth');
          });
        }
      }
    });
  }, [history]);

  return (
    <ApolloProvider client={apolloClient}>
      <StripeProvider apiKey={awsExports.stripeKey}>
        <Shell>
          <Switch>
            <Route path="/app" exact component={AllProducts} />
            <Route path="/app/auto" exact component={AutoMain} />
            <Route path="/app/auto/:policyId" exact component={AutoCoverage} />
            <Route path="/app/home" exact component={HomeMain} />
            <Route path="/app/home/:policyId" exact component={HomeInfo} />
            <Route path="/app/home/:policyId/coverage" exact component={HomeCoverage} />
            <Route path="/app/billing" exact component={Billing} />
            <Route path="/app/account-details" exact component={AccountDetails} />
            <Route render={() => <Redirect to="/app" />} />
          </Switch>
        </Shell>
      </StripeProvider>
    </ApolloProvider>
  );
};

export default App;
