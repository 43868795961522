function notifyListeners(listenerSet) {
  for (const listener of listenerSet) {
    listener();
  }
}

const onOpenListeners = new Set();
const onCloseListeners = new Set();

// The reason we're checking if window.zE / $zopim exists
// before calling it is because there's a ton of ad blockers
// that also block Zendesk, in which case calling window.zE
// $zopim will cause the app to crash with a "window.zE is not a function"
// error (similar for Zopim). Not having Zendesk isn't the experience
// we had in mind but it's still perfectly usable and is
// better than the app crashing completely.

if (window.zE) {
  window.zE('webWidget:on', 'close', () => {
    window.zE('webWidget', 'hide');
    notifyListeners(onCloseListeners);
  });
}

const ZendeskWidgetController = {
  setPrefillData({ accountId, name, email, phoneNumber }) {
    if (window.zE) {
      window.zE('webWidget', 'prefill', {
        name: { value: name },
        email: { value: email },
        phone: { value: phoneNumber }
      });
    }

    window.zESettings = {
      webWidget: {
        contactForm: {
          fields: [
            { id: 360020869092, prefill: { '*': name } },
            { id: 'email', prefill: { '*': email } },
            { id: 360017056671, prefill: { '*': phoneNumber } },
            { id: 360017021032, prefill: { '*': accountId } }
          ]
        },
        chat: {
          departments: {
            enabled: ['Member Support'],
            select: 'Member Support'
          }
        }
      }
    };
  },

  openWidget() {
    if (window.zE) {
      window.zE('webWidget', 'show');
      window.zE('webWidget', 'open');
      notifyListeners(onOpenListeners);
    }
  },

  openChat() {
    /* eslint-disable no-undef */
    if ($zopim) {
      $zopim.livechat.window.show();
      notifyListeners(onOpenListeners);
    }
    /* eslint-enable no-undef */
  },

  onOpen(func) {
    onOpenListeners.add(func);
    return () => onOpenListeners.delete(func);
  },

  onClose(func) {
    onCloseListeners.add(func);
    return () => onCloseListeners.delete(func);
  }
};

export default ZendeskWidgetController;
