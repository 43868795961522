import Grow from '@material-ui/core/Grow';
import { withStyles } from '@material-ui/core/styles';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React from 'react';
import { useQuery } from 'react-apollo';
import { Redirect } from 'react-router-dom';
import BackButton from '../../components/back-button';
import Card from '../../components/card';
import CardContent from '../../components/card/card-content';
import LoadingScreen from '../../components/loading-screen';
import PageSectionTitle from '../../components/page-section-title';
import ScreenPadding from '../../components/screen-padding';
import { formatAddress } from '../../util/strings';
import HomeInfoIcons from './components/home-info-icons';
import PropertyColumn from './components/property-column';
import ResidentsColumn from './components/residents-column';
import RoofInfo from './components/roof-info';

const GET_HOME_INFO_QUERY = gql`
  query {
    getMyPolicies {
      id
      policyDetails {
        people {
          highestEducation
          age
        }
        home {
          homeLocation {
            address
            address2
            city
            state
            zip
          }
          latitude
          longitude
          numOccupants
          ownershipStatus
          purchaseDate
          homeQuality
          yearBuilt
          typeOfHome
          numFamiliesInDwelling
          constructionType
          basementType
          basementSqFt
          exteriorWallType
          numFullBathrooms
          numHalfBathrooms
          sqFt
          numStories
          numFireplaces
          numWoodBurningStoves
          garageType
          garageCarCapacity
          roofOriginal
          roofType
          roofYear
          roofShape
          mortgageDetails {
            loanNumber
          }
        }
      }
    }
  }
`;

const styles = {
  screenPadding: {
    '@media(max-width: 451px)': {
      padding: '18px 0 0 0'
    }
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16
  },
  pageTitle: {
    margin: 0,
    '@media(max-width: 451px)': {
      marginRight: 8,
      marginLeft: 16,
      fontSize: 20,
      fontWeight: 'normal'
    }
  },
  cardContainer: {
    display: 'flex',
    '@media(max-width: 756px)': {
      justifyContent: 'center'
    }
  },
  card: {
    '@media(max-width: 451px)': {
      width: '100%',
      borderRadius: 0
    }
  },
  cardContent: {
    display: 'grid',
    gridGap: '32px',
    gridTemplateColumns: '308px 296px 296px',
    '@media(max-width: 1083px)': {
      gridTemplateColumns: '308px 296px'
    },
    '@media(max-width: 756px)': {
      gridTemplateColumns: '308px'
    },
    '@media(max-width: 451px)': {
      gridTemplateColumns: '1fr'
    }
  }
};

const HomeInfo = ({ classes, match }) => {
  const { loading, data } = useQuery(GET_HOME_INFO_QUERY);

  if (loading) {
    return <LoadingScreen />;
  }

  const matchedPolicy = data.getMyPolicies.find((policy) => policy.id === match.params.policyId);

  if (!matchedPolicy) {
    return <Redirect to="/app/home" />;
  }

  const { home } = matchedPolicy.policyDetails;

  return (
    <ScreenPadding className={classes.screenPadding}>
      <Grow in appear timeout={250}>
        <div>
          <div className={classes.headerContainer}>
            <BackButton />
            <PageSectionTitle className={classes.pageTitle}>{formatAddress(home.homeLocation)}</PageSectionTitle>
          </div>
          <div className={classes.cardContainer}>
            <Card cardColor="light" className={classes.card}>
              <CardContent className={classes.cardContent}>
                <ResidentsColumn homePolicyData={matchedPolicy} />
                <PropertyColumn homePolicyData={matchedPolicy} />
                <div>
                  <HomeInfoIcons homePolicyData={matchedPolicy} />
                  <RoofInfo homePolicyData={matchedPolicy} />
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </Grow>
    </ScreenPadding>
  );
};

HomeInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default withStyles(styles)(HomeInfo);
