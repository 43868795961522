import ConditionalWrap from 'conditional-wrap';
import { Collapse } from '@material-ui/core';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useQuery } from 'react-apollo';
import { Link } from 'react-router-dom';
import CardColumn from '../../../../components/card-column';
import CardColumnHeader from '../../../../components/card-column/card-column-header';
import CardColumnTitle from '../../../../components/card-column/card-column-title';
import NoPolicyCard from '../../../../components/no-policy-card';
import RentersPolicyCard from './renters-policy-card';
import CardColumnSubLabel from '../../../../components/card-column/card-column-sub-label';
import CardColumnTermHeader from '../../../../components/card-column/card-column-term-header';
import filterPoliciesBasedOnTerm from '../../../../util/policy-filter';
import { PolicyTerm } from '../../../../util/terminology';

const GET_RENTERS_POLICIES_QUERY = gql`
  {
    getMyPolicies {
      id
      policyType
      effectiveDate
      endDate
      policyDetails {
        includeRenters
        rentersCoverage {
          rentersLocation {
            address
            city
            state
            zip
          }
          coverageX
          coverageCD
        }
      }
    }
  }
`;

const RentersPoliciesColumn = ({ className }) => {
  const { loading, data } = useQuery(GET_RENTERS_POLICIES_QUERY);

  const [expandedPrior, setExpandedPrior] = useState(true);
  const [expandedCurrent, setExpandedCurrent] = useState(true);
  const [expandedRenewal, setExpandedRenewal] = useState(true);

  if (loading) {
    return null;
  }

  const hasHomeownersPolicies = data.getMyPolicies.some((policy) => policy.policyType === 'H');

  const rentersPolicies = data.getMyPolicies.filter((policy) => policy.policyDetails.includeRenters);
  const hasRentersPolicies = rentersPolicies.length > 0;

  if (hasHomeownersPolicies && !hasRentersPolicies) {
    return null;
  }

  const { priorPolicies, currentPolicies, renewalPolicies } = filterPoliciesBasedOnTerm(rentersPolicies);

  return (
    <CardColumn className={className}>
      <ConditionalWrap condition={hasRentersPolicies} wrap={(children) => <Link to="/app/auto">{children}</Link>}>
        <CardColumnHeader>
          <CardColumnTitle>Renters</CardColumnTitle>
          {hasRentersPolicies && <CardColumnSubLabel>More Details</CardColumnSubLabel>}
        </CardColumnHeader>
      </ConditionalWrap>
      {renewalPolicies?.length > 0 && (
        <>
          <CardColumnTermHeader expanded={expandedRenewal} setExpanded={setExpandedRenewal}>
            {currentPolicies?.length > 0 ? `Renewal${renewalPolicies.length === 1 ? '' : 's'}` : 'Future'}
          </CardColumnTermHeader>
          <Collapse in={expandedRenewal}>
            {renewalPolicies.map((policy) => (
              <RentersPolicyCard rentersPolicyData={policy} className={className} policyTerm={PolicyTerm.Renewal} />
            ))}
          </Collapse>
        </>
      )}
      {currentPolicies.length > 0 && (
        <>
          <CardColumnTermHeader expanded={expandedCurrent} setExpanded={setExpandedCurrent}>
            {currentPolicies.length > 1 ? 'Current Terms' : 'Current Term'}
          </CardColumnTermHeader>
          <Collapse in={expandedCurrent}>
            {currentPolicies.map((policy) => (
              <RentersPolicyCard rentersPolicyData={policy} className={className} policyTerm={PolicyTerm.Current} />
            ))}
          </Collapse>
        </>
      )}
      {priorPolicies.length > 0 && (
        <>
          <CardColumnTermHeader expanded={expandedPrior} setExpanded={setExpandedPrior}>
            {priorPolicies.length > 1 ? 'Prior Terms' : 'Prior Term'}
          </CardColumnTermHeader>
          <Collapse in={expandedPrior}>
            {priorPolicies.map((policy) => (
              <RentersPolicyCard rentersPolicyData={policy} className={className} policyTerm={PolicyTerm.Prior} />
            ))}
          </Collapse>
        </>
      )}
      {!hasRentersPolicies && (
        <NoPolicyCard titleText="Are you renting? We can cover you!" actionText="Get Renters Insurance" />
      )}
    </CardColumn>
  );
};

RentersPoliciesColumn.propTypes = {
  className: PropTypes.string
};

RentersPoliciesColumn.defaultProps = {
  className: ''
};

export default RentersPoliciesColumn;
