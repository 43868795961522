import IconButton from '@material-ui/core/IconButton';
import MUISnackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import theme from '../theme';

const useStyles = makeStyles({
  snackbar: {
    backgroundColor: theme.colors.hunterGreen,
    borderRadius: 6
  },
  snackbarContent: {
    backgroundColor: theme.colors.hunterGreen,
    color: theme.colors.beige
  },
  closeButton: {
    color: theme.colors.carrot
  }
});

function Snackbar({ className, onClose, ...props }) {
  const styles = useStyles();

  return (
    <MUISnackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      onClose={onClose}
      ContentProps={{
        classes: {
          root: styles.snackbarContent
        }
      }}
      action={
        <IconButton size="small" aria-label="close" onClick={onClose}>
          <CloseIcon fontSize="small" className={styles.closeButton} />
        </IconButton>
      }
      className={clsx(styles.snackbar, className)}
      {...props}
    />
  );
}

Snackbar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func
};

Snackbar.defaultProps = {
  className: '',
  onClose: () => null
};

export default Snackbar;
