import theme from '../../../../theme';
import exclamationIcon from '../../../../assets/icons/exclamation.svg';

const styles = {
  container: {
    marginBottom: 20,
    background: theme.colors.carrot
  },
  title: {
    fontSize: '32px',
    lineHeight: 1.25,
    fontWeight: 100
  },
  button: {
    marginTop: 24,
    width: '100%'
  },
  modalPaper: {
    width: '32%',
    minWidth: 610,
    '@media(max-width: 600px)': {
      width: '100%',
      minWidth: 'unset',
      overflow: 'auto'
    },
    '& div:nth-child(2)': {
      overflow: 'auto'
    }
  },
  bankInfo: {
    display: 'table',
    width: '100%',
    '& div': {
      display: 'table-row',
      '& span': {
        display: 'table-cell',
        width: '30%'
      }
    },
    '& div:first-child': {
      color: theme.colors.pesto
    }
  },
  transferInfo: {
    marginTop: '20px',
    marginBottom: '20px',
    borderRadius: '5px',
    padding: '10px',
    background: theme.colors.beige,
    '& a': {
      color: theme.colors.carrot
    }
  },
  formInputs: {
    display: 'flex',
    justifyContent: 'space-between',
    '@media(max-width: 600px)': {
      flexDirection: 'column',
      '& input': {
        width: '100%',
        marginBottom: 20
      }
    }
  },
  submitButton: {
    margin: 'auto',
    display: 'block',
    marginTop: 24,
    width: '30%',
    '@media(max-width: 600px)': {
      width: '100%'
    }
  },
  capitalize: {
    textTransform: 'capitalize'
  },
  warning: {
    color: theme.colors.terracotta,
    display: 'flex',
    marginTop: 20,
    '& a': {
      textDecoration: 'underline',
      color: theme.colors.carrot
    },
    '& p': {
      margin: 0
    }
  },
  warningIcon: {
    mask: `url(${exclamationIcon})`,
    maskSize: 'cover',
    background: theme.colors.terracotta,
    width: 30,
    height: 25,
    display: 'inline-block',
    marginRight: 20
  },
  bottomSpacing: {
    paddingBottom: 10
  },
  adornment: {
    marginLeft: 10,
    marginRight: 2
  },
  inputFieldContainer: {
    paddingTop: 15
  },
  inputFieldLabel: {
    transform: 'translate(1px, 1px) scale(0.75)!important',
    color: theme.colors.pesto
  },
  inputField: {
    color: theme.colors.pesto
  }
};

export default styles;
