import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import CardFieldGroup from '../../../components/card/card-field-group';
import CardLabel from '../../../components/card/card-label';
import CardText from '../../../components/card/card-text';
import { TOOLTIPS } from '../../../util/constants';
import { formatDollars } from '../../../util/strings';
import CoverageCardSubLabel from './coverage-card-sub-label';
import CoverageCardSubtitle from './coverage-card-subtitle';

const styles = {
  costToRebuildHome: {
    fontSize: 24
  }
};

const DwellingLimitsColumn = ({ classes, className, homePolicyData }) => {
  const { home, homeCoverage } = homePolicyData.policyDetails;

  const coverageB = (homeCoverage.coverageBPctOfA * homeCoverage.coverageA) / 100;
  const coverageC = (homeCoverage.coverageCPctOfA * homeCoverage.coverageA) / 100;
  const coverageD = (homeCoverage.coverageDPctOfA * homeCoverage.coverageA) / 100;

  const coverageIBSCValue = (homeCoverage.coverageIBSC * homeCoverage.coverageA) / 100;
  const dwellingCoverageTotal = homeCoverage.coverageA + coverageIBSCValue;

  return (
    <div className={className}>
      <CardText>Dwelling Limit</CardText>
      <CardFieldGroup>
        <CardLabel>Estimated Cost to Rebuild Home</CardLabel>
        <CardText className={classes.costToRebuildHome}>{formatDollars(home.replacementCostEstimate.total)}</CardText>
      </CardFieldGroup>
      <CardFieldGroup>
        <CardLabel tooltip={TOOLTIPS.HOME_DWELLING_COVERAGE}>Dwelling Limit</CardLabel>
        <CardText>
          {100 + homeCoverage.coverageIBSC}% ({formatDollars(dwellingCoverageTotal)})
        </CardText>
      </CardFieldGroup>
      <CoverageCardSubLabel>Other coverages calculated based on your dwellling:</CoverageCardSubLabel>
      <CardFieldGroup>
        <CardLabel tooltip={TOOLTIPS.HOME_ADDITIONAL_LIVING_EXPENSE_LIMIT}>Additional Living Expense Limit</CardLabel>
        <CardText>
          {homeCoverage.coverageDPctOfA}% ({formatDollars(coverageD)})
        </CardText>
      </CardFieldGroup>
      <CardFieldGroup>
        <CardLabel tooltip={TOOLTIPS.HOME_PERSONAL_PROPERTY_LIMIT}>Personal Property Limit</CardLabel>
        <CardText>
          {homeCoverage.coverageCPctOfA}% ({formatDollars(coverageC)})
        </CardText>
      </CardFieldGroup>
      <CardFieldGroup>
        <CardLabel tooltip={TOOLTIPS.HOME_OTHER_STRUCTURES_LIMIT}>Other Structures Limit</CardLabel>
        <CardText>
          {homeCoverage.coverageBPctOfA}% ({formatDollars(coverageB)})
        </CardText>
      </CardFieldGroup>
      <CoverageCardSubtitle>Liability</CoverageCardSubtitle>
      <CardFieldGroup>
        <CardLabel tooltip={TOOLTIPS.HOME_FAMILY_LIABILITY_LIMIT}>Family Liability</CardLabel>
        <CardText>{formatDollars(homeCoverage.coverageX)}</CardText>
      </CardFieldGroup>
      <CardFieldGroup>
        <CardLabel tooltip={TOOLTIPS.HOME_GUEST_MEDICAL_LIMIT}>Guest Medical Limit</CardLabel>
        <CardText>{formatDollars(homeCoverage.coverageY)}</CardText>
      </CardFieldGroup>
    </div>
  );
};

DwellingLimitsColumn.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  homePolicyData: PropTypes.object.isRequired
};

DwellingLimitsColumn.defaultProps = {
  className: ''
};

export default withStyles(styles)(DwellingLimitsColumn);
