import { makeStyles } from '@material-ui/core/styles';
import theme from '../../../theme';

const useStyles = makeStyles(() => ({
  container: {
    position: 'absolute',
    top: 400,
    right: 4
  },
  button: {
    color: theme.colors.gray,
    fontSize: 18,
    fontWeight: '600',
    padding: 0
  },
  'icon-small': {
    width: 24,
    height: 24
  },
  'icon-medium': {
    width: 32,
    height: 32
  },
  'icon-big': {
    width: 48,
    height: 48
  },
  caret: {
    transform: 'rotate(0deg)',
    transition: 'transform 0.3s linear'
  },
  caretOpen: {
    transform: 'rotate(180deg)',
    transition: 'transform 0.3s linear'
  }
}));

export default useStyles;
