import Fab from '@material-ui/core/Fab';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import Bowser from 'bowser';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import exclamation from '../../assets/icons/exclamation.svg';
import headset from '../../assets/icons/headset.svg';
import makeChanges from '../../assets/icons/make_changes.svg';
import question from '../../assets/icons/question.svg';
import theme from '../../theme';
import { track } from '../../util/analytics';
import ZendeskWidgetController from '../../util/zendesk-widget-controller';
import ClaimsModal from './claims-modal';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'center',
    pointerEvents: 'none',
    transition: 'opacity 0.3s linear'
  },
  hidden: {
    opacity: 0
  },
  speedDialRoot: {
    alignItems: 'center'
  },
  speedDialIconRoot: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  claimsButton: {
    pointerEvents: 'all',
    paddingLeft: 8,
    marginRight: 16,
    backgroundColor: '#FFF'
  },
  speedDialButton: {
    height: 48,
    opacity: 1,
    width: 48,
    padding: 8,
    marginLeft: 0,
    marginRight: 0,
    backgroundColor: theme.colors.yellow,
    color: '#FFF',
    '&:hover': {
      backgroundColor: theme.colors.darkYellow
    }
  },
  claimsButtonText: {
    fontSize: 16,
    paddingLeft: 8,
    fontWeight: 600,
    textTransform: 'none',
    color: theme.colors.yellow
  },
  icon: {
    height: 32,
    width: 32
  },
  tooltip: {
    backgroundColor: '#FFF',
    color: theme.colors.turtleGreen,
    boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.24)',
    fontSize: 13
  }
};

function FloatingButtons({ classes, className }) {
  const [claimsModalOpen, setClaimsModalOpen] = useState(false);
  const [buttonsOpen, setButtonsOpen] = useState(false);
  const [widgetOpen, setWidgetOpen] = useState(false);

  const toggleButtons = () => {
    setButtonsOpen((isOpen) => !isOpen);
  };

  const openButtons = () => {
    setButtonsOpen(true);
  };

  const closeButtons = () => {
    setButtonsOpen(false);
  };

  useEffect(() => {
    const removeOnOpenListener = ZendeskWidgetController.onOpen(() => {
      setWidgetOpen(true);
      closeButtons();
    });

    const removeOnCloseListener = ZendeskWidgetController.onClose(() => {
      setWidgetOpen(false);
    });

    return () => {
      removeOnOpenListener();
      removeOnCloseListener();
    };
  }, []);

  return (
    <>
      <ClaimsModal open={claimsModalOpen} onClose={() => setClaimsModalOpen(false)} />
      <div className={clsx(className, classes.container, { [classes.hidden]: widgetOpen })}>
        <Fab
          variant="extended"
          className={classes.claimsButton}
          onClick={() => {
            track('Account Support Clicked', { action: 'Claims' });
            setClaimsModalOpen(true);
          }}
        >
          <img src={exclamation} alt="Claims" className={classes.icon} />
          <span className={classes.claimsButtonText}>Claims</span>
        </Fab>
        <SpeedDial
          ariaLabel="Speed Dial"
          icon={
            <SpeedDialIcon
              classes={{ root: classes.speedDialIconRoot }}
              icon={<img src={question} alt="Questions" className={classes.icon} />}
              openIcon={<CloseIcon />}
            />
          }
          onBlur={closeButtons}
          onClick={toggleButtons}
          onClose={closeButtons}
          onFocus={openButtons}
          onMouseEnter={openButtons}
          onMouseLeave={closeButtons}
          open={buttonsOpen}
          classes={{
            root: classes.speedDialRoot,
            fab: classes.speedDialButton
          }}
        >
          <SpeedDialAction
            tooltipTitle="Questions"
            icon={<img src={question} alt="Questions" className={classes.icon} />}
            TooltipClasses={{ tooltip: classes.tooltip }}
            classes={{ fab: classes.speedDialButton }}
            onClick={() => {
              track('Account Support Clicked', { action: 'Questions' });
              window.open('https://team-branch.zendesk.com/hc/en-us/sections/360001382771-FAQ', '_blank');
            }}
          />
          <SpeedDialAction
            tooltipTitle="Make Changes"
            icon={<img src={makeChanges} alt="Make Changes" className={classes.icon} />}
            TooltipClasses={{ tooltip: classes.tooltip }}
            classes={{ fab: classes.speedDialButton }}
            onClick={() => {
              track('Account Support Clicked', { action: 'Make Changes' });
              ZendeskWidgetController.openChat();
            }}
          />
          <SpeedDialAction
            tooltipTitle="Call Support"
            icon={<img src={headset} alt="Call Support" className={classes.icon} />}
            TooltipClasses={{ tooltip: classes.tooltip }}
            classes={{ fab: classes.speedDialButton }}
            FabProps={{ component: 'a', href: 'tel:833-4BRANCH' }}
            onClick={() => {
              track('account-fe Call Support Clicked', Bowser.parse(window.navigator.userAgent));
            }}
          />
        </SpeedDial>
      </div>
    </>
  );
}

FloatingButtons.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

FloatingButtons.defaultProps = {
  className: ''
};

export default withStyles(styles)(FloatingButtons);
