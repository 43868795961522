import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import backIcon from '../assets/icons/back.svg';

const useStyles = makeStyles({
  icon: {
    width: 36,
    height: 36,
    cursor: 'pointer',
    '@media(max-width: 414px)': {
      height: 28,
      width: 28
    }
  }
});

const BackButton = ({ className }) => {
  const styles = useStyles();
  const history = useHistory();

  return (
    <IconButton className={className} onClick={history.goBack}>
      <img src={backIcon} alt="Back" className={styles.icon} />
    </IconButton>
  );
};

BackButton.propTypes = {
  className: PropTypes.string
};

BackButton.defaultProps = {
  className: ''
};

export default BackButton;
