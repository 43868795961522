/* eslint-disable no-extra-boolean-cast */
import CryptoJS from 'crypto-js';
import Auth from '@aws-amplify/auth';

import config from '../aws-exports';
import { PolicyType } from './terminology';

const tokenAttributes = {
  firstName: 'name',
  lastName: 'family_name',
  city: 'custom:city',
  state: 'custom:state',
  zip: 'custom:zip',
  address: 'address',
  email: 'email',
  phone: 'phone_number',
  affinityCode: 'custom:affinity_code'
};

const getPlainParameters = (attr) => {
  return (
    `first_name=${attr[tokenAttributes.firstName]}&` +
    `last_name=${attr[tokenAttributes.lastName]}&` +
    `city=${attr[tokenAttributes.city]}&` +
    `state=${attr[tokenAttributes.state]}&` +
    `zip=${attr[tokenAttributes.zip]}&` +
    `address=${attr[tokenAttributes.address]}&` +
    `email=${attr[tokenAttributes.email]}&` +
    `phone=${attr[tokenAttributes.phone]}`
  );
};

const buildEncryptedQuoteURL = async (policyType) => {
  const path = `${config.quoteDomain}/inbound?data=`;

  const user = await Auth.currentAuthenticatedUser();
  const { username, attributes } = user;
  const ap = attributes[tokenAttributes.affinityCode] ? `&ap=${attributes[tokenAttributes.affinityCode]}` : '';
  const text = `accountId=${username}&${getPlainParameters(attributes)}&noBindAuto=${
    policyType === PolicyType.Home
  }&noBindHome=${policyType === PolicyType.Auto}`;

  const key = CryptoJS.enc.Hex.parse(config.encryptKey);
  const iv = CryptoJS.enc.Hex.parse(config.encryptIV);
  const encrypted = CryptoJS.AES.encrypt(text, key, { iv });
  const plaintext = encrypted.toString();
  const uri = `${path}${encodeURIComponent(plaintext)}&policyType=${policyType}&utm_source=Account${ap}`;

  return uri;
};

export default buildEncryptedQuoteURL;
