import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import theme from '../../theme';

const styles = {
  title: {
    fontSize: 32,
    fontWeight: 100,
    color: theme.colors.turtleGreen,
    marginTop: 16,
    '@media(max-width: 414px)': {
      marginLeft: 16,
      fontSize: 20,
      fontWeight: 'normal'
    }
  }
};

const CardColumnTitle = ({ classes, className, children }) => (
  <div className={clsx(classes.title, className)}>{children}</div>
);

CardColumnTitle.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  children: PropTypes.node
};

CardColumnTitle.defaultProps = {
  className: '',
  children: null
};

export default withStyles(styles)(CardColumnTitle);
