import MUICheckbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import theme from '../theme';

const styles = {
  checkbox: {
    padding: 0
  },
  root: {
    color: theme.colors.carrot,
    '&$checked': {
      color: theme.colors.carrot
    }
  },
  checked: {}
};

function Checkbox({ classes, className, ...props }) {
  return (
    <MUICheckbox
      className={clsx(classes.checkbox, className)}
      classes={{ root: classes.root, checked: classes.checked }}
      {...props}
    />
  );
}

Checkbox.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string
};

Checkbox.defaultProps = {
  className: ''
};

export default withStyles(styles)(Checkbox);
