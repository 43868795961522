import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import Card from '../../../components/card';
import CardContent from '../../../components/card/card-content';
import DeductiblesOtherCoveragesColumn from './deductibles-other-coverages-column';
import DwellingLimitsColumn from './dwelling-limits-column';
import MoreCoveragesColumn from './more-coverages-column';

const styles = {
  card: {
    '@media(max-width: 414px)': {
      width: '100%'
    }
  },
  cardContent: {
    display: 'grid',
    gridGap: '32px',
    gridTemplateColumns: '296px 296px 296px',
    '@media(max-width: 1083px)': {
      gridTemplateColumns: '296px 296px'
    },
    '@media(max-width: 756px)': {
      gridTemplateColumns: '296px'
    },
    '@media(max-width: 414px)': {
      gridTemplateColumns: '1fr'
    }
  }
};

const HomeCoverageCard = ({ classes, className, homePolicyData }) => (
  <Card cardColor="dark" className={clsx(classes.card, className)}>
    <CardContent className={classes.cardContent}>
      <DwellingLimitsColumn homePolicyData={homePolicyData} />
      <DeductiblesOtherCoveragesColumn homePolicyData={homePolicyData} />
      <MoreCoveragesColumn homePolicyData={homePolicyData} />
    </CardContent>
  </Card>
);

HomeCoverageCard.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  homePolicyData: PropTypes.object.isRequired
};

HomeCoverageCard.defaultProps = {
  className: ''
};

export default withStyles(styles)(HomeCoverageCard);
