import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../components/button';

const styles = {
  button: {
    minHeight: 0
  }
};

const GoToHomeDetailsButton = ({ classes, className, policyId }) => (
  <div className={className}>
    <Link to={`/app/home/${policyId}`}>
      <Button className={classes.button}>View Home Information</Button>
    </Link>
  </div>
);

GoToHomeDetailsButton.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  policyId: PropTypes.string.isRequired
};

GoToHomeDetailsButton.defaultProps = {
  className: ''
};

export default withStyles(styles)(GoToHomeDetailsButton);
