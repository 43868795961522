import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import ReactMasonryComponent from 'react-masonry-component';
import ResizeDetector from 'react-resize-detector';

const styles = {
  masonry: {
    margin: '0 auto',
    '@media(max-width: 414px)': {
      // The masonry component sets its own width after render,
      // and this is the only way to override it.
      width: '100% !important'
    },
    '& > div': {
      width: 327,
      '@media(max-width: 414px)': {
        width: '100%'
      }
    }
  }
};

const masonryOptions = {
  gutter: 16,
  transitionDuration: '0',
  horizontalOrder: true
};

class Masonry extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    children: PropTypes.node,
    centered: PropTypes.bool
  };

  static defaultProps = {
    className: '',
    children: null,
    centered: true
  };

  onResize = () => {
    if (this.masonry) {
      this.masonry.layout();
    }
  };

  render() {
    const { className, classes, children, centered } = this.props;

    return (
      <>
        <ReactMasonryComponent
          options={{ ...masonryOptions, fitWidth: centered }}
          className={clsx(classes.masonry, className)}
          ref={(node) => {
            this.masonry = this.masonry || node.masonry;
          }}
        >
          {React.Children.map(children, (child) => (
            <div>
              {child}
              <ResizeDetector handleHeight onResize={this.onResize} />
            </div>
          ))}
        </ReactMasonryComponent>
      </>
    );
  }
}

export default withStyles(styles)(Masonry);
