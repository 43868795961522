import MuiButton from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import theme from '../theme';
import CircularProgress from './circular-progress';

const styles = {
  common: {
    fontSize: 16,
    fontWeight: 600,
    textTransform: 'none'
  },
  primaryButton: {
    composes: '$common',
    backgroundColor: theme.colors.hunterGreen,
    color: '#FFF',
    '&:hover': {
      backgroundColor: theme.colors.hunterGreen
    }
  },
  regularButton: {
    composes: '$common',
    color: theme.colors.carrot,
    '&:hover': {
      backgroundColor: 'unset'
    },
    padding: 0
  },
  invisible: {
    opacity: 0
  },
  loadingIndicatorContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
};

const Button = ({ classes, className, primary, loading, children, ...props }) => {
  if (primary) {
    return (
      <MuiButton variant="contained" className={clsx(classes.primaryButton, className)} {...props}>
        <div className={clsx({ [classes.invisible]: loading })}>{children}</div>
        {loading && (
          <div className={classes.loadingIndicatorContainer}>
            <CircularProgress size={22} light />
          </div>
        )}
      </MuiButton>
    );
  }

  return (
    <MuiButton disableRipple className={clsx(classes.regularButton, className)} {...props}>
      {children}
    </MuiButton>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  primary: PropTypes.bool,
  loading: PropTypes.bool,
  children: PropTypes.node,
  disabled: PropTypes.bool
};

Button.defaultProps = {
  className: '',
  primary: false,
  children: null,
  loading: false,
  disabled: false
};

export default withStyles(styles)(Button);
