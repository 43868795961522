import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import Card from '../../../components/card';
import CardColumn from '../../../components/card-column';
import CardColumnHeader from '../../../components/card-column/card-column-header';
import CardColumnSubtitle from '../../../components/card-column/card-column-subtitle';
import CardButton from '../../../components/card/card-button';
import CardContent from '../../../components/card/card-content';
import CardFieldGroup from '../../../components/card/card-field-group';
import CardLabel from '../../../components/card/card-label';
import CardText from '../../../components/card/card-text';
import CardTitle from '../../../components/card/card-title';
import { TOOLTIPS } from '../../../util/constants';
import { formatDollars } from '../../../util/strings';

const CoverageColumn = ({ className, homePolicyData }) => {
  const { coverageA, coverageX } = homePolicyData.policyDetails.homeCoverage;
  const { includeUmbrella, umbrellaCoverage } = homePolicyData.policyDetails;

  return (
    <CardColumn className={className}>
      <CardColumnHeader>
        <CardColumnSubtitle>Coverage</CardColumnSubtitle>
      </CardColumnHeader>
      <Card cardColor="dark">
        <CardContent>
          <CardFieldGroup>
            <CardLabel tooltip={TOOLTIPS.HOME_DWELLING_COVERAGE}>Dwelling Coverage Limit</CardLabel>
            <CardText>{formatDollars(coverageA)}</CardText>
          </CardFieldGroup>
          <CardFieldGroup>
            <CardLabel tooltip={TOOLTIPS.HOME_FAMILY_LIABILITY_LIMIT}>Family Liability Limit</CardLabel>
            <CardText>{formatDollars(coverageX)}</CardText>
          </CardFieldGroup>
          <Link to={`/app/home/${homePolicyData.id}/coverage`}>
            <CardButton>See More</CardButton>
          </Link>
        </CardContent>
      </Card>
      {includeUmbrella && (
        <Card cardColor="dark">
          <CardContent>
            <CardTitle>Umbrella</CardTitle>
            <CardFieldGroup>
              <CardLabel tooltip={TOOLTIPS.HOME_UMBRELLA_COVERAGE_LIMIT}>Umbrella Coverage Limit</CardLabel>
              <CardText>{formatDollars(umbrellaCoverage.liabilityCoverageLimit)}</CardText>
            </CardFieldGroup>
            <Link to={`/app/home/${homePolicyData.id}/coverage`}>
              <CardButton>See More</CardButton>
            </Link>
          </CardContent>
        </Card>
      )}
    </CardColumn>
  );
};

CoverageColumn.propTypes = {
  className: PropTypes.string,
  homePolicyData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    policyDetails: PropTypes.shape({
      homeCoverage: PropTypes.shape({
        coverageA: PropTypes.number.isRequired,
        coverageX: PropTypes.number.isRequired
      }).isRequired,
      includeUmbrella: PropTypes.bool.isRequired,
      umbrellaCoverage: PropTypes.shape({
        liabilityCoverageLimit: PropTypes.number.isRequired
      })
    }).isRequired
  }).isRequired
};

CoverageColumn.defaultProps = {
  className: ''
};

export default CoverageColumn;
