import { withStyles } from '@material-ui/core/styles';
import * as Sentry from '@sentry/browser';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Mutation } from 'react-apollo';
import awsExports from '../../../aws-exports';
import { logError } from '../../../util/devmode-logger';
import Button from '../../button';

const MODIFY_PAYMENT_METHOD_ACH_MUTATION = gql`
  mutation ModifyPaymentMethodACH($policyId: String, $plaidPublicToken: String, $plaidMetadataJSON: String) {
    modifyPaymentMethodACH(
      policyId: $policyId
      plaidPublicToken: $plaidPublicToken
      plaidMetadataJSON: $plaidMetadataJSON
    )
  }
`;

const styles = {
  container: {
    marginTop: 12
  },
  submitButton: {
    // marginTop: 18
  }
};

function ModifyBankAccountForm({ policyId, classes, onCompleted }) {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (modifyPaymentMethodACHMutation) => {
    setLoading(true);

    window.Plaid.create({
      clientName: 'Branch',
      env: awsExports.plaid.env,
      key: awsExports.plaid.publicKey,
      product: ['auth'],
      onSuccess: async (plaidPublicToken, plaidMetadata) => {
        setLoading(true);
        const plaidMetadataJSON = JSON.stringify(plaidMetadata);
        try {
          await modifyPaymentMethodACHMutation({
            variables: { policyId, plaidPublicToken, plaidMetadataJSON }
          });
        } catch (e) {
          logError(e);
          Sentry.captureException(e);
        } finally {
          setLoading(false);
          onCompleted();
        }
      },
      onExit: () => setLoading(false)
    }).open();
  };

  return (
    <Mutation mutation={MODIFY_PAYMENT_METHOD_ACH_MUTATION}>
      {(modifyPaymentMethodACHMutation) => (
        <div className={classes.container}>
          <Button
            onClick={() => handleSubmit(modifyPaymentMethodACHMutation)}
            primary
            loading={loading}
            className={classes.submitButton}
          >
            Link New Bank Account
          </Button>
        </div>
      )}
    </Mutation>
  );
}

ModifyBankAccountForm.propTypes = {
  policyId: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  onCompleted: PropTypes.func.isRequired
};

export default withStyles(styles)(ModifyBankAccountForm);
