import PropTypes from 'prop-types';
import React from 'react';
import CardColumn from './card-column';
import CardColumnHeader from './card-column/card-column-header';
import CardColumnSubtitle from './card-column/card-column-subtitle';
import DocumentsCard from './documents-card';

const DocumentsColumn = ({ policyId, className }) => (
  <CardColumn className={className}>
    <CardColumnHeader>
      <CardColumnSubtitle>Documents</CardColumnSubtitle>
    </CardColumnHeader>
    <DocumentsCard policyId={policyId} />
  </CardColumn>
);

DocumentsColumn.propTypes = {
  className: PropTypes.string,
  policyId: PropTypes.string
};

DocumentsColumn.defaultProps = {
  className: '',
  policyId: ''
};

export default DocumentsColumn;
