import { CssBaseline, makeStyles } from '@material-ui/core';
import React from 'react';
import theme from './theme';

const useStyles = makeStyles({
  '@global': {
    '*': {
      fontFamily: theme.fonts.LibreFranklin
    },
    body: {
      backgroundColor: theme.colors.beige
    },
    '*:focus': {
      outline: 'none'
    },
    a: {
      textDecoration: 'none',
      display: 'contents' // https://drafts.csswg.org/css-display/#valdef-display-contents
    }
  }
});

export default function GlobalStyles() {
  useStyles();

  return <CssBaseline />;
}
