import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import * as Sentry from '@sentry/browser';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useMutation } from 'react-apollo';
import Button from '../../../../../components/button';
import Checkbox from '../../../../../components/checkbox';
import Modal from '../../../../../components/modal';
import ModalBody from '../../../../../components/modal/modal-body';
import ModalTitle from '../../../../../components/modal/modal-title';
import TextField from '../../../../../components/text-field';
import theme from '../../../../../theme';

const useStyles = makeStyles({
  dialog: {
    maxWidth: 670
  },
  dialogCaption: {
    fontSize: 16,
    color: theme.colors.hunterGreen,
    fontWeight: 300
  },
  checkboxAndLabelContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 16
  },
  checkboxLabel: {
    marginLeft: 12,
    fontSize: 14,
    color: theme.colors.hunterGreen
  },
  checkboxAndAuthorizationTextContainer: {
    display: 'flex',
    marginTop: 32,
    alignItems: 'flex-start'
  },
  authorizationText: {
    marginLeft: 12,
    fontSize: 12,
    color: theme.colors.pesto
  },
  authorizationTextLink: {
    color: theme.colors.carrot
  },
  actionButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 32
  }
});

const CREATE_STRESS_FREE_SWITCH_REQUEST_MUTATION = gql`
  mutation CreateStressFreeSwitchRequest($input: StressFreeSwitchRequestInput!) {
    createStressFreeSwitchRequest(input: $input)
  }
`;

const StressFreeSwitchDialog = ({
  open,
  onClose,
  onComplete,
  initialHomeInsuranceProvider,
  initialAutoInsuranceProvider
}) => {
  const styles = useStyles();

  const [priorHomeInsuranceProviderName, setPriorHomeInsuranceProviderName] = useState(initialHomeInsuranceProvider);
  const [cancelHomeInsurance, setCancelHomeInsurance] = useState(false);
  const [priorAutoInsuranceProviderName, setPriorAutoInsuranceProviderName] = useState(initialAutoInsuranceProvider);
  const [cancelAutoInsurance, setCancelAutoInsurance] = useState(false);
  const [customerAuthorization, setCustomerAuthorization] = useState(false);

  const submitButtonEnabled = useMemo(() => {
    return (
      customerAuthorization &&
      ((priorHomeInsuranceProviderName && cancelHomeInsurance) ||
        (priorAutoInsuranceProviderName && cancelAutoInsurance))
    );
  }, [
    cancelAutoInsurance,
    cancelHomeInsurance,
    customerAuthorization,
    priorAutoInsuranceProviderName,
    priorHomeInsuranceProviderName
  ]);

  const [createStressFreeSwitchRequest, { loading, error }] = useMutation(CREATE_STRESS_FREE_SWITCH_REQUEST_MUTATION);

  const onSubmit = async () => {
    const homeInsuranceProviderToCancel = cancelHomeInsurance ? priorHomeInsuranceProviderName : null;
    const autoInsuranceProviderToCancel = cancelAutoInsurance ? priorAutoInsuranceProviderName : null;

    await createStressFreeSwitchRequest({
      variables: {
        input: {
          priorHomeInsuranceProvider: homeInsuranceProviderToCancel || null,
          priorAutoInsuranceProvider: autoInsuranceProviderToCancel || null
        }
      }
    });

    if (error) {
      Sentry.captureException(error);
      onComplete({ success: false, message: 'Something went wrong. Please call us at 833-4BRANCH for help.' });
      return;
    }

    onComplete({ success: true, message: "Thanks! We'll take care of cancelling your previous insurance for you!" });
  };

  return (
    <Modal open={open} onClose={onClose} className={styles.dialog}>
      <ModalTitle responsive>
        <div className={styles.dialogCaption}>Introducing Stress-Free Switch</div>
        Branch can cancel your prior insurance policy for you
      </ModalTitle>
      <ModalBody>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="text"
              label="Home Insurance Provider"
              placeholder="Prior Home Insurance Provider"
              value={priorHomeInsuranceProviderName}
              onChange={(e) => setPriorHomeInsuranceProviderName(e.target.value)}
              className={styles.textField}
            />
            <div className={styles.checkboxAndLabelContainer}>
              <Checkbox checked={cancelHomeInsurance} onChange={() => setCancelHomeInsurance((value) => !value)} />
              <span className={styles.checkboxLabel}>Cancel my prior home insurance</span>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="text"
              label="Auto Insurance Provider"
              placeholder="Prior Auto Insurance Provider"
              value={priorAutoInsuranceProviderName}
              onChange={(e) => setPriorAutoInsuranceProviderName(e.target.value)}
              className={styles.textField}
            />
            <div className={styles.checkboxAndLabelContainer}>
              <Checkbox value={cancelAutoInsurance} onChange={() => setCancelAutoInsurance((value) => !value)} />
              <span className={styles.checkboxLabel}>Cancel my prior auto insurance</span>
            </div>
          </Grid>
        </Grid>
        <div className={styles.checkboxAndAuthorizationTextContainer}>
          <Checkbox value={customerAuthorization} onChange={() => setCustomerAuthorization((value) => !value)} />
          <div className={styles.authorizationText}>
            By clicking this checkbox, I authorize Branch to contact the above insurer(s) on my behalf to request
            cancellation of my current policy or policies, and acknowledge that this process will require that Branch
            obtain and use my electronic signature. I acknowledge that my electronic signature shall have the same force
            and effect of a handwritten signature for purposes of validity, enforcement, and admissibility. I also
            acknowledge that Branch cannot guarantee that the other insurer(s) will process the cancellation request and
            that I should confirm cancellation directly with the insurer(s) as needed.{' '}
            <a
              href="https://www.ourbranch.com/s/blog/a-truly-good-reason-to-switch-your-insurance"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.authorizationTextLink}
            >
              Learn more about how we cancel your old policy.
            </a>
          </div>
        </div>
        <div className={styles.actionButtonContainer}>
          <Button primary disabled={!submitButtonEnabled || loading} loading={loading} onClick={onSubmit}>
            Start Cancellation
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

StressFreeSwitchDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onComplete: PropTypes.func.isRequired,
  initialHomeInsuranceProvider: PropTypes.string,
  initialAutoInsuranceProvider: PropTypes.string
};

StressFreeSwitchDialog.defaultProps = {
  open: false,
  onClose: () => null,
  initialHomeInsuranceProvider: '',
  initialAutoInsuranceProvider: ''
};

export default StressFreeSwitchDialog;
