import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import theme from '../../../theme';

const styles = {
  subtitle: {
    marginTop: 24,
    fontSize: 16,
    fontWeight: 600,
    color: theme.colors.beige
  }
};

const CoverageCardSubtitle = ({ className, classes, children }) => (
  <div className={clsx(classes.subtitle, className)}>{children}</div>
);

CoverageCardSubtitle.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  children: PropTypes.node
};

CoverageCardSubtitle.defaultProps = {
  className: '',
  children: null
};

export default withStyles(styles)(CoverageCardSubtitle);
