import AmplifyAuth from '@aws-amplify/auth';
import Amplify from '@aws-amplify/core';
import * as Sentry from '@sentry/browser';
import ConditionalWrap from 'conditional-wrap';
import PropTypes from 'prop-types';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import App from './app';
import Auth from './auth';
import awsExports from './aws-exports';
import JssConfigProvider from './components/jss-config-provider';
import ProtectedRoute from './components/protected-route';
import configureServices from './configure-services';
import GlobalStyles from './global-styles';
import { track } from './util/analytics';
import { logError } from './util/devmode-logger';
import RouteChangeListener from './util/route-change-listener';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://f87a75b2d40b4720b1b858ef1acc72e7@sentry.io/1428075',
    environment: window.location.hostname
  });
}

Amplify.configure(awsExports);
configureServices();

class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired
  };

  componentDidCatch(error, info) {
    Sentry.withScope((scope) => {
      Object.keys(info).forEach((key) => {
        scope.setExtra(key, info[key]);
      });
      logError(error);
      Sentry.captureException(error);
      AmplifyAuth.signOut();
    });
  }

  render() {
    return <>{this.props.children}</>;
  }
}

const Root = () => (
  <StrictMode>
    <ConditionalWrap
      condition={process.env.NODE_ENV === 'production'}
      wrap={(children) => <ErrorBoundary>{children}</ErrorBoundary>}
    >
      <JssConfigProvider>
        <GlobalStyles />
        <Router>
          <RouteChangeListener
            onChange={(location) => {
              track('Account Viewed Page', { path: location.pathname, query: location.query, hash: location.hash });
            }}
          />
          <Switch>
            <Route path="/auth" component={Auth} />
            <ProtectedRoute path="/app" component={App} />
            <Route>
              <Redirect to="/auth" />
            </Route>
          </Switch>
        </Router>
      </JssConfigProvider>
    </ConditionalWrap>
  </StrictMode>
);

ReactDOM.render(<Root />, document.getElementById('root'));
