import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import Lottie from 'react-lottie';
import branchSpinnerJson from '../assets/lottie-animations/branch-spinner.min.json';

const useStyles = makeStyles({
  animation: {
    width: '100%',
    height: '100%'
  }
});

function BranchSpinner({ className }) {
  const styles = useStyles();

  return (
    <div className={clsx(styles.animation, className)}>
      <Lottie options={{ animationData: branchSpinnerJson, loop: true, autoplay: true }} />
    </div>
  );
}

BranchSpinner.propTypes = {
  className: PropTypes.string
};

BranchSpinner.defaultProps = {
  className: ''
};

export default BranchSpinner;
