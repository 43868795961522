import React from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import theme from '../../../theme';

const useStyles = makeStyles({
  copyTextParagraph: {
    fontFamily: theme.fonts.LibreFranklin,
    fontSize: 16,
    color: theme.colors.turtleGreen,
    marginTop: 24
  },
  copyUnorderedList: {
    '& li': {
      position: 'relative',
      marginTop: 16
    }
  }
});

function NoticeCopyTextPriceIncrease({ policyBeingRenewedPolicyId, renewalDateString, newPriceString }) {
  const styles = useStyles();

  return (
    <>
      <p className={styles.copyTextParagraph}>
        {"We're"} deeply grateful for your business and look forward to the renewal of your policy{' '}
        {policyBeingRenewedPolicyId} slated for {renewalDateString}.
      </p>
      <p className={styles.copyTextParagraph}>
        At Branch, {"we're"} constantly looking for ways to improve how we operate to make insurance better and more
        affordable in the long run.
      </p>
      <p className={styles.copyTextParagraph}>
        {"That's"} why we recently launched the Branch Insurance Exchange - a reciprocal insurance exchange built to
        give our customers more control over their policies. Through Branch Insurance Exchange, customers will enjoy a
        number of exciting features:
      </p>
      <p className={styles.copyTextParagraph}>
        <ul className={styles.copyUnorderedList}>
          <li>
            Representation from a customer advisory forum, made up of current Branch customers who regularly interact
            with Branch management to <b>help make decisions</b> regarding company operations and direction.
          </li>
          <li>
            Access to <b>new savings programs</b> like Community Drive and connected home discounts.
          </li>
          <li>
            Coming soon! A <b>subscriber savings account</b>, which allows us to periodically return money directly back
            to members like you.
          </li>
        </ul>
      </p>
      <p className={styles.copyTextParagraph}>
        Branch Insurance Exchange is a major milestone for Branch and our way of putting the power of community to work
        in order to make insurance better and more affordable. Your renewal price with Branch Insurance Exchange will be{' '}
        {newPriceString}.
      </p>
      <p className={styles.copyTextParagraph}>Would you like to learn more about the Branch Insurance Exchange?</p>
    </>
  );
}

NoticeCopyTextPriceIncrease.propTypes = {
  policyBeingRenewedPolicyId: PropTypes.string.isRequired,
  renewalDateString: PropTypes.string.isRequired,
  newPriceString: PropTypes.string.isRequired
};

function NoticeCopyTextPriceDecrease({
  policyBeingRenewedPolicyId,
  renewalDateString,
  oldPriceString,
  newPriceString
}) {
  const styles = useStyles();

  return (
    <>
      <p className={styles.copyTextParagraph}>
        Your insurance policy {policyBeingRenewedPolicyId} is set to renew on {renewalDateString} and your price will
        reduce from {oldPriceString} to {newPriceString}.
      </p>
      <p className={styles.copyTextParagraph}>
        Sound too good to be true? <b>{"It's"} not.</b>
      </p>
      <p className={styles.copyTextParagraph}>
        At Branch, we obsessively look for ways to drive the price of insurance down for our members. {"That's"} why we
        recently launched the Branch Insurance Exchange. {"It's"} our way of putting the power of community to work in
        order to make insurance better and more affordable.
      </p>
      <p className={styles.copyTextParagraph}>Would you like to learn more about the Branch Insurance Exchange?</p>
    </>
  );
}

NoticeCopyTextPriceDecrease.propTypes = {
  policyBeingRenewedPolicyId: PropTypes.string.isRequired,
  renewalDateString: PropTypes.string.isRequired,
  oldPriceString: PropTypes.string.isRequired,
  newPriceString: PropTypes.string.isRequired
};

export { NoticeCopyTextPriceIncrease, NoticeCopyTextPriceDecrease };
