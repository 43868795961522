import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';

import crossIcon from './cross.svg';
import editIcon from './edit.svg';
import deleteIcon from './delete.svg';
import useStyles from './action-button.styles';

const icons = {
  edit: editIcon,
  delete: deleteIcon,
  close: crossIcon
};

const ActionButton = ({ onClick, type, icon, size, alt, cyIdentifier, className, ...props }) => {
  const classes = useStyles();
  return (
    <IconButton
      data-cy={cyIdentifier}
      size="small"
      className={clsx(classes.button, className)}
      onClick={onClick}
      {...props}
    >
      <img src={icon || icons[type]} className={classes[`icon-${size}`]} alt={alt} />
    </IconButton>
  );
};

ActionButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['delete', 'close', 'edit']),
  size: PropTypes.oneOf(['small', 'medium', 'big']),
  alt: PropTypes.string,
  icon: PropTypes.string,
  cyIdentifier: PropTypes.string,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

ActionButton.defaultProps = {
  alt: '',
  className: null,
  cyIdentifier: null,
  icon: null,
  size: 'medium',
  type: 'edit'
};

export default ActionButton;
