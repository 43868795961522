// See https://github.com/gobranch/branch/issues/2624 for what this is / how it works
import Auth from '@aws-amplify/auth';
import * as Sentry from '@sentry/browser';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { logError } from '../../../util/devmode-logger';
import { analyticsIdentifyUser, newPasswordRequired } from '../../helpers';
import UserBeingAuthenticatedContainer from '../../user-being-authenticated-container';

export default function TemporaryPasswordEmailAuthenticator() {
  const [loggedInState, setLoggedInState] = useState(false);
  const [newPasswordRequiredState, setNewPasswordRequiredState] = useState(false);
  const [fallbackState, setFallbackState] = useState(false);

  // UserBeingAuthenticatedContainer has a user property that changes when it's setUser property is called
  // so using the container directly as a useEffect dependency causes an un-necessary re-run of the effect.
  // The setUser property maintains its identity so it's okay to use inside the effect / as an effect dependency
  // which is why we pull it out like this first.
  const { setUser: userBeingAuthenticatedContainerSetUser } = UserBeingAuthenticatedContainer.useContainer();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    async function effect() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        if (user.username === 'anonymous') {
          await Auth.signOut();
        } else {
          return setLoggedInState(true);
        }
      } catch {
        /* no-op */
      }

      const { username, password } = queryString.parse(location.search);
      if (!username || !password) {
        return setFallbackState(true);
      }

      try {
        const user = await Auth.signIn(username, password);
        userBeingAuthenticatedContainerSetUser(user);

        if (newPasswordRequired(user)) {
          return setNewPasswordRequiredState(true);
        }

        analyticsIdentifyUser(user);
        return setLoggedInState(true);
      } catch (e) {
        Sentry.withScope((scope) => {
          logError(e);
          scope.setExtra('Error', e);
          Sentry.captureException(new Error('Triggered temporary password email auto login fallback'));
        });
        setFallbackState(true);
      }
    }

    effect();
  }, [history, location, userBeingAuthenticatedContainerSetUser]);

  if (fallbackState) {
    return <Redirect to="/auth/signin" />;
  }

  if (loggedInState) {
    return <Redirect to="/app" />;
  }

  if (newPasswordRequiredState) {
    return <Redirect to="/auth/newpassword" />;
  }

  return null;
}
