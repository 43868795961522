import { stateToTzMap } from '@ourbranch/state-to-tz-map';
import { differenceInDays, isFuture, sub } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-apollo';
import { useLocalStorage } from 'react-use';
import CardColumn from '../../../../components/card-column';
import CardColumnHeader from '../../../../components/card-column/card-column-header';
import CardColumnTitle from '../../../../components/card-column/card-column-title';
import Snackbar from '../../../../components/snackbar';
import GetAQuoteCard from './get-a-quote-card';
import InventoryCard from './inventory-card';
import StressFreeSwitchCard from './stress-free-switch-card';

const GET_MY_POLICIES_QUERY = gql`
  query GetMyPoliciesQuery {
    getMyPolicies {
      state
      policyType
      effectiveDate
      policyDetails {
        global {
          breakupWithHomeowners
          breakupWithAuto
          currentHomeownersCarrier
          currentAutoCarrier
          discountInventoryScore
          discountInventoryScoreAddedDate
        }
      }
    }
  }
`;

const GetAQuoteColumn = ({ className }) => {
  const [localStorageHideStressFreeSwitch, setLocalStorageHideStressFreeSwitch] = useLocalStorage(
    'HideStressFreeSwitch',
    false
  );

  const [stressFreeSwitchResultSnackbarOpen, setStressFreeSwitchResultSnackbarOpen] = useState(false);
  const [stressFreeSwitchResultMessage, setStressFreeSwitchResultMessage] = useState('');

  const onStressFreeSwitchComplete = ({ success, message }) => {
    setStressFreeSwitchResultMessage(message);
    setStressFreeSwitchResultSnackbarOpen(true);
    setLocalStorageHideStressFreeSwitch(success);
  };

  const onStressFreeSwitchResultSnackbarClose = () => {
    setStressFreeSwitchResultSnackbarOpen(false);
  };

  const getMyPoliciesQuery = useQuery(GET_MY_POLICIES_QUERY);

  const policiesValidForStressFreeSwitch = useMemo(() => {
    if (getMyPoliciesQuery.loading) {
      return false;
    }

    if (getMyPoliciesQuery.error) {
      return false;
    }

    const policies = getMyPoliciesQuery.data.getMyPolicies;

    if (
      policies.some(
        ({ policyDetails }) => policyDetails.global.breakupWithHomeowners || policyDetails.global.breakupWithAuto
      )
    ) {
      return false;
    }

    const maxEffectiveDate = Math.max(
      ...policies.map((policy) => zonedTimeToUtc(policy.effectiveDate, stateToTzMap[policy.state]))
    );
    if (maxEffectiveDate < sub(new Date(), { days: 30 })) {
      return false;
    }

    return true;
  }, [getMyPoliciesQuery.data, getMyPoliciesQuery.error, getMyPoliciesQuery.loading]);

  const [priorHomeInsuranceProvider, priorAutoInsuranceProvider] = useMemo(() => {
    let homeProvider = '';
    let autoProvider = '';

    if (getMyPoliciesQuery.loading) {
      return [homeProvider, autoProvider];
    }

    if (getMyPoliciesQuery.error) {
      return [homeProvider, autoProvider];
    }

    const policies = getMyPoliciesQuery.data.getMyPolicies;

    for (const policy of policies) {
      const { currentHomeownersCarrier, currentAutoCarrier } = policy.policyDetails.global;
      if (currentHomeownersCarrier) {
        homeProvider = currentHomeownersCarrier;
      }

      if (currentAutoCarrier) {
        autoProvider = currentAutoCarrier;
      }
    }

    return [homeProvider, autoProvider];
  }, [getMyPoliciesQuery.data, getMyPoliciesQuery.error, getMyPoliciesQuery.loading]);

  const showStressFreeSwitch = useMemo(
    () => policiesValidForStressFreeSwitch && !localStorageHideStressFreeSwitch,
    [localStorageHideStressFreeSwitch, policiesValidForStressFreeSwitch]
  );

  const showInventoryCard = useMemo(() => {
    if (getMyPoliciesQuery.loading) {
      return false;
    }

    if (getMyPoliciesQuery.error) {
      return false;
    }

    const policies = getMyPoliciesQuery.data.getMyPolicies;
    return policies.some((policy) => {
      if (policy.policyType !== 'H') {
        return false;
      }

      if (!policy.policyDetails.global.discountInventoryScore) {
        return false;
      }

      const today = new Date();
      // older policies will not have a discountInventoryScoreAddedDate; default to policy.effectiveDate in those cases
      const dateAdded = new Date(policy.policyDetails.global.discountInventoryScoreAddedDate || policy.effectiveDate);

      return isFuture(dateAdded) || differenceInDays(today, dateAdded) < 14;
    });
  }, [getMyPoliciesQuery.data, getMyPoliciesQuery.error, getMyPoliciesQuery.loading]);

  return (
    <>
      <CardColumn className={className}>
        <CardColumnHeader>
          <CardColumnTitle>More</CardColumnTitle>
        </CardColumnHeader>
        <GetAQuoteCard />
        {showStressFreeSwitch && (
          <StressFreeSwitchCard
            priorHomeInsuranceProvider={priorHomeInsuranceProvider}
            priorAutoInsuranceProvider={priorAutoInsuranceProvider}
            onComplete={onStressFreeSwitchComplete}
          />
        )}
        {showInventoryCard && <InventoryCard />}
      </CardColumn>
      <Snackbar
        onClose={onStressFreeSwitchResultSnackbarClose}
        message={stressFreeSwitchResultMessage}
        open={stressFreeSwitchResultSnackbarOpen}
      />
    </>
  );
};

GetAQuoteColumn.propTypes = {
  className: PropTypes.string
};

GetAQuoteColumn.defaultProps = {
  className: ''
};

export default GetAQuoteColumn;
