import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import CardLabel from '../../../components/card/card-label';
import CardText from '../../../components/card/card-text';
import NoMarginCardFieldGroup from '../../../components/card/no-margin-card-field-group';
import TwoColumnGrid from '../../../components/two-column-grid';
import { formatDollars } from '../../../util/strings';

const styles = {
  twoColumnGrid: {
    marginTop: 16
  }
};

const MoreCoveragesColumn = ({ classes, className, homePolicyData }) => {
  const { homeCoverage } = homePolicyData.policyDetails;

  return (
    <div className={className}>
      <CardText>More Coverages</CardText>
      <TwoColumnGrid className={classes.twoColumnGrid}>
        <NoMarginCardFieldGroup>
          <CardLabel>Musical Instruments</CardLabel>
          <CardText>{homeCoverage.coverageMI ? formatDollars(homeCoverage.coverageMI) : 'Not Purchased'}</CardText>
        </NoMarginCardFieldGroup>
        <NoMarginCardFieldGroup>
          <CardLabel>Silverware Theft</CardLabel>
          <CardText>{homeCoverage.coverageST ? formatDollars(homeCoverage.coverageST) : 'Not Purchased'}</CardText>
        </NoMarginCardFieldGroup>
        <NoMarginCardFieldGroup>
          <CardLabel>Sports Equipment</CardLabel>
          <CardText>{homeCoverage.coverageSP ? formatDollars(homeCoverage.coverageSP) : 'Not Purchased'}</CardText>
        </NoMarginCardFieldGroup>
        <NoMarginCardFieldGroup>
          <CardLabel>Fair Rental Income</CardLabel>
          <CardText>
            {homeCoverage.coverageFairRental ? formatDollars(homeCoverage.coverageFairRental) : 'Not Purchased'}
          </CardText>
        </NoMarginCardFieldGroup>
        <NoMarginCardFieldGroup>
          <CardLabel>Host Protection</CardLabel>
          <CardText>{homeCoverage.homeownerProtection ? 'Purchased' : 'Not Purchased'}</CardText>
        </NoMarginCardFieldGroup>
        <NoMarginCardFieldGroup>
          <CardLabel>Electronic Data Recovery</CardLabel>
          <CardText>{homeCoverage.coverageDR ? 'Purchased' : 'Not Purchased'}</CardText>
        </NoMarginCardFieldGroup>
        <NoMarginCardFieldGroup>
          <CardLabel>Building Materials</CardLabel>
          <CardText>{homeCoverage.coverageBuildingMaterialsTheft ? 'Purchased' : 'Not Purchased'}</CardText>
        </NoMarginCardFieldGroup>
        <NoMarginCardFieldGroup>
          <CardLabel>Mine Subsidence</CardLabel>
          <CardText>{homeCoverage.coverageMS ? 'Purchased' : 'Not Purchased'}</CardText>
        </NoMarginCardFieldGroup>
        <NoMarginCardFieldGroup>
          <CardLabel>Yard and Garden</CardLabel>
          <CardText>{homeCoverage.coverageYardAndGarden ? 'Purchased' : 'Not Purchased'}</CardText>
        </NoMarginCardFieldGroup>
        <NoMarginCardFieldGroup>
          <CardLabel>Day Care</CardLabel>
          <CardText>{homeCoverage.coverageDC ? 'Purchased' : 'Not Purchased'}</CardText>
        </NoMarginCardFieldGroup>
      </TwoColumnGrid>
    </div>
  );
};

MoreCoveragesColumn.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  homePolicyData: PropTypes.object.isRequired
};

MoreCoveragesColumn.defaultProps = {
  className: ''
};

export default withStyles(styles)(MoreCoveragesColumn);
