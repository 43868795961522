/* eslint-disable */
import awsConfig from './aws-exports';
import Cookies from 'js-cookie';

function initializeSegment() {
  !(function () {
    var analytics = (window.analytics = window.analytics || []);
    if (!analytics.initialize)
      if (analytics.invoked) window.console && console.error && console.error('Segment snippet included twice.');
      else {
        analytics.invoked = !0;
        analytics.methods = [
          'trackSubmit',
          'trackClick',
          'trackLink',
          'trackForm',
          'pageview',
          'identify',
          'reset',
          'group',
          'track',
          'ready',
          'alias',
          'debug',
          'page',
          'once',
          'off',
          'on'
        ];
        analytics.factory = function (t) {
          return function () {
            var e = Array.prototype.slice.call(arguments);
            e.unshift(t);
            analytics.push(e);
            return analytics;
          };
        };
        for (var t = 0; t < analytics.methods.length; t++) {
          var e = analytics.methods[t];
          analytics[e] = analytics.factory(e);
        }
        analytics.load = function (t, e) {
          var n = document.createElement('script');
          n.type = 'text/javascript';
          n.async = !0;
          n.src = 'https://cdn.segment.com/analytics.js/v1/' + t + '/analytics.min.js';
          var a = document.getElementsByTagName('script')[0];
          a.parentNode.insertBefore(n, a);
          analytics._loadOptions = e;
        };
        analytics.SNIPPET_VERSION = '4.1.0';
        analytics.load(awsConfig.segmentKey, {
          integrations: {
            All: true
          }
        });
        analytics.page();
        const sessionId = Cookies.get('ampSessionId') || Date.now();
        Cookies.set('ampSessionId', sessionId, { expires: 0.5 / 24 }); // session expires after 30 mins
      }
  })();
}

function initializeProdPerfect() {
  !(function (name, path, ctx) {
    var latest,
      prev = name !== 'Keen' && window.Keen ? window.Keen : false;
    ctx[name] = ctx[name] || {
      ready: function (fn) {
        var h = document.getElementsByTagName('head')[0],
          s = document.createElement('script'),
          w = window,
          loaded;
        s.onload = s.onreadystatechange = function () {
          if ((s.readyState && !/^c|loade/.test(s.readyState)) || loaded) {
            return;
          }
          s.onload = s.onreadystatechange = null;
          loaded = 1;
          latest = w.Keen;
          if (prev) {
            w.Keen = prev;
          } else {
            try {
              delete w.Keen;
            } catch (e) {
              w.Keen = void 0;
            }
          }
          ctx[name] = latest;
          ctx[name].ready(fn);
        };
        s.async = 1;
        s.src = path;
        h.parentNode.insertBefore(s, h);
      }
    };
  })('ProdPerfectKeen', 'https://branch-account.trackinglibrary.prodperfect.com/keen-tracking.min.js', window);

  ProdPerfectKeen.ready(function () {
    var client = new ProdPerfectKeen({
      projectId: 'XeVciVGaoTJMnD6uSa8BSNHl',
      writeKey:
        'IRLSPRYP3G7A4JXYINIBL8XNIEIJV37K5OOLSG13L2G6C8YEBEEEZQ9DBPG7M9Y2EY3M05H370HYINS6LZREBLJ4W13Y7P8777H1UC25ORZKQPMOWDMSETPRCRCLOYVZHM3EA2O1VFMF60VN1ZMB3LCPPGLO8R5YOUW664WPVDT6SWTHASE8S24LV7OB64DS',
      requestType: 'beacon',
      host: 'branch-account.datapipe.prodperfect.com/v1'
    });

    client.extendEvents({
      visitor: {
        user_id: null
      }
    });

    var options = {
      ignoreDisabledFormFields: false,
      recordClicks: true,
      recordFormSubmits: true,
      recordInputChanges: true,
      recordPageViews: true,
      recordPageUnloads: true,
      recordScrollState: true
    };
    client.initAutoTracking(options);
  });
}

function initializeRecursiveLabs() {
  // only initialize recursive labs if the customer has been on the page for 1 min
  if (awsConfig.trackingEnabled) {
    window.setTimeout(() => {
      let rl = document.createElement('script');
      let s = document.scripts[0];
      rl.src = 'https://embed.rcrsv.io/ABOzoIe7bmF4TMpwtYjHscgULWKTNIq4woRf9j9KIJxCHhD0Bgc8Kg2CXHhW54KC?zwwi=1';
      rl.async = true;
      s.parentNode.insertBefore(rl, s);
    }, 60000);
  }
}

function initializeLogRocket() {
  if (awsConfig.trackingEnabled) {
    import('logrocket').then(({ default: LogRocket }) => LogRocket.init(awsConfig.logRocketApiKey));
  }
}

export function initializeHelloBar() {
  // Hello Bar that shows alerts that marketing can control
  if (awsConfig.trackingEnabled) {
    let hb = document.createElement('script');
    let s = document.scripts[0];
    hb.src = 'https://my.hellobar.com/c9c0aca374340c2c1f0032cb67233d7b6d4c14da.js';
    hb.async = true;
    s.parentNode.insertBefore(hb, s);
  }
}

export default function configureServices() {
  initializeSegment();
  // initializeProdPerfect();
  initializeRecursiveLabs();
  initializeLogRocket();
  initializeHelloBar();
}
