import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { CardStyleContext } from '.';
import theme from '../../theme';
import Tooltip from '../tooltip';

const styles = {
  layout: {
    position: 'relative'
  },
  spanContainer: {
    lineHeight: 'unset'
  },
  common: {
    fontSize: 10,
    fontWeight: 500,
    textTransform: 'uppercase'
  },
  dark: {
    composes: '$common',
    color: theme.colors.tallow
  },
  light: {
    composes: '$common',
    color: theme.colors.pesto
  },
  black: {
    composes: '$common',
    color: theme.colors.gray
  },
  green: {
    composes: '$common',
    color: theme.colors.sage
  },
  error: {
    composes: '$common',
    color: 'red'
  }
};

const CardLabel = ({ className, error, classes, children, tooltip }) => (
  <CardStyleContext.Consumer>
    {(cardStyle) => (
      <div>
        <div className={classes.spanContainer}>
          <span
            className={clsx(
              {
                [classes.layout]: 1,
                [classes.dark]: cardStyle === 'dark',
                [classes.light]: cardStyle === 'light',
                [classes.black]: cardStyle === 'black',
                [classes.green]: cardStyle === 'green',
                [classes.error]: error
              },
              className
            )}
          >
            {children}
            {tooltip && <Tooltip text={tooltip} />}
          </span>
        </div>
      </div>
    )}
  </CardStyleContext.Consumer>
);

CardLabel.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  error: PropTypes.bool,
  tooltip: PropTypes.string
};

CardLabel.defaultProps = {
  className: '',
  children: null,
  error: false,
  tooltip: ''
};

export default withStyles(styles)(CardLabel);
