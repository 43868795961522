import React, { useState, useMemo, useContext } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

import { stripeBankStatus } from '@ourbranch/lookups';
import Card from '../../../../components/card';
import CardContent from '../../../../components/card/card-content';
import CardLabel from '../../../../components/card/card-label';
import CardTitle from '../../../../components/card/card-title';
import Button from '../../../../components/button';
import Modal from '../../../../components/modal';
import ModalTitle from '../../../../components/modal/modal-title';
import ModalBody from '../../../../components/modal/modal-body';
import exclamationIcon from '../../../../assets/icons/exclamation.svg';
import styles from './styles';
import { MicroDepositContext, getRequiresVerification, GET_BANK_ACCOUNT } from './with-microdeposits-verification';
import NumberInput from './number-input';

const VERIFY_BANK_ACCOUNT = gql`
  mutation VerifyMicrodeposits($verificationDetails: VerifyMicrodepositsInput!) {
    verifyMicrodeposits(verificationDetails: $verificationDetails) {
      status
    }
  }
`;

const ValidateBankAccount = ({ classes, policyType }) => {
  const [isModalOpen, setModalState] = useState(false);
  const [amountOne, setAmountOne] = useState('');
  const [amountTwo, setAmountTwo] = useState('');

  const { verificationDetails } = useContext(MicroDepositContext);
  const { stripeCustomerId, accountId, policyId, status, microdepositDate } = verificationDetails[policyType];

  const { data: bankAccountData } = useQuery(GET_BANK_ACCOUNT, {
    variables: { accountId, policyId },
    skip: !policyId || !accountId
  });

  const [verifyBankAccount, { data, loading }] = useMutation(VERIFY_BANK_ACCOUNT, {
    refetchQueries: ['GetVerifiedStatus']
  });
  const isVerifiedStatus = useMemo(() => data?.verifyMicrodeposits?.status, [data]);

  const { bankName, last4 } = useMemo(
    () => ({ bankName: bankAccountData?.getBankAccount?.bankName, last4: bankAccountData?.getBankAccount?.last4 }),
    [bankAccountData]
  );

  const message = useMemo(() => {
    if (
      isVerifiedStatus === null ||
      isVerifiedStatus === stripeBankStatus.New ||
      isVerifiedStatus === stripeBankStatus.VerificationFailed
    ) {
      return { type: 'error' };
    }

    if (isVerifiedStatus === stripeBankStatus.Validated || isVerifiedStatus === stripeBankStatus.Verified) {
      return { type: 'success' };
    }
  }, [isVerifiedStatus]);

  if (!getRequiresVerification(status)) return null;

  return (
    <>
      <Card cardColor="darkYellow" className={classes.container}>
        <CardContent>
          <CardLabel>
            <img src={exclamationIcon} alt="warning" />
          </CardLabel>
          <CardTitle className={classes.title}>Bank validation required</CardTitle>
          <CardLabel>Please validate your account in order for this policy to be effective</CardLabel>
          <Button loading={loading} primary className={classes.button} onClick={() => setModalState(true)}>
            Validate bank account
          </Button>
        </CardContent>
      </Card>
      <Modal
        className="a"
        classes={{ modalPaper: classes.modalPaper }}
        open={isModalOpen}
        onClose={() => setModalState(false)}
      >
        {message?.type === 'success' ? (
          <>
            <ModalTitle>Your bank account was validated successfully</ModalTitle>
            <ModalBody>
              <Button primary type="button" onClick={() => setModalState(false)} className={classes.submitButton}>
                Got it
              </Button>
            </ModalBody>
          </>
        ) : (
          <>
            <ModalTitle>Please validate your bank account</ModalTitle>
            <ModalBody>
              <div>
                <p>
                  Before your <strong>{policyId}</strong> can be effective, we need to validate your bank account. We do
                  this by sending two small deposits to your account. On{' '}
                  <strong>{microdepositDate?.toLocaleDateString()}</strong> we sent the deposits to:
                </p>
                <div className={classes.bankInfo}>
                  <div>
                    <span>BANK NAME</span>
                    <span>ACCOUNT NUMBER</span>
                  </div>
                  <div>
                    <span className={classes.capitalize}>{(bankName || '').toLowerCase()}</span>
                    <span>xxxxxxx{last4}</span>
                  </div>
                </div>
                <article className={classes.transferInfo}>
                  <strong>Please note</strong>: Depending on your bank, these transfers may take up to 5 business days
                  to arrive. If you have questions or problems, please call us at{' '}
                  <a href="tel:(833)-427-2624">(833)-427-2624</a> Mon-Fri, 9am - 7pm EST.
                </article>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();

                    verifyBankAccount({
                      variables: {
                        verificationDetails: {
                          stripeCustomerId,
                          accountId,
                          policyId,
                          amountOne: amountOne * 100,
                          amountTwo: amountTwo * 100
                        }
                      }
                    });
                  }}
                >
                  <p>Please enter the amount of the deposits you received from Branch:</p>
                  <div className={classes.formInputs}>
                    <NumberInput
                      id="outlined-adornment-amount-one"
                      value={amountOne}
                      onChange={({ value }) => setAmountOne(value)}
                      label="Amount #1"
                      classes={classes}
                      required
                    />
                    <NumberInput
                      id="outlined-adornment-amount-one"
                      value={amountTwo}
                      onChange={({ value }) => setAmountTwo(value)}
                      label="Amount #2"
                      classes={classes}
                      required
                    />
                  </div>
                  {message?.type === 'error' && (
                    <div className={classes.warning}>
                      <span className={classes.warningIcon} />
                      <p>
                        The deposit amounts were incorrect. Please try again or{' '}
                        <a href="tel:(833)-427-2624">contact us</a> if you need help.
                      </p>
                    </div>
                  )}
                  <Button loading={loading} primary type="submit" className={classes.submitButton}>
                    Validate
                  </Button>
                </form>
              </div>
            </ModalBody>
          </>
        )}
      </Modal>
    </>
  );
};

ValidateBankAccount.propTypes = {
  classes: PropTypes.object.isRequired,
  policyType: PropTypes.string.isRequired
};

export default withStyles(styles)(ValidateBankAccount);
