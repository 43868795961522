import { stateToTzMap } from '@ourbranch/state-to-tz-map';
import { zonedTimeToUtc } from 'date-fns-tz';
import PropTypes from 'prop-types';
import React from 'react';
import Card from '../../../components/card';
import CardColumn from '../../../components/card-column';
import CardColumnHeader from '../../../components/card-column/card-column-header';
import CardColumnSubtitle from '../../../components/card-column/card-column-subtitle';
import CardContent from '../../../components/card/card-content';
import CardFieldGroup from '../../../components/card/card-field-group';
import CardLabel from '../../../components/card/card-label';
import CardText from '../../../components/card/card-text';
import { formatDate } from '../../../util/strings';

const PolicyIdColumn = ({ className, homePolicyData }) => {
  const policyStateTimezone = stateToTzMap[homePolicyData.state];
  const effectiveDate = zonedTimeToUtc(homePolicyData.effectiveDate, policyStateTimezone);
  const endDate = zonedTimeToUtc(homePolicyData.endDate, policyStateTimezone);

  return (
    <CardColumn className={className}>
      <CardColumnHeader>
        <CardColumnSubtitle>Policy</CardColumnSubtitle>
      </CardColumnHeader>
      <Card cardColor="light">
        <CardContent>
          <CardFieldGroup>
            <CardLabel>Policy ID</CardLabel>
            <CardText>{homePolicyData.id}</CardText>
          </CardFieldGroup>
          <CardFieldGroup>
            <CardLabel>Effective</CardLabel>
            <CardText>{`${formatDate(effectiveDate)} to ${formatDate(endDate)}`}</CardText>
          </CardFieldGroup>
        </CardContent>
      </Card>
    </CardColumn>
  );
};

PolicyIdColumn.propTypes = {
  className: PropTypes.string,
  homePolicyData: PropTypes.object.isRequired
};

PolicyIdColumn.defaultProps = {
  className: ''
};

export default PolicyIdColumn;
