export const homeOwnershipStatus = {
  P: 'Primary',
  PS: 'Primary, Owns Secondary',
  S: 'Secondary'
};

export const education = {
  H: { name: 'High School or Less', precedence: 0 },
  S: { name: 'Some College', precedence: 1 },
  A: { name: 'Associate Degree', precedence: 2 },
  B: { name: "Bachelor's Degree", precedence: 3 },
  M: { name: "Master's Degree", precedence: 4 },
  D: { name: 'Doctoral Degree', precedence: 5 }
};

export const maritalStatus = {
  S: 'Single',
  M: 'Married',
  W: 'Widow / Widower'
};

export const gender = {
  M: 'Male',
  F: 'Female'
};

export const typeOfHome = {
  1: 'Single Family',
  2: 'Condo',
  3: 'Townhome',
  4: 'Other'
};

export const constructionType = {
  B: 'Brick',
  F: 'Frame'
};

export const basementType = {
  S: 'Slab',
  R: 'Raised',
  U: 'Unfinished',
  PF: 'Partial Finished',
  FF: 'Full Finished'
};

export const exteriorWallType = {
  B: 'Brick',
  AL: 'Siding - Aluminum or Metal',
  ASB: 'Siding - Hardboard / Masonite',
  T111: 'Siding - Plywood',
  VL: 'Siding - Vinyl',
  S: 'Siding - Other (non-wood)',
  T: 'Stucco',
  W: 'Wood'
};

export const garageType = {
  0: 'None',
  1: 'Attached',
  2: 'Carport',
  3: 'Basement',
  D: 'Detached'
};

export const roofType = {
  W: 'Wood',
  S: 'Slate',
  T: 'Tile',
  M: 'Metal',
  C: 'Composition',
  O: 'Other'
};

export const roofShape = {
  F: 'Flat',
  G: 'Gable',
  H: 'Hip',
  O: 'Other'
};

export const PolicyTerm = {
  Renewal: 'renewal',
  Current: 'current',
  Prior: 'prior'
};

export const PolicyType = {
  HABundle: 'HA',
  ARBundle: 'AR',
  Auto: 'A',
  Home: 'H'
};
