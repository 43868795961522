import PropTypes from 'prop-types';
import React, { useState } from 'react';

const ShellAppbarLinksContext = React.createContext({
  autoVisible: false,
  homeVisible: false,
  setAutoVisible: () => {},
  setHomeVisible: () => {}
});

const ShellAppbarLinksContextProvider = ({ children }) => {
  const [autoVisible, setAutoVisible] = useState(false);
  const [homeVisible, setHomeVisible] = useState(false);

  return (
    <ShellAppbarLinksContext.Provider value={{ autoVisible, homeVisible, setAutoVisible, setHomeVisible }}>
      {children}
    </ShellAppbarLinksContext.Provider>
  );
};

ShellAppbarLinksContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { ShellAppbarLinksContext, ShellAppbarLinksContextProvider };
