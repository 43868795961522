import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import theme from '../../theme';

const useStyles = makeStyles({
  container: {
    borderRadius: 6,
    overflow: 'hidden',
    boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)',
    '@media (max-width: 414px)': {
      borderRadius: 0
    }
  },
  black: {
    backgroundColor: theme.colors.black
  },
  green: {
    backgroundColor: theme.colors.green
  },
  dark: {
    backgroundColor: theme.colors.turtleGreen
  },
  light: {
    backgroundColor: theme.colors.light
  },
  empty: {
    backgroundColor: 'none',
    border: '2px solid #fff',
    boxShadow: 'unset'
  }
});

export const CardStyleContext = React.createContext('light');

const cardStyleContextValues = {
  empty: null,
  dark: 'dark',
  black: 'black',
  green: 'green',
  light: 'light'
};

const Card = ({ className, children, cardColor }) => {
  const styles = useStyles();

  return (
    <div
      className={clsx(
        styles.container,
        { [styles.empty]: cardColor === 'empty' },
        { [styles.dark]: cardColor === 'dark' },
        { [styles.light]: cardColor === 'light' },
        { [styles.black]: cardColor === 'black' },
        { [styles.green]: cardColor === 'green' },
        className
      )}
    >
      <CardStyleContext.Provider value={cardStyleContextValues[cardColor]}>{children}</CardStyleContext.Provider>
    </div>
  );
};

Card.propTypes = {
  className: PropTypes.string,
  cardColor: PropTypes.string,
  children: PropTypes.node
};

Card.defaultProps = {
  className: '',
  cardColor: 'empty',
  children: null
};

export default Card;
