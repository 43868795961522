import { identify } from '../util/analytics';

export function newPasswordRequired(user) {
  return user.challengeName === 'NEW_PASSWORD_REQUIRED';
}

export function analyticsIdentifyUser(user) {
  if (user.signInUserSession) {
    const { payload } = user.signInUserSession.idToken;
    const username = payload['cognito:username'];
    identify(username, {
      email: payload.email,
      phone: payload.phone_number,
      firstName: payload.name,
      lastName: payload.family_name,
      name: `${payload.name} ${payload.family_name}`,
      address: payload.address
    });
  } else {
    identify(user.username);
  }
}
