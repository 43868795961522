import Auth from '@aws-amplify/auth';
import { makeStyles } from '@material-ui/core';
import * as Sentry from '@sentry/browser';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../components/button';
import CardLabel from '../../components/card/card-label';
import TextField from '../../components/text-field';
import theme from '../../theme';
import { identify, track } from '../../util/analytics';
import { logError } from '../../util/devmode-logger';
import { analyticsIdentifyUser, newPasswordRequired } from '../helpers';
import useSharedStyles from '../shared-styles';
import UserBeingAuthenticatedContainer from '../user-being-authenticated-container';

const useStyles = makeStyles({
  secondaryButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 16
  },
  notACustomerContainer: {
    marginTop: '26px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  notACustomerYetText: {
    fontSize: '12px',
    color: '#b4a794',
    textAlign: 'center',
    margin: '0'
  },
  notACustomerYetLink: {
    fontSize: '14px',
    color: theme.colors.carrot,
    textDecoration: 'underline'
  }
});

function SignIn() {
  const sharedStyles = useSharedStyles();
  const styles = useStyles();

  const [loading, setLoading] = useState(false);
  const [accountId, setAccountId] = useState('');
  const [password, setPassword] = useState('');
  const [errorText, setErrorText] = useState('');

  const history = useHistory();

  const userBeingAuthenticatedContainer = UserBeingAuthenticatedContainer.useContainer();

  const signIn = (e) => {
    e.preventDefault();

    if (!accountId) {
      setErrorText('Enter an account ID');
      return;
    }

    if (!password) {
      setErrorText('Enter your password');
      return;
    }

    if (accountId === 'anonymous') {
      return;
    }

    setLoading(true);

    Auth.signIn(accountId, password)
      .then(async (user) => {
        analyticsIdentifyUser(user);
        track('Account Sign In');

        userBeingAuthenticatedContainer.setUser(user);

        if (newPasswordRequired(user)) {
          return history.push('/auth/newpassword');
        }

        history.replace('/app');
      })
      .catch((err) => {
        logError(err);
        Sentry.captureException(err);
        setLoading(false);
        setErrorText('Invalid login. If this is your first login, please enter the ID and password emailed to you.');
        identify(accountId, {});
        track('Account Sign In Failure', { reason: JSON.stringify(err) });
      });
  };

  return (
    <>
      <div className={sharedStyles.title}>Sign in to your account</div>
      <form onSubmit={signIn}>
        <TextField
          type="text"
          placeholder="Account ID or Email"
          disabled={loading}
          className={sharedStyles.textField}
          value={accountId}
          onChange={(e) => setAccountId(e.target.value)}
        />
        <TextField
          type="password"
          placeholder="Password"
          disabled={loading}
          className={sharedStyles.textField}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <div className={sharedStyles.primaryButtonAndErrorTextContainer}>
          {errorText && <CardLabel error>{errorText}</CardLabel>}
          <Button primary type="submit" loading={loading} disabled={loading} className={sharedStyles.primaryButton}>
            Sign in
          </Button>
        </div>
      </form>
      <div className={styles.secondaryButtonContainer}>
        <Button
          className={sharedStyles.secondaryButton}
          disabled={loading}
          onClick={() => history.push('/auth/phonesigninstep1')}
        >
          Sign in with your phone
        </Button>
        <Button
          className={sharedStyles.secondaryButton}
          disabled={loading}
          onClick={() => history.push('/auth/forgotpasswordstep1')}
        >
          Forgot your password?
        </Button>
      </div>
      <div className={styles.notACustomerContainer}>
        <p className={styles.notACustomerYetText}>Not a part of the Branch community yet?</p>
        <a href="https://ourbranch.com/quote" rel="noopener noreferrer" className={styles.notACustomerYetLink}>
          See your price
        </a>
      </div>
    </>
  );
}

export default SignIn;
