import PropTypes from 'prop-types';
import React from 'react';
import Card from '../../../../components/card';
import CardContent from '../../../../components/card/card-content';
import CardFieldGroup from '../../../../components/card/card-field-group';
import CardLabel from '../../../../components/card/card-label';
import CardSubtitle from '../../../../components/card/card-subtitle';
import CardText from '../../../../components/card/card-text';
import CardTitle from '../../../../components/card/card-title';
import { formatAddress, formatDollars, prettifyLiabilityString } from '../../../../util/strings';
import { getPolicyCardBackgroundColor } from '../../../../util/get-policy-card-background-color';

const RentersPolicyCard = ({ className, rentersPolicyData, policyTerm }) => {
  const addressString = formatAddress(rentersPolicyData.policyDetails.rentersCoverage.rentersLocation);

  return (
    <Card term={policyTerm} className={className} cardColor={getPolicyCardBackgroundColor(policyTerm)}>
      <CardContent>
        <CardLabel>Renting</CardLabel>
        <CardTitle>{addressString}</CardTitle>
        <CardSubtitle>Coverage</CardSubtitle>
        <CardFieldGroup>
          <CardLabel>Personal Liability Coverage</CardLabel>
          <CardText>{formatDollars(rentersPolicyData.policyDetails.rentersCoverage.coverageX)}</CardText>
        </CardFieldGroup>
        <CardFieldGroup>
          <CardLabel>Personal Property / Living Expenses</CardLabel>
          <CardText>
            {prettifyLiabilityString(rentersPolicyData.policyDetails.rentersCoverage.coverageCD, {
              interpretAsThousands: false
            })}
          </CardText>
        </CardFieldGroup>
        <CardSubtitle>Included in Auto Premium</CardSubtitle>
      </CardContent>
    </Card>
  );
};

RentersPolicyCard.propTypes = {
  className: PropTypes.string,
  rentersPolicyData: PropTypes.shape({
    policyDetails: PropTypes.shape({
      rentersCoverage: PropTypes.shape({
        rentersLocation: PropTypes.shape({
          address: PropTypes.string.isRequired,
          city: PropTypes.string.isRequired,
          state: PropTypes.string.isRequired,
          zip: PropTypes.string.isRequired
        }).isRequired,
        coverageX: PropTypes.number.isRequired,
        coverageCD: PropTypes.string.isRequired
      }).isRequired
    })
  }).isRequired,
  policyTerm: PropTypes.string.isRequired
};

RentersPolicyCard.defaultProps = {
  className: ''
};

export default RentersPolicyCard;
