import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  container: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    marginBottom: '8px !important' // Override bottom margin set by CardColumn
  }
};

const CardColumnHeader = ({ className, classes, children }) => (
  <div className={clsx(classes.container, className)}>{children}</div>
);

CardColumnHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  children: PropTypes.node
};

CardColumnHeader.defaultProps = {
  className: '',
  children: null
};

export default withStyles(styles)(CardColumnHeader);
