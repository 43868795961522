import Auth from '@aws-amplify/auth';
import Grow from '@material-ui/core/Grow';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import BackButton from '../components/back-button';
import Card from '../components/card';
import CardContent from '../components/card/card-content';
import CardFieldGroup from '../components/card/card-field-group';
import CardLabel from '../components/card/card-label';
import CardText from '../components/card/card-text';
import LoadingScreen from '../components/loading-screen';
import Masonry from '../components/masonry';
import PageSectionTitle from '../components/page-section-title';
import ScreenPadding from '../components/screen-padding';
import { formatAddress, formatPhoneNumber } from '../util/strings';

const styles = {
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16
  }
};

function AccountDetails({ classes }) {
  const [userAttributes, setUserAttributes] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((user) => {
      setUserAttributes(user.attributes);
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  const fname = userAttributes.name;
  const lname = userAttributes.family_name;
  const phone = userAttributes.phone_number;
  const { email } = userAttributes;

  const address = formatAddress({
    address: userAttributes.address,
    city: userAttributes['custom:city'],
    state: userAttributes['custom:state'],
    zip: userAttributes['custom:zip']
  });

  return (
    <ScreenPadding>
      <Grow in appear timeout={250}>
        <div>
          <div className={classes.headerContainer}>
            <BackButton />
            <PageSectionTitle>Account Details</PageSectionTitle>
          </div>
          <Masonry centered={false}>
            <Card>
              <CardContent>
                <CardText>
                  {fname} {lname}
                </CardText>
                <CardFieldGroup>
                  <CardLabel>Mailing Address</CardLabel>
                  <CardText>{address}</CardText>
                </CardFieldGroup>
                <CardFieldGroup>
                  <CardLabel>Email</CardLabel>
                  <CardText>{email}</CardText>
                </CardFieldGroup>
                <CardFieldGroup>
                  <CardLabel>Phone Number</CardLabel>
                  <CardText>{formatPhoneNumber(phone)}</CardText>
                </CardFieldGroup>
              </CardContent>
            </Card>
          </Masonry>
        </div>
      </Grow>
    </ScreenPadding>
  );
}

AccountDetails.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AccountDetails);
