import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  container: {
    padding: 32,
    '@media(max-width: 414px)': {
      paddingLeft: 16,
      paddingRight: 16
    }
  }
};

const CardContent = ({ className, classes, children }) => (
  <div className={clsx(classes.container, className)}>{children}</div>
);

CardContent.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  children: PropTypes.node
};

CardContent.defaultProps = {
  className: '',
  children: null
};

export default withStyles(styles)(CardContent);
