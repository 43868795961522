import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import theme from '../../theme';

const styles = {
  cardListHeading: {
    padding: '0 32px 16px 32px',
    textTransform: 'uppercase',

    fontSize: 14,
    fontWeight: 300,
    color: theme.colors.pesto,
    '@media(max-width: 414px)': {
      paddingLeft: 16
    }
  }
};

const CardListHeading = withStyles(styles)(({ classes, className, children }) => (
  <div className={clsx(classes.cardListHeading, className)}>{children}</div>
));

CardListHeading.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

CardListHeading.defaultProps = {
  className: '',
  children: null
};

export default CardListHeading;
