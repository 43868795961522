import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import theme from '../../theme';

const styles = {
  brandText: {
    fontSize: 12,
    textTransform: 'uppercase',
    color: theme.colors.turtleGreen
  },
  maskingDots: {
    marginLeft: 8,
    marginRight: 8,
    fontSize: 9,
    color: theme.colors.tallow
  },
  regularText: {
    fontSize: 16,
    color: theme.colors.turtleGreen
  }
};

const maskingDotsText = '\u25CF\u25CF\u25CF\u25CF';

function PaymentMethodDisplay({ method, brand, last4, classes, className }) {
  if (!method && !brand && !last4) {
    return <span className={clsx(classes.regularText, className)}>Unknown</span>;
  }

  if (method === 'W' || method === 'Check') {
    return <span className={clsx(classes.regularText, className)}>Escrow</span>;
  }

  if (!brand && last4) {
    return (
      <span className={className}>
        <span className={classes.brandText}>ACH/ETF</span>
        <span className={classes.maskingDots}>{maskingDotsText}</span>
        <span className={classes.regularText}>{last4}</span>
      </span>
    );
  }

  return (
    <span className={className}>
      <span className={classes.brandText}>{brand}</span>
      <span className={classes.maskingDots}>{maskingDotsText}</span>
      <span className={classes.regularText}>{last4}</span>
    </span>
  );
}

PaymentMethodDisplay.propTypes = {
  method: PropTypes.string,
  brand: PropTypes.string,
  last4: PropTypes.string,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string
};

PaymentMethodDisplay.defaultProps = {
  className: '',
  method: '',
  brand: '',
  last4: ''
};

export default withStyles(styles)(PaymentMethodDisplay);
