import React, { createContext, useContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';

import { policyType as lookupPolicyType, stripeBankStatus } from '@ourbranch/lookups';

export const GET_VERIFIED_STATUS = gql`
  query GetVerifiedStatus($accountId: String!, $policyId: String!, $stripeCustomerId: String!) {
    getMicrodepositVerifiedStatus(accountId: $accountId, policyId: $policyId, stripeCustomerId: $stripeCustomerId) {
      status
    }
  }
`;

export const GET_BANK_ACCOUNT = gql`
  query ($accountId: String!, $policyId: String!) {
    getBankAccount(accountId: $accountId, policyId: $policyId) {
      bankName
      last4
      id
    }
  }
`;

export const MicroDepositContext = createContext({});

export const getRequiresVerification = (bankAccountStatus) =>
  [stripeBankStatus.New, stripeBankStatus.VerificationFailed].includes(bankAccountStatus);

export const withMicrodepositsVerification = (Component) => {
  const ComponentWithMicrodepositVerification = (props) => {
    const { homePolicyData, autoPolicyData, isFuturePolicy } = props;
    const { updateVerification, verificationDetails } = useContext(MicroDepositContext);

    const {
      id: policyId,
      accountId,
      stripeCustomerId,
      policyType,
      versionHistory
    } = autoPolicyData || homePolicyData || {};

    const { data: bankAccountData } = useQuery(GET_BANK_ACCOUNT, {
      variables: { accountId, policyId },
      fetchPolicy: 'cache-first'
    });
    useQuery(GET_VERIFIED_STATUS, {
      variables: { accountId, policyId, stripeCustomerId, stripeBankAccountId: bankAccountData?.getBankAccount?.id },
      onCompleted: (data) => {
        const bankAccountStatus = data?.getMicrodepositVerifiedStatus?.status;
        const requiresVerification = getRequiresVerification(bankAccountStatus);
        if (!requiresVerification && verificationDetails[policyType].policyId !== policyId) return;

        return updateVerification({
          [policyType]: {
            accountId,
            policyId,
            stripeCustomerId,
            status: bankAccountStatus,
            microdepositDate: new Date(versionHistory?.[0]?.updatedDateTime)
          }
        });
      },
      skip: !policyId || !stripeCustomerId || !bankAccountData?.getBankAccount?.id,
      fetchPolicy: 'cache-and-network'
    });

    return <>{Component && !isFuturePolicy && <Component {...props} />}</>;
  };

  ComponentWithMicrodepositVerification.propTypes = {
    homePolicyData: PropTypes.any,
    autoPolicyData: PropTypes.any,
    isFuturePolicy: PropTypes.any
  };

  ComponentWithMicrodepositVerification.defaultProps = {
    homePolicyData: undefined,
    autoPolicyData: undefined,
    isFuturePolicy: undefined
  };

  return ComponentWithMicrodepositVerification;
};

export const withMicroDeposit = (Component) => (props) => {
  const [verificationDetails, setVerificationDetails] = useState({
    [lookupPolicyType.Auto]: {},
    [lookupPolicyType.Home]: {}
  });

  const updateVerification = useCallback((details) => setVerificationDetails((prev) => ({ ...prev, ...details })), []);

  return (
    <MicroDepositContext.Provider value={{ updateVerification, verificationDetails }}>
      <Component {...props} />
    </MicroDepositContext.Provider>
  );
};
