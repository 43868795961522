import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import caret from '../../assets/icons/caretOrange.svg';
import ActionButton from './action-button/action-button';
import theme from '../../theme';

const styles = {
  container: {
    marginTop: 15,
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    marginBottom: '8px !important' // Override bottom margin set by CardColumn
  },
  text: {
    fontFamily: theme.fonts.LibreFranklin,
    fontSize: 20,
    lineHeight: 1.2,
    color: theme.colors.turtleGreen
  },
  collapseContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  caret: {
    transform: 'rotate(0deg)',
    transition: 'transform 0.3s linear'
  },
  caretOpen: {
    transform: 'rotate(180deg)',
    transition: 'transform 0.3s linear'
  }
};

const CardColumnTermHeader = ({ classes, children, expanded, setExpanded }) => {
  const collapsePressHandler = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded, setExpanded]);

  return (
    <div className={classes.collapseContainer}>
      <div className={clsx(classes.container, classes.text)}>{children}</div>
      <ActionButton
        className={clsx(classes.caret, { [classes.caretOpen]: !expanded })}
        onClick={collapsePressHandler}
        size="medium"
        icon={caret}
        alt="arrow"
      />
    </div>
  );
};

CardColumnTermHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  expanded: PropTypes.bool.isRequired,
  setExpanded: PropTypes.func.isRequired
};

CardColumnTermHeader.defaultProps = {
  children: null
};

export default withStyles(styles)(CardColumnTermHeader);
