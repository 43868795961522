import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import CircularProgress from '../circular-progress';
import CardContent from './card-content';

const styles = {
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
};

function CardContentLoading({ classes }) {
  return (
    <CardContent className={classes.loadingContainer}>
      <CircularProgress size={32} />
    </CardContent>
  );
}

CardContentLoading.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CardContentLoading);
