import Grow from '@material-ui/core/Grow';
import { withStyles } from '@material-ui/core/styles';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React from 'react';
import { useQuery } from 'react-apollo';
import { Redirect } from 'react-router-dom';
import BackButton from '../../components/back-button';
import LoadingScreen from '../../components/loading-screen';
import Masonry from '../../components/masonry';
import PageSectionSubtitle from '../../components/page-section-subtitle';
import PageSectionTitle from '../../components/page-section-title';
import ScreenPadding from '../../components/screen-padding';
import { autoPolicyTitleString } from '../../util/strings';
import CarCoverageCard from './components/car-coverage-card';
import PolicyCoverageCard from './components/policy-coverage-card';
import RentersCoverageCard from './components/renters-coverage-card';

const styles = {
  headerContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    margin: 0,
    '@media(max-width: 414px)': {
      marginRight: 8
    }
  },
  subtitle: {
    marginBottom: 16
  },
  card: {
    marginBottom: 8
  },
  policyAndRentersColumn: {
    display: 'flex',
    flexDirection: 'column'
  }
};

const GET_AUTO_COVERAGES_QUERY = gql`
  query {
    getMyPolicies {
      id
      state
      policyDetails {
        autoCoverage {
          policyLimitBIPD
          policyLimitUMBI
          policyLimitMedicalPayments
        }
        includeRenters
        rentersCoverage {
          coverageCD
          coverageX
          coverageY
          deductible
        }
        cars {
          VIN
          year
          make
          model
          deductibleCollision
          deductibleComprehensive
          limitUMPD
          limitACPE
          limitRental
          roadsideAssistance
        }
      }
    }
  }
`;

const AutoCoverage = ({ classes, match }) => {
  const { loading, data } = useQuery(GET_AUTO_COVERAGES_QUERY);

  if (loading) {
    return <LoadingScreen />;
  }

  const matchedPolicy = data.getMyPolicies.find((policy) => policy.id === match.params.policyId);

  if (!matchedPolicy) {
    return <Redirect to="/app/auto" />;
  }

  return (
    <ScreenPadding>
      <Grow in appear timeout={250}>
        <div>
          <div className={classes.headerContainer}>
            <BackButton />
            <PageSectionTitle className={classes.title}>
              {autoPolicyTitleString(matchedPolicy.policyDetails.cars)}
            </PageSectionTitle>
          </div>
          <PageSectionSubtitle className={classes.subtitle}>All Coverages</PageSectionSubtitle>
          <Masonry>
            <div className={classes.policyAndRentersColumn}>
              <PolicyCoverageCard className={classes.card} autoPolicyData={matchedPolicy} />
              {matchedPolicy.policyDetails.includeRenters && (
                <RentersCoverageCard className={classes.card} autoPolicyData={matchedPolicy} />
              )}
            </div>
            {matchedPolicy.policyDetails.cars.map((car) => (
              <CarCoverageCard className={classes.card} car={car} key={car.VIN} />
            ))}
          </Masonry>
        </div>
      </Grow>
    </ScreenPadding>
  );
};

AutoCoverage.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default withStyles(styles)(AutoCoverage);
