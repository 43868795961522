import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import theme from '../theme';

const styles = {
  divider: {
    height: 2,
    width: '100%',
    backgroundColor: theme.colors.lightBeige
  }
};

const PageSectionDivider = ({ classes, className }) => <div className={clsx(classes.divider, className)} />;

PageSectionDivider.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string
};

PageSectionDivider.defaultProps = {
  className: ''
};

export default withStyles(styles)(PageSectionDivider);
