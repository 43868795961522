import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { track } from '../../util/analytics';
import { autoPolicyTitleString, formatAddress } from '../../util/strings';
import CardFieldGroup from '../card/card-field-group';
import CardText from '../card/card-text';
import Modal from '../modal';
import ModalBody from '../modal/modal-body';
import ModalTitle from '../modal/modal-title';
import PreviousPaymentsTable from './previous-payments-table';

const PreviousPaymentsDialog = ({ open, onClose, policyData, transactions }) => {
  const { policyType, policyDetails } = policyData;
  const dialogTitleText = `Past payments on your ${policyType === 'H' ? 'homeowners' : 'auto'} policy`;
  const policyTitle =
    policyType === 'A' ? autoPolicyTitleString(policyDetails.cars) : formatAddress(policyDetails.home.homeLocation);

  useEffect(() => {
    if (open) {
      track('Account Viewed Billing History', { policyId: policyData.id });
    }
  }, [open, policyData.id]);

  return (
    <Modal open={open} onClose={onClose}>
      <ModalTitle responsive>{dialogTitleText}</ModalTitle>
      <ModalBody>
        <CardFieldGroup>
          <CardText>{policyTitle}</CardText>
        </CardFieldGroup>
        <PreviousPaymentsTable transactions={transactions} />
      </ModalBody>
    </Modal>
  );
};

PreviousPaymentsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  policyData: PropTypes.object.isRequired,
  transactions: PropTypes.arrayOf(PropTypes.object).isRequired
};

PreviousPaymentsDialog.defaultProps = {
  open: false,
  onClose: () => null
};

export default PreviousPaymentsDialog;
