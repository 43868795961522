import { withStyles } from '@material-ui/core/styles';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React from 'react';
import { useQuery } from 'react-apollo';
import CardList from '../../../components/card-list';
import CardListDivider from '../../../components/card-list/card-list-divider';
import CardListHeading from '../../../components/card-list/card-list-heading';
import CardListItem from '../../../components/card-list/card-list-item';
import CardListItemText from '../../../components/card-list/card-list-item-text';
import CardListLoading from '../../../components/card-list/card-list-loading';
import { track } from '../../../util/analytics';
import { formatDateTime } from '../../../util/strings';

const styles = {
  cardList: {
    maxHeight: 320,
    overflowY: 'auto'
  }
};

export const GET_MY_DOCS_QUERY = gql`
  {
    getMyDocs {
      policyId
      friendlyName
      lastModified
      url
    }
  }
`;
function PolicyCardDocsList({ policyId, classes }) {
  const { loading, data } = useQuery(GET_MY_DOCS_QUERY);

  return (
    <CardList className={classes.cardList}>
      <CardListHeading>Documents</CardListHeading>
      {loading ? (
        <CardListLoading />
      ) : (
        data.getMyDocs
          .filter((doc) => doc.policyId === policyId)
          .map((doc, idx) => (
            <React.Fragment key={doc.url}>
              {idx !== 0 && <CardListDivider />}
              <CardListItem
                onClick={() => {
                  track('Account Downloaded Policy Doc', { name: doc.friendlyName });
                  window.open(doc.url, '_blank');
                }}
              >
                <CardListItemText primary={formatDateTime(new Date(doc.lastModified))} secondary={doc.friendlyName} />
              </CardListItem>
            </React.Fragment>
          ))
      )}
    </CardList>
  );
}

PolicyCardDocsList.propTypes = {
  policyId: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PolicyCardDocsList);
