import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import theme from '../../../theme';

const styles = {
  coverageCardSubLabel: {
    fontFamily: theme.fonts.LibreFranklin,
    fontSize: 12,
    fontWeight: 300,
    color: theme.colors.tallow,
    marginTop: 12,
    marginBottom: 4
  }
};

const CoverageCardSubLabel = ({ classes, className, children }) => (
  <div className={clsx(classes.coverageCardSubLabel, className)}>{children}</div>
);

CoverageCardSubLabel.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  children: PropTypes.node
};

CoverageCardSubLabel.defaultProps = {
  className: '',
  children: null
};

export default withStyles(styles)(CoverageCardSubLabel);
