import Auth from '@aws-amplify/auth';
import * as Sentry from '@sentry/browser';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../components/button';
import CardLabel from '../../components/card/card-label';
import CardTitle from '../../components/card/card-title';
import Checkbox from '../../components/checkbox';
import TextField from '../../components/text-field';
import { logError } from '../../util/devmode-logger';
import { analyticsIdentifyUser } from '../helpers';
import useSharedStyles from '../shared-styles';
import UserBeingAuthenticatedContainer from '../user-being-authenticated-container';

function RequireNewPassword() {
  const sharedStyles = useSharedStyles();
  const history = useHistory();

  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState('');

  const userBeingAuthenticatedContainer = UserBeingAuthenticatedContainer.useContainer();
  if (!userBeingAuthenticatedContainer.user) {
    history.replace('/auth');
    return null;
  }

  const amplifySetNewPassword = async (e) => {
    e.preventDefault();

    if (password.length < 8 || !/\d/.test(password)) {
      setErrorText("Password doesn't meet requirements");
      return;
    }

    setLoading(true);
    const { user } = userBeingAuthenticatedContainer;

    try {
      Auth.completeNewPassword(user, password, user.challengeParam.requiredAttributes).then((user) => {
        analyticsIdentifyUser(user);
        history.replace('/app');
      });
    } catch (err) {
      logError(err);
      Sentry.captureException(err);
      setLoading(false);
      setErrorText('Something went wrong. Please try again later.');
    }
  };

  return (
    <>
      <CardTitle className={sharedStyles.title}>Set new password</CardTitle>
      <form onSubmit={amplifySetNewPassword}>
        <div className={sharedStyles.textFieldWithLabelContainer}>
          <CardLabel>At least 8 characters and 1 number</CardLabel>
          <TextField
            type={showPassword ? 'text' : 'password'}
            placeholder="New password"
            disabled={loading}
            className={sharedStyles.textFieldWithLabel}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className={sharedStyles.checkboxOrRadioField}>
          <Checkbox checked={showPassword} onChange={(e) => setShowPassword(e.target.checked)} />
          <span className={sharedStyles.checkboxOrRadioFieldText}>Show password</span>
        </div>
        <div className={sharedStyles.primaryButtonAndErrorTextContainer}>
          {errorText && <CardLabel error>{errorText}</CardLabel>}
          <Button
            primary
            type="submit"
            loading={loading}
            disabled={loading || !password}
            className={sharedStyles.primaryButton}
          >
            Set password
          </Button>
        </div>
      </form>
    </>
  );
}

export default RequireNewPassword;
