import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import arrowDownIcon from '../../assets/icons/arrow-down.svg';
import PageSectionDivider from '../page-section-divider';

const styles = {
  header: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    '@media(max-width: 414px)': {
      paddingLeft: 16,
      paddingRight: 16
    },
    cursor: 'pointer'
  },
  noClick: {
    pointerEvents: 'none'
  },
  headerContent: {
    height: '100%'
  },
  headerIconContainer: {
    height: 48,
    width: 48,
    marginLeft: 8,
    marginTop: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media(max-width: 414px)': {
      height: 32,
      width: 32
    }
  },
  headerIconImage: {
    height: 32,
    width: 32,
    transition: 'transform 0.15s ease',
    '@media(max-width: 414px)': {
      height: 24,
      width: 24
    }
  },
  flipped: {
    transform: 'rotate(180deg)'
  },
  content: {
    width: '100%'
  }
};

// Right now this is a fully controlled component. We can modify it to
// allow uncontrolled usage too, but there's no specific use case in the current
// designs where that would be needed or useful.
const PageSectionAccordionPanel = ({ classes, className, expanded, onClick, header, noCollapse, children }) => (
  <div className={className}>
    <div className={clsx(classes.header, { [classes.noClick]: noCollapse })} onClick={onClick}>
      <div className={classes.headerContent}>{header}</div>
      {!noCollapse && (
        <div className={classes.headerIconContainer}>
          <IconButton>
            <img
              src={arrowDownIcon}
              alt="Expand or Collapse"
              className={clsx(classes.headerIconImage, { [classes.flipped]: expanded })}
            />
          </IconButton>
        </div>
      )}
    </div>
    <Collapse in={expanded}>
      <div className={classes.content}>{children}</div>
    </Collapse>
    <PageSectionDivider />
  </div>
);

PageSectionAccordionPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  noCollapse: PropTypes.bool,
  className: PropTypes.string,
  expanded: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
  header: PropTypes.node
};

PageSectionAccordionPanel.defaultProps = {
  className: '',
  noCollapse: false,
  expanded: false,
  onClick: () => null,
  children: null,
  header: null
};

export default withStyles(styles)(PageSectionAccordionPanel);
