import PropTypes from 'prop-types';
import React from 'react';
import CardColumn from '../../../../components/card-column';
import CardColumnHeader from '../../../../components/card-column/card-column-header';
import CardColumnLabel from '../../../../components/card-column/card-column-label';
import CardColumnSubtitle from '../../../../components/card-column/card-column-subtitle';
import CarCard from './car-card';

const CarsColumn = ({ className, autoPolicyData }) => {
  const { cars } = autoPolicyData.policyDetails;

  return (
    <CardColumn className={className}>
      <CardColumnHeader>
        <CardColumnSubtitle>Cars</CardColumnSubtitle>
        <CardColumnLabel>{`${cars.length} car${cars.length > 1 ? 's' : ''}`}</CardColumnLabel>
      </CardColumnHeader>
      {cars.map((car) => (
        <CarCard car={car} key={car.VIN} />
      ))}
    </CardColumn>
  );
};

CarsColumn.propTypes = {
  className: PropTypes.string,
  autoPolicyData: PropTypes.shape({
    policyDetails: PropTypes.shape({
      cars: PropTypes.arrayOf(
        PropTypes.shape({
          VIN: PropTypes.string
        }).isRequired
      ).isRequired
    }).isRequired
  }).isRequired
};

CarsColumn.defaultProps = {
  className: ''
};

export default CarsColumn;
