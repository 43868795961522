import React from 'react';
import PropTypes from 'prop-types';
import { InputAdornment, FormControl, InputLabel, OutlinedInput } from '@material-ui/core';
import NumberFormat from 'react-number-format';

const NumberFormatCustom = ({ inputRef, onChange, id, name, format, value, ...props }) => (
  <NumberFormat
    {...format}
    {...props}
    value={value || value === 0 ? value : ''}
    getInputRef={inputRef}
    onValueChange={(values) => {
      onChange({
        value: values.floatValue,
        id,
        name
      });
    }}
  />
);

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  format: PropTypes.shape({
    thousandSeparator: PropTypes.bool,
    decimalSeparator: PropTypes.bool,
    allowNegative: PropTypes.bool,
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    mask: PropTypes.string
  })
};

NumberFormatCustom.defaultProps = {
  format: {},
  value: undefined
};

const NumberInput = ({ label, classes, id, ...props }) => {
  return (
    <FormControl margin="dense" variant="outlined" className={classes.inputFieldContainer}>
      <InputLabel htmlFor={id} className={classes.inputFieldLabel}>
        {label}
      </InputLabel>
      <OutlinedInput
        id={id}
        notched
        margin="dense"
        className={classes.inputField}
        startAdornment={
          <InputAdornment position="start" className={classes.adornment}>
            $
          </InputAdornment>
        }
        inputProps={{
          name: id,
          format: {
            thousandSeparator: true,
            allowNegative: false,
            fixedDecimalScale: true,
            decimalScale: 2
          }
        }}
        inputComponent={NumberFormatCustom}
        {...props}
      />
    </FormControl>
  );
};

NumberInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired
};

export default NumberInput;
