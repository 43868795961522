import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

function RouteChangeListener({ onChange }) {
  const [didCaptureInitialRoute, setDidCaptureInitialRoute] = useState(false);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (didCaptureInitialRoute) {
      return;
    }
    onChange(location);
    setDidCaptureInitialRoute(true);
  }, [didCaptureInitialRoute, location, onChange]);

  useEffect(() => {
    const debouncedOnChange = debounce(onChange, 1000);
    return history.listen(debouncedOnChange);
  }, [history, onChange]);

  return null;
}

RouteChangeListener.propTypes = {
  onChange: PropTypes.func.isRequired
};

export default RouteChangeListener;
