import React, { useContext, useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { Collapse } from '@material-ui/core';
import ConditionalWrap from 'conditional-wrap';
import { policyType, rejectCodes } from '@ourbranch/lookups';

import buildQuoteUrl from '../../../../util/quote-url-builder';
import { PolicyTerm, PolicyType } from '../../../../util/terminology';
import CardColumn from '../../../../components/card-column';
import CardColumnHeader from '../../../../components/card-column/card-column-header';
import CardColumnTitle from '../../../../components/card-column/card-column-title';
import NoPolicyCard from '../../../../components/no-policy-card';
import { ShellAppbarLinksContext } from '../../../../components/shell/shell-appbar-links-context';
import { GET_REJECT_CODES_QUERY } from '../shared-queries';
import AutoPolicyCard from './auto-policy-card';
import CardColumnSubLabel from '../../../../components/card-column/card-column-sub-label';
import CardColumnTermHeader from '../../../../components/card-column/card-column-term-header';
import filterPoliciesBasedOnTerm from '../../../../util/policy-filter';
import ValidateBankAccount from '../validate-bank-account';

const GET_AUTO_POLICIES_QUERY = gql`
  {
    getMyPolicies(policyType: "A") {
      id
      accountId
      stripeCustomerId
      policyType
      premium
      surplusContribution
      effectiveDate
      endDate
      state
      fees {
        amount
      }
      policyDetails {
        cars {
          year
          make
          model
        }
        autoCoverage {
          policyLimitBIPD
          policyLimitUMBI
        }
      }
      versionHistory {
        updatedDateTime
      }
    }

    futurePolicies: getMyPolicies(policyType: "A", isFuturePolicy: true) {
      id
      accountId
      stripeCustomerId
      policyType
      versionHistory {
        updatedDateTime
      }
    }
  }
`;

const AutoPoliciesColumn = ({ className }) => {
  const { loading: getAutoPoliciesQueryLoading, data: getAutoPoliciesQueryData } = useQuery(GET_AUTO_POLICIES_QUERY);

  const { loading: getRejectCodesQueryLoading, data: getRejectCodesQueryData } = useQuery(GET_REJECT_CODES_QUERY, {
    skip: getAutoPoliciesQueryLoading || !getAutoPoliciesQueryData || getAutoPoliciesQueryData.getMyPolicies.length > 0
  });

  const [expandedPrior, setExpandedPrior] = useState(true);
  const [expandedCurrent, setExpandedCurrent] = useState(true);
  const [expandedRenewal, setExpandedRenewal] = useState(true);

  const addAutoPolicy = useCallback(async () => {
    const url = await buildQuoteUrl(PolicyType.Auto);
    window.location.href = url;
  }, []);

  const dontUpsell = useMemo(() => {
    if (getRejectCodesQueryLoading || !getRejectCodesQueryData) {
      return true;
    }

    // Don't try to upsell an auto policy if the user was rejected for it.
    // See https://github.com/gobranch/branch/issues/3906
    for (const policy of getRejectCodesQueryData.getMyPolicies) {
      const rejectCode = policy.offer.quote.offerings.autoRejectCode;
      if (rejectCode && rejectCode !== rejectCodes.NO_CARS) {
        return true;
      }
    }

    return false;
  }, [getRejectCodesQueryLoading, getRejectCodesQueryData]);

  const shellAppbarLinksContext = useContext(ShellAppbarLinksContext);

  if (getAutoPoliciesQueryLoading || !getAutoPoliciesQueryData) {
    return null;
  }

  const autoPolicies = getAutoPoliciesQueryData.getMyPolicies;
  const futurePolicies = getAutoPoliciesQueryData.futurePolicies;
  const hasAutoPolicies = autoPolicies.length > 0 || futurePolicies.length > 0;

  const { priorPolicies, currentPolicies, renewalPolicies } = filterPoliciesBasedOnTerm(autoPolicies);

  if (!hasAutoPolicies && dontUpsell) {
    return null;
  }

  shellAppbarLinksContext.setAutoVisible(true);

  return (
    <CardColumn className={className}>
      <ConditionalWrap condition={hasAutoPolicies} wrap={(children) => <Link to="/app/auto">{children}</Link>}>
        <CardColumnHeader>
          <CardColumnTitle>Auto</CardColumnTitle>
          {hasAutoPolicies && <CardColumnSubLabel>More Details</CardColumnSubLabel>}
        </CardColumnHeader>
      </ConditionalWrap>
      <ValidateBankAccount policyType={policyType.Auto} />
      {renewalPolicies?.length > 0 && (
        <>
          <CardColumnTermHeader expanded={expandedRenewal} setExpanded={setExpandedRenewal}>
            {currentPolicies?.length > 0 ? `Renewal${renewalPolicies.length === 1 ? '' : 's'}` : 'Future'}
          </CardColumnTermHeader>
          <Collapse in={expandedRenewal}>
            {renewalPolicies.map((policy) => (
              <AutoPolicyCard autoPolicyData={policy} className={className} policyTerm={PolicyTerm.Renewal} />
            ))}
          </Collapse>
        </>
      )}
      {currentPolicies.length > 0 && (
        <>
          <CardColumnTermHeader expanded={expandedCurrent} setExpanded={setExpandedCurrent}>
            {currentPolicies.length > 1 ? 'Current Terms' : 'Current Term'}
          </CardColumnTermHeader>
          <Collapse in={expandedCurrent}>
            {currentPolicies.map((policy) => (
              <AutoPolicyCard autoPolicyData={policy} className={className} policyTerm={PolicyTerm.Current} />
            ))}
          </Collapse>
        </>
      )}
      {priorPolicies.length > 0 && (
        <>
          <CardColumnTermHeader expanded={expandedPrior} setExpanded={setExpandedPrior}>
            {priorPolicies.length > 1 ? 'Prior Terms' : 'Prior Term'}
          </CardColumnTermHeader>
          <Collapse in={expandedPrior}>
            {priorPolicies.map((policy) => (
              <AutoPolicyCard autoPolicyData={policy} className={className} policyTerm={PolicyTerm.Prior} />
            ))}
          </Collapse>
        </>
      )}
      {!hasAutoPolicies && (
        <NoPolicyCard titleText="Want to cover your cars?" actionText="Get Auto Insurance" onClick={addAutoPolicy} />
      )}
    </CardColumn>
  );
};

AutoPoliciesColumn.propTypes = {
  className: PropTypes.string
};

AutoPoliciesColumn.defaultProps = {
  className: ''
};

export default AutoPoliciesColumn;
