import Auth from '@aws-amplify/auth';
import * as Sentry from '@sentry/browser';
import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import { useHistory } from 'react-router-dom';
import Button from '../../components/button';
import CardText from '../../components/card/card-text';
import CardTitle from '../../components/card/card-title';
import TextField from '../../components/text-field';
import { track } from '../../util/analytics';
import { logError } from '../../util/devmode-logger';
import { analyticsIdentifyUser } from '../helpers';
import useSharedStyles from '../shared-styles';
import UserBeingAuthenticatedContainer from '../user-being-authenticated-container';

function PhoneSignInStep1() {
  const sharedStyles = useSharedStyles();
  const history = useHistory();
  const userBeingAuthenticatedContainer = UserBeingAuthenticatedContainer.useContainer();

  // If input is +1 (617) 331-1994
  // phoneNumber will be 16173311994
  const [phoneNumber, setPhoneNumber] = useState('');
  const [loading, setLoading] = useState(false);

  const sendOtp = (e) => {
    e.preventDefault();

    setLoading(true);
    Auth.signIn(`+${phoneNumber}`)
      .then((user) => {
        analyticsIdentifyUser(user);
        track('Account Phone Sign In Started');
        userBeingAuthenticatedContainer.setUser(user);
      })
      .catch((err) => {
        Sentry.captureException(err);
        logError(err);
      })
      .finally(() => {
        setLoading(false);
        history.push('/auth/phonesigninstep2');
      });
  };

  return (
    <>
      <CardTitle className={sharedStyles.title}>Sign in with phone</CardTitle>
      <CardText className={sharedStyles.explanationText}>
        {"We'll"} text a sign in code to the phone number you used to join Branch.
      </CardText>
      <form onSubmit={sendOtp}>
        <InputMask
          type="tel"
          mask="+1 (999) 999-9999"
          onChange={(e) => setPhoneNumber(e.target.value.replace(/\D/g, ''))} // +1 (617) 331-1994 -> 16173311994
          placeholder="Phone number"
          value={phoneNumber}
          disabled={loading}
        >
          <TextField className={sharedStyles.textField} />
        </InputMask>
        <div className={sharedStyles.primaryButtonAndErrorTextContainer}>
          <Button
            primary
            type="submit"
            loading={loading}
            disabled={loading || phoneNumber.length !== 11}
            className={sharedStyles.primaryButton}
          >
            Send Code
          </Button>
        </div>
      </form>
    </>
  );
}

export default PhoneSignInStep1;
