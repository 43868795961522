import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  twoColumnGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridRowGap: '18px',
    gridColumnGap: '6px'
  }
};

const TwoColumnGrid = ({ classes, className, children }) => (
  <div className={clsx(classes.twoColumnGrid, className)}>{children}</div>
);

TwoColumnGrid.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  children: PropTypes.node
};

TwoColumnGrid.defaultProps = {
  className: '',
  children: null
};

export default withStyles(styles)(TwoColumnGrid);
