import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import CardFieldGroup from '../../../components/card/card-field-group';
import CardLabel from '../../../components/card/card-label';
import CardText from '../../../components/card/card-text';
import NoMarginCardFieldGroup from '../../../components/card/no-margin-card-field-group';
import TwoColumnGrid from '../../../components/two-column-grid';
import { TOOLTIPS } from '../../../util/constants';
import { formatDollars, formatDeductible } from '../../../util/strings';
import CoverageCardSubLabel from './coverage-card-sub-label';
import CoverageCardSubtitle from './coverage-card-subtitle';

const styles = {
  twoColumnGrid: {
    marginTop: 16
  }
};

const DeductiblesOtherCoveragesColumn = ({
  classes,
  className,
  homePolicyData: {
    policyDetails: { homeCoverage }
  }
}) => (
  <div className={className}>
    <CardText>Deductibles</CardText>
    <CardFieldGroup>
      <CardLabel tooltip={TOOLTIPS.HOME_DEDUCTIBLE}>Deductible</CardLabel>
      <CardText>
        {homeCoverage.deductibleAllOther ? formatDeductible(homeCoverage.deductibleAllOther) : 'None'}
      </CardText>
    </CardFieldGroup>
    <CoverageCardSubLabel>Except For:</CoverageCardSubLabel>
    <TwoColumnGrid>
      <NoMarginCardFieldGroup>
        <CardLabel tooltip={TOOLTIPS.HOME_DEDUCTIBLE_WIND_HAIL}>Wind & Hail Deductible</CardLabel>
        <CardText>
          {homeCoverage.deductibleWindHail ? formatDeductible(homeCoverage.deductibleWindHail) : 'None'}
        </CardText>
      </NoMarginCardFieldGroup>
      {/* https://github.com/gobranch/branch/pull/828#discussion_r280156249 */}
      {homeCoverage.deductibleHurricane && (
        <NoMarginCardFieldGroup>
          <CardLabel tooltip={TOOLTIPS.HOME_DEDUCTIBLE_HURRICANE}>Hurricane Deductible</CardLabel>
          <CardText>{formatDollars(homeCoverage.deductibleHurricane)}</CardText>
        </NoMarginCardFieldGroup>
      )}
    </TwoColumnGrid>
    <CoverageCardSubtitle>Other Coverages</CoverageCardSubtitle>
    <TwoColumnGrid className={classes.twoColumnGrid}>
      <NoMarginCardFieldGroup>
        <CardLabel>Business Property</CardLabel>
        <CardText>{homeCoverage.coverageBP ? formatDollars(homeCoverage.coverageBP) : 'Not Purchased'}</CardText>
      </NoMarginCardFieldGroup>
      <NoMarginCardFieldGroup>
        <CardLabel>Roof Surface</CardLabel>
        <CardText>{homeCoverage.coverageRSExtended ? 'Extended Coverage' : 'Endorsement'}</CardText>
      </NoMarginCardFieldGroup>
      <NoMarginCardFieldGroup>
        <CardLabel>Fire Department Charges</CardLabel>
        <CardText>{homeCoverage.coverageF ? formatDollars(homeCoverage.coverageF) : 'Not Purchased'}</CardText>
      </NoMarginCardFieldGroup>
      <NoMarginCardFieldGroup>
        <CardLabel>Pet Bites</CardLabel>
        <CardText>{homeCoverage.coveragePetBite ? 'Purchased' : 'Not Purchased'}</CardText>
      </NoMarginCardFieldGroup>
      <NoMarginCardFieldGroup>
        <CardLabel>Loss Assessments</CardLabel>
        <CardText>{homeCoverage.coverageG ? formatDollars(homeCoverage.coverageG) : 'Not Purchased'}</CardText>
      </NoMarginCardFieldGroup>
      <NoMarginCardFieldGroup>
        <CardLabel>Course of Construction</CardLabel>
        <CardText>{homeCoverage.coverageCourseOfConstruction ? 'Purchased' : 'Not Purchased'}</CardText>
      </NoMarginCardFieldGroup>
      <NoMarginCardFieldGroup>
        <CardLabel>Water Backup</CardLabel>
        <CardText>
          {homeCoverage.coverageWaterBackup ? formatDollars(homeCoverage.coverageWaterBackup) : 'Not Purchased'}
        </CardText>
      </NoMarginCardFieldGroup>
      <NoMarginCardFieldGroup>
        <CardLabel>Cameras</CardLabel>
        <CardText>{homeCoverage.coverageCA ? formatDollars(homeCoverage.coverageCA) : 'Not Purchased'}</CardText>
      </NoMarginCardFieldGroup>
      <NoMarginCardFieldGroup>
        <CardLabel>Jewelry, Watches, and Furs</CardLabel>
        <CardText>{homeCoverage.coverageJ ? formatDollars(homeCoverage.coverageJ) : 'Not Purchased'}</CardText>
      </NoMarginCardFieldGroup>
      <NoMarginCardFieldGroup>
        <CardLabel>Extended Premises</CardLabel>
        <CardText>{homeCoverage.coverageExtendedPremises ? 'Purchased' : 'Not Purchased'}</CardText>
      </NoMarginCardFieldGroup>
    </TwoColumnGrid>
  </div>
);

DeductiblesOtherCoveragesColumn.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  homePolicyData: PropTypes.object.isRequired
};

DeductiblesOtherCoveragesColumn.defaultProps = {
  className: ''
};

export default withStyles(styles)(DeductiblesOtherCoveragesColumn);
