import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import Card from '../../../../../components/card';
import CardContent from '../../../../../components/card/card-content';
import theme from '../../../../../theme';
import AppStoreBadgeImage from './app-store-badge.svg';
import GooglePlayBadgeImage from './google-play-badge.svg';
import MobileAppIconImage from './mobile-app-icon.svg';

const useStyles = makeStyles({
  card: {
    backgroundColor: theme.colors.lightBeige
  },
  titleText: {
    fontSize: 32,
    fontWeight: 100,
    color: theme.colors.hunterGreen,
    lineHeight: 1.25
  },
  subtitleText: {
    fontSize: 14,
    lineHeight: 1.43,
    color: theme.colors.hunterGreen,
    marginTop: 8
  },
  appIconAndNameRow: {
    marginTop: 16,
    display: 'flex',
    alignItems: 'center'
  },
  appIconImage: {
    height: 60,
    width: 60
  },
  appNameTextContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 16
  },
  appNameText: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: 1.2,
    color: theme.colors.hunterGreen
  },
  storeBadgesRow: {
    display: 'flex',
    marginTop: 24
  },
  appStoreBadge: {
    width: 114,
    height: 38
  },
  googlePlayBadge: {
    width: 133,
    height: 38,
    marginLeft: 16
  }
});

const InventoryCard = ({ className }) => {
  const styles = useStyles();

  return (
    <Card className={clsx(styles.card, className)}>
      <CardContent>
        <div className={styles.titleText}>Complete your inventory</div>
        <div className={styles.subtitleText}>Maintain your savings by completing your inventory in our app.</div>
        <div className={styles.appIconAndNameRow}>
          <img src={MobileAppIconImage} className={styles.appIconImage} alt="" />
          <div className={styles.appNameTextContainer}>
            <div className={styles.appNameText}>Branch Insurance</div>
          </div>
        </div>
        <div className={styles.storeBadgesRow}>
          <a
            href="https://apps.apple.com/us/app/branch-insurance/id1437502167?itsct=apps_box&itscg=30200"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.appStoreBadge}
          >
            <img src={AppStoreBadgeImage} alt="iPhone App Store Link" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.branch.accountmobile&hl=en&gl=US"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.googlePlayBadge}
          >
            <img src={GooglePlayBadgeImage} className={styles.googlePlayBadge} alt="Get it on Google Play" />
          </a>
        </div>
      </CardContent>
    </Card>
  );
};

InventoryCard.propTypes = {
  className: PropTypes.string
};

InventoryCard.defaultProps = {
  className: ''
};

export default InventoryCard;
