import { makeStyles } from '@material-ui/core';
import * as Sentry from '@sentry/browser';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useApolloClient } from 'react-apollo';
import { CardElement, Elements, injectStripe } from 'react-stripe-elements';
import theme from '../../../theme';
import { logError } from '../../../util/devmode-logger';
import Button from '../../button';

const MODIFY_PAYMENT_METHOD_CARD_MUTATION = gql`
  mutation ModifyPaymentMethodCard($policyId: String, $stripeToken: String) {
    modifyPaymentMethodCard(policyId: $policyId, stripeToken: $stripeToken)
  }
`;

const useStyles = makeStyles({
  container: {
    marginTop: 12
  },
  title: {
    fontSize: 24,
    fontWeight: 300,
    color: theme.colors.pesto
  },
  cardInputContainer: {
    backgroundColor: 'white',
    borderRadius: 5,
    padding: '4px 8px',
    boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)',
    marginTop: 14
  },
  submitButton: {
    marginTop: 18
  }
});

const cardElementStyle = {
  base: {
    fontSize: '16px',
    fontFamily: 'Libre Franklin',
    color: theme.colors.turtleGreen,
    fontWeight: '400',
    textTransform: 'uppercase',
    lineHeight: '36px',
    '::placeholder': {
      fontSize: '14px',
      color: theme.colors.tallow
    }
  }
};

const ModifyCardForm = injectStripe(({ policyId, stripe, onCompleted }) => {
  const styles = useStyles();
  const apolloClient = useApolloClient();

  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [cardTextCompleted, setCardTextCompleted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const {
        token: { id: stripeToken }
      } = await stripe.createToken({ name: 'change_payment_method' });
      await apolloClient.mutate({
        mutation: MODIFY_PAYMENT_METHOD_CARD_MUTATION,
        variables: { policyId, stripeToken }
      });
    } catch (err) {
      logError(err);
      Sentry.captureException(err);
    } finally {
      setLoading(false);
      onCompleted();
    }
  };

  const handleCardTextChange = ({ error, complete }) => {
    setHasErrors((currentHasErrors) => {
      if (currentHasErrors !== Boolean(error)) {
        return Boolean(error);
      }
    });

    setCardTextCompleted((currentCardTextCompletedstate) => {
      if (currentCardTextCompletedstate !== complete) {
        return complete;
      }
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>Enter new card</div>
      <form onSubmit={handleSubmit}>
        <div className={styles.cardInputContainer}>
          <CardElement hidePostalCode style={cardElementStyle} onChange={handleCardTextChange} />
        </div>
        <Button
          primary
          loading={loading}
          disabled={!cardTextCompleted || hasErrors}
          type="submit"
          className={styles.submitButton}
        >
          Save New Payment Method
        </Button>
      </form>
    </div>
  );
});

function ModifyCardFormRoot(props) {
  return (
    <Elements fonts={[{ cssSrc: 'https://fonts.googleapis.com/css?family=Libre+Franklin:300,400' }]}>
      <ModifyCardForm {...props} />
    </Elements>
  );
}

export default ModifyCardFormRoot;
