export default {
  graphqlEndpoint: process.env.REACT_APP_APPSYNC_BE_GRAPHQL_ENDPOINT,
  region: process.env.REACT_APP_REGION,
  authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  userPoolId: process.env.REACT_APP_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
  identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
  Storage: {
    bucket: process.env.REACT_APP_POLICY_DOCS_S3_BUCKET,
    region: process.env.REACT_APP_REGION,
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID
  },
  Analytics: {
    disabled: true
  },
  stripeKey: process.env.REACT_APP_STRIPE_KEY,
  plaid: {
    publicKey: process.env.REACT_APP_PLAID_PUBLIC_KEY,
    env: process.env.REACT_APP_PLAID_ENV
  },
  segmentKey: process.env.REACT_APP_SEGMENT_KEY,
  logRocketApiKey: process.env.REACT_APP_LOGROCKET_API_KEY,
  trackingEnabled: process.env.REACT_APP_TRACKING_ENABLED === 'true',
  encryptKey: process.env.REACT_APP_BRANCH_AES_256_KEY,
  encryptIV: process.env.REACT_APP_BRANCH_AES_256_IV,
  quoteDomain: process.env.REACT_APP_QUOTE_DOMAIN
};
