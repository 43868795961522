import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import tableSortIcon from '../../assets/icons/table-sort.svg';
import theme from '../../theme';
import { capitalizeFirstLetter, formatDate, formatDollars } from '../../util/strings';
import PaymentMethodDisplay from './payment-method-display';

const styles = {
  tableContainerOuter: {
    width: '100%',
    display: 'flex',
    paddingTop: 32
  },
  tableContainerInner: {
    width: '100%',
    overflow: 'auto'
  },
  table: {
    borderCollapse: 'collapse',
    width: '100%'
  },
  bottomSpacer: {
    flexShrink: 0,
    height: 48
  },
  tableHeaderCell: {
    textAlign: 'unset',
    paddingBottom: 4,
    borderBottom: '1px solid #E3E4D6',
    fontFamily: theme.fonts.LibreFranklin,
    fontSize: 10,
    fontWeight: 500,
    textTransform: 'uppercase',
    color: theme.colors.pesto,
    whiteSpace: 'nowrap',
    paddingRight: 18
  },
  tableHeaderCellContentContainer: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  tableSortIconButton: {
    display: 'content-box',
    marginRight: 4
  },
  tableSortIcon: {
    height: 18,
    width: 18,
    transition: 'transform 0.15s ease'
  },
  flipped: {
    transform: 'rotate(180deg)'
  },
  tableBodyCell: {
    padding: '12px 18px 12px 0',
    borderBottom: '1px solid #E3E4D6',
    fontFamily: theme.fonts.LibreFranklin,
    fontSize: 16,
    color: theme.colors.turtleGreen,
    whiteSpace: 'nowrap'
  }
};

// Note: This table component's scroll behaviour isn't great. The header scrolls along with
// the rest of the table. Implementing a sticky header + scrolling body table is pretty complicated
// as we need to manually sync the widths of the cells in the header and body (and also the scroll offsets
// if we're going to allow scrolling horizontally as well). We won't reach the point where people
// have enough transactions to scroll for a while, but we will have to put some engineering
// effort into this soon when we can afford it.
function PreviousPaymentsTable({ classes, transactions }) {
  const [sortByDateDescending, setSortByDateDescending] = useState(true);

  const invertSortByDate = () => {
    setSortByDateDescending((current) => !current);
  };

  const sortedTransactionHistory = transactions
    .map((item) => ({ ...item, paymentDate: new Date(item.paymentDate) }))
    .sort((a, b) => (sortByDateDescending ? b.paymentDate - a.paymentDate : a.paymentDate - b.paymentDate));

  return (
    <>
      <div className={classes.tableContainerOuter}>
        <div className={classes.tableContainerInner}>
          <table className={classes.table}>
            <thead>
              <tr>
                <th className={classes.tableHeaderCell}>
                  <div className={classes.tableHeaderCellContentContainer}>
                    <IconButton onClick={invertSortByDate} className={classes.tableSortIconButton}>
                      <img
                        src={tableSortIcon}
                        alt="Table sort"
                        className={clsx(classes.tableSortIcon, {
                          [classes.flipped]: !sortByDateDescending
                        })}
                      />
                    </IconButton>
                    Date
                  </div>
                </th>
                <th className={classes.tableHeaderCell}>Amount</th>
                <th className={classes.tableHeaderCell}>Payment Method</th>
                <th className={classes.tableHeaderCell}>Status</th>
                <th className={classes.tableHeaderCell}>Refunded</th>
                <th className={classes.tableHeaderCell}>Net Paid</th>
              </tr>
            </thead>
            <tbody>
              {sortedTransactionHistory.map((transaction, idx) => (
                <tr key={idx}>
                  <td className={classes.tableBodyCell}>{formatDate(transaction.paymentDate)}</td>
                  <td className={classes.tableBodyCell}>{formatDollars(transaction.paymentAmount)}</td>
                  <td className={classes.tableBodyCell}>
                    <PaymentMethodDisplay
                      className={classes.tableBodyPaymentMethodDisplay}
                      method={transaction.paymentMethod}
                      brand={transaction.paymentMethodBrand}
                      last4={transaction.paymentMethodLast4}
                    />
                  </td>
                  <td className={classes.tableBodyCell}>{capitalizeFirstLetter(transaction.paymentStatus)}</td>
                  <td className={classes.tableBodyCell}>{formatDollars(transaction.paymentRefunded)}</td>
                  <td className={classes.tableBodyCell}>
                    {formatDollars(transaction.paymentAmount - transaction.paymentRefunded)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className={classes.bottomSpacer} />
    </>
  );
}

PreviousPaymentsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  transactions: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default withStyles(styles)(PreviousPaymentsTable);
