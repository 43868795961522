import { makeStyles, Tooltip as MUITooltip } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import infoIcon from '../assets/icons/info.svg';
import theme from '../theme';

const useStyles = makeStyles({
  layout: {
    position: 'absolute',
    bottom: '0',
    right: -'20px'
  },
  infoIcon: {
    height: '20px',
    width: '20px',
    paddingLeft: '2px'
  },
  tooltipPopper: {
    opacity: '1'
  },
  tooltipContainer: {
    backgroundColor: theme.colors.hunterGreen,
    color: 'white',
    fontSize: '12px'
  }
});

const Tooltip = ({ text, className, layoutOverrideClassName }) => {
  const styles = useStyles();

  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <MUITooltip
      title={text}
      placement="top"
      interactive
      onMouseEnter={() => setTooltipOpen(true)}
      onMouseLeave={() => setTooltipOpen(false)}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      open={tooltipOpen}
      className={layoutOverrideClassName || styles.layout}
      styles={{ tooltip: styles.tooltipContainer, popperInteractive: styles.tooltipPopper }}
    >
      <img src={infoIcon} className={clsx(styles.infoIcon, className)} alt={text} />
    </MUITooltip>
  );
};

Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  layoutOverrideClassName: PropTypes.string
};

Tooltip.defaultProps = {
  className: '',
  layoutOverrideClassName: ''
};

export default Tooltip;
