import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import theme from '../../theme';

const styles = {
  listItemTextPrimary: {
    fontSize: 10,
    fontWeight: 500,
    color: theme.colors.pesto,
    textTransform: 'uppercase',
    paddingLeft: 10
  },
  listItemTextSecondary: {
    fontSize: 16,
    color: theme.colors.hunterGreen,
    paddingLeft: 10
  }
};

const CardListItemText = withStyles(styles)(({ classes, primary, secondary, ...props }) => (
  <ListItemText
    classes={{ primary: classes.listItemTextPrimary, secondary: classes.listItemTextSecondary }}
    primary={primary}
    secondary={secondary}
    {...props}
  />
));

CardListItemText.propTypes = {
  primary: PropTypes.string,
  secondary: PropTypes.string
};

CardListItemText.defaultProps = {
  primary: '',
  secondary: ''
};

export default CardListItemText;
