import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import Card from '../../../../components/card';
import CardButton from '../../../../components/card/card-button';
import CardContent from '../../../../components/card/card-content';
import CardFieldGroup from '../../../../components/card/card-field-group';
import CardLabel from '../../../../components/card/card-label';
import CardText from '../../../../components/card/card-text';
import CardTitle from '../../../../components/card/card-title';
import { formatDollars, prettifyLiabilityString } from '../../../../util/strings';

const RentersCoverageCard = ({ className, autoPolicyData }) => (
  <Card cardColor="dark" className={className}>
    <CardContent>
      <CardTitle>Renters</CardTitle>
      <CardFieldGroup>
        <CardLabel>Personal Liability Coverage</CardLabel>
        <CardText>{formatDollars(autoPolicyData.policyDetails.rentersCoverage.coverageX)}</CardText>
      </CardFieldGroup>
      <CardFieldGroup>
        <CardLabel>Personal Property / Living Expenses</CardLabel>
        <CardText>
          {prettifyLiabilityString(autoPolicyData.policyDetails.rentersCoverage.coverageCD, {
            interpretAsThousands: false
          })}
        </CardText>
      </CardFieldGroup>
      <Link to={`/app/auto/${autoPolicyData.id}`}>
        <CardButton>See More</CardButton>
      </Link>
    </CardContent>
  </Card>
);

RentersCoverageCard.propTypes = {
  className: PropTypes.string,
  autoPolicyData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    policyDetails: PropTypes.shape({
      rentersCoverage: PropTypes.shape({
        coverageX: PropTypes.number.isRequired,
        coverageCD: PropTypes.string.isRequired
      }).isRequired
    }).isRequired
  }).isRequired
};

RentersCoverageCard.defaultProps = {
  className: ''
};

export default RentersCoverageCard;
