import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import theme from '../theme';

const useStyles = makeStyles({
  textField: {
    fontSize: '16px',
    color: theme.colors.pesto,
    height: 36,
    padding: 8,
    border: 'none',
    '&::placeholder': {
      color: theme.colors.tallow,
      fontSize: 14
    }
  },
  label: {
    height: 14,
    fontSize: 10,
    fontWeight: 500,
    lineHeight: 1.4,
    color: theme.colors.pesto,
    textTransform: 'uppercase',
    marginBottom: 4
  },
  fullWidth: {
    width: '100%'
  }
});

const TextField = ({ className, containerClassName, fullWidth, label, ...props }) => {
  const styles = useStyles();
  return (
    <div className={containerClassName}>
      {Boolean(label) && <div className={styles.label}>{label}</div>}
      <input className={clsx(styles.textField, { [styles.fullWidth]: fullWidth }, className)} {...props} />
    </div>
  );
};

TextField.propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  fullWidth: PropTypes.bool,
  label: PropTypes.string
};

TextField.defaultProps = {
  className: '',
  containerClassName: '',
  fullWidth: false,
  label: ''
};

export default TextField;
