import PropTypes from 'prop-types';
import React from 'react';
import CardFieldGroup from '../../../components/card/card-field-group';
import CardLabel from '../../../components/card/card-label';
import CardSubtitle2 from '../../../components/card/card-subtitle-2';
import CardText from '../../../components/card/card-text';
import { TOOLTIPS } from '../../../util/constants';
import { basementType, constructionType, exteriorWallType, typeOfHome } from '../../../util/terminology';

const PropertyColumn = ({ className, homePolicyData }) => {
  const { home } = homePolicyData.policyDetails;

  return (
    <div className={className}>
      <CardSubtitle2>Property</CardSubtitle2>
      <CardFieldGroup>
        <CardLabel>Built In</CardLabel>
        <CardText>{home.yearBuilt}</CardText>
      </CardFieldGroup>
      <CardFieldGroup>
        <CardLabel>Home Type</CardLabel>
        <CardText>{typeOfHome[home.typeOfHome]}</CardText>
      </CardFieldGroup>
      <CardFieldGroup>
        <CardLabel tooltip={TOOLTIPS.HOME_INFO_FAMILIES_IN_DWELLING}>Families in Dwelling</CardLabel>
        <CardText>{home.numFamiliesInDwelling}</CardText>
      </CardFieldGroup>
      <CardFieldGroup>
        <CardLabel tooltip={TOOLTIPS.HOME_INFO_NUMBER_OF_MORTGAGES}>Number of Mortgages</CardLabel>
        <CardText>{home.mortgageDetails.length}</CardText>
      </CardFieldGroup>
      <CardFieldGroup>
        <CardLabel tooltip={TOOLTIPS.HOME_INFO_CONSTRUCTION_TYPE}>Construction Type</CardLabel>
        <CardText>{constructionType[home.constructionType]}</CardText>
      </CardFieldGroup>
      <CardFieldGroup>
        <CardLabel tooltip={TOOLTIPS.HOME_INFO_BASEMENT_TYPE}>Basement Type</CardLabel>
        <CardText>{basementType[home.basementType]}</CardText>
      </CardFieldGroup>
      <CardFieldGroup>
        <CardLabel>Basement SqFt</CardLabel>
        <CardText>{home.basementSqFt.toLocaleString('en-US', { minimumFractionDigits: 2 })} sqft</CardText>
      </CardFieldGroup>
      <CardFieldGroup>
        <CardLabel tooltip={TOOLTIPS.HOME_INFO_EXTERIOR_WALL_TYPE}>Exterior Wall Type</CardLabel>
        <CardText>{exteriorWallType[home.exteriorWallType]}</CardText>
      </CardFieldGroup>
    </div>
  );
};

PropertyColumn.propTypes = {
  className: PropTypes.string,
  homePolicyData: PropTypes.shape({
    policyDetails: PropTypes.shape({
      home: PropTypes.shape({
        yearBuilt: PropTypes.number.isRequired,
        typeOfHome: PropTypes.string.isRequired,
        numFamiliesInDwelling: PropTypes.number.isRequired,
        mortgageDetails: PropTypes.array.isRequired,
        constructionType: PropTypes.string.isRequired,
        basementType: PropTypes.string.isRequired,
        basementSqFt: PropTypes.number.isRequired,
        exteriorWallType: PropTypes.string.isRequired
      }).isRequired
    }).isRequired
  }).isRequired
};

PropertyColumn.defaultProps = {
  className: ''
};

export default PropertyColumn;
