import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Button from '../../../../../components/button';
import Card from '../../../../../components/card';
import CardContent from '../../../../../components/card/card-content';
import theme from '../../../../../theme';
import StressFreeSwitchDialog from './stress-free-switch-dialog';

const useStyles = makeStyles({
  card: {
    backgroundColor: theme.colors.lightBeige
  },
  title: {
    fontSize: 16,
    fontWeight: 300,
    color: theme.colors.hunterGreen
  },
  text: {
    fontSize: '32px',
    lineHeight: 1.25,
    fontWeight: 100,
    color: theme.colors.hunterGreen
  },
  button: {
    marginTop: 24,
    width: '100%'
  }
});

const StressFreeSwitchCard = ({ className, onComplete, priorHomeInsuranceProvider, priorAutoInsuranceProvider }) => {
  const styles = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <Card className={clsx(styles.card, className)}>
        <CardContent>
          <div className={styles.title}>Introducing Stress-Free Switch</div>
          <div className={styles.text}>Want us to cancel your prior insurance policy for you?</div>
          <Button primary className={styles.button} onClick={() => setDialogOpen(true)}>
            Continue
          </Button>
        </CardContent>
      </Card>
      <StressFreeSwitchDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onComplete={onComplete}
        initialHomeInsuranceProvider={priorHomeInsuranceProvider}
        initialAutoInsuranceProvider={priorAutoInsuranceProvider}
      />
    </>
  );
};

StressFreeSwitchCard.propTypes = {
  className: PropTypes.string,
  onComplete: PropTypes.func.isRequired,
  priorHomeInsuranceProvider: PropTypes.string,
  priorAutoInsuranceProvider: PropTypes.string
};

StressFreeSwitchCard.defaultProps = {
  className: '',
  priorHomeInsuranceProvider: '',
  priorAutoInsuranceProvider: ''
};

export default StressFreeSwitchCard;
