import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import DocumentsColumn from '../../../components/documents-column';
import Masonry from '../../../components/masonry';
import CarsColumn from './cars-column';
import DriversColumn from './drivers-column';
import PolicyDataColumn from './policy-data-column';

const styles = {
  container: {
    marginBottom: 24
  }
};

const AutoPolicySection = ({ classes, className, autoPolicyData }) => (
  <div className={clsx(classes.container, className)}>
    <Masonry>
      <PolicyDataColumn autoPolicyData={autoPolicyData} />
      <DriversColumn autoPolicyData={autoPolicyData} />
      <CarsColumn autoPolicyData={autoPolicyData} />
      <DocumentsColumn policyId={autoPolicyData.id} />
    </Masonry>
  </div>
);

AutoPolicySection.propTypes = {
  autoPolicyData: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string
};

AutoPolicySection.defaultProps = {
  className: ''
};

export default withStyles(styles)(AutoPolicySection);
