import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ForgotPasswordStep1 from './screens/forgot-password-step-1';
import ForgotPasswordStep2 from './screens/forgot-password-step-2';
import Initial from './screens/initial';
import PhoneSignInStep1 from './screens/phone-sign-in-step-1';
import PhoneSignInStep2 from './screens/phone-sign-in-step-2';
import RequireNewPassword from './screens/require-new-password';
import Root from './screens/root';
import SignIn from './screens/sign-in';
import UserBeingAuthenticatedContainer from './user-being-authenticated-container';

const Auth = () => (
  <>
    <Route
      path="/auth"
      render={() => (
        <UserBeingAuthenticatedContainer.Provider>
          <Root>
            <Switch>
              <Route path="/auth" exact component={Initial} />
              <Route path="/auth/signin" exact component={SignIn} />
              <Route path="/auth/newpassword" exact component={RequireNewPassword} />
              <Route path="/auth/phonesigninstep1" exact component={PhoneSignInStep1} />
              <Route path="/auth/phonesigninstep2" exact component={PhoneSignInStep2} />
              <Route path="/auth/forgotpasswordstep1" exact component={ForgotPasswordStep1} />
              <Route path="/auth/forgotpasswordstep2" exact component={ForgotPasswordStep2} />
              <Route render={() => <Redirect to="/auth" />} />
            </Switch>
          </Root>
        </UserBeingAuthenticatedContainer.Provider>
      )}
    />
  </>
);

export default Auth;
