import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { CardStyleContext } from '.';
import theme from '../../theme';

const styles = {
  common: {
    fontSize: 20
  },
  dark: {
    composes: '$common',
    color: theme.colors.beige
  },
  black: {
    composes: '$common',
    color: theme.colors.beige
  },
  green: {
    composes: '$common',
    color: theme.colors.beige
  },
  light: {
    composes: '$common',
    color: theme.colors.turtleGreen
  }
};

const CardTitle = ({ className, classes, children }) => (
  <CardStyleContext.Consumer>
    {(cardStyle) => (
      <div
        className={clsx(className, {
          [classes.dark]: cardStyle === 'dark',
          [classes.light]: cardStyle === 'light',
          [classes.black]: cardStyle === 'black',
          [classes.green]: cardStyle === 'green'
        })}
      >
        {children}
      </div>
    )}
  </CardStyleContext.Consumer>
);

CardTitle.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  children: PropTypes.node
};

CardTitle.defaultProps = {
  className: '',
  children: null
};

export default withStyles(styles)(CardTitle);
