import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  iconField: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: 150
  },
  icon: {
    height: 24,
    width: 24,
    marginRight: 3
  }
};

const IconField = ({ classes, className, iconSrc, iconAlt, children }) => (
  <div className={clsx(classes.iconField, className)}>
    <img src={iconSrc} alt={iconAlt} className={classes.icon} />
    {children}
  </div>
);

IconField.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  iconSrc: PropTypes.string.isRequired,
  iconAlt: PropTypes.string.isRequired,
  children: PropTypes.node
};

IconField.defaultProps = {
  className: '',
  children: null
};

export default withStyles(styles)(IconField);
