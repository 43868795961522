import Auth from '@aws-amplify/auth';
import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { analyticsIdentifyUser } from '../auth/helpers';

export default function ProtectedRoute(props) {
  const [shouldRenderRoute, setShouldRenderRoute] = useState(false);
  const [redirectToAuth, setRedirectToAuth] = useState(false);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user.username === 'anonymous') {
          Auth.signOut().then(() => setRedirectToAuth(true));
          return;
        }
        analyticsIdentifyUser(user);
        setShouldRenderRoute(true);
      })
      .catch(() => {
        setRedirectToAuth(true);
      });
  }, [setShouldRenderRoute, setRedirectToAuth]);

  if (redirectToAuth) {
    return <Redirect to="/auth" />;
  }

  if (shouldRenderRoute) {
    return <Route {...props} />;
  }

  return null;
}
