import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  container: {
    marginTop: 16,
    '&:first-child': {
      marginTop: 0
    }
  }
};

const CardFieldGroup = ({ className, classes, children }) => (
  <div className={clsx(classes.container, className)}>{children}</div>
);

CardFieldGroup.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  children: PropTypes.node
};

CardFieldGroup.defaultProps = {
  className: '',
  children: null
};

export default withStyles(styles)(CardFieldGroup);
