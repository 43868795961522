import PropTypes from 'prop-types';
import React from 'react';
import Card from '../../../../components/card';
import CardContent from '../../../../components/card/card-content';
import CardFieldGroup from '../../../../components/card/card-field-group';
import CardLabel from '../../../../components/card/card-label';
import CardText from '../../../../components/card/card-text';
import CardTitle from '../../../../components/card/card-title';
import { joinIgnoringFalsy } from '../../../../util/strings';
import * as terminology from '../../../../util/terminology';
import PrimaryDriverLabel from './primary-driver-label';

const DriverCard = ({ className, driver }) => {
  const name = joinIgnoringFalsy([driver.firstName, driver.middleName, driver.lastName]);

  const gender = terminology.gender[driver.gender] || 'Other';

  const maritalStatus = terminology.maritalStatus[driver.maritalStatus] || 'Unknown';

  const highestEducation =
    (terminology.education[driver.highestEducation] && terminology.education[driver.highestEducation].name) ||
    'Unknown';

  return (
    <Card cardColor="light" className={className}>
      <CardContent>
        {driver.isPrimary && <PrimaryDriverLabel />}
        <CardTitle>{name}</CardTitle>
        <CardFieldGroup>
          <CardLabel>Gender</CardLabel>
          <CardText>{gender}</CardText>
        </CardFieldGroup>
        <CardFieldGroup>
          <CardLabel>Marital Status</CardLabel>
          <CardText>{maritalStatus}</CardText>
        </CardFieldGroup>
        <CardFieldGroup>
          <CardLabel>Highest Education Achieved</CardLabel>
          <CardText>{highestEducation}</CardText>
        </CardFieldGroup>
        <CardFieldGroup>
          <CardLabel>Licensed in State</CardLabel>
          <CardText>{driver.driversLicenseState}</CardText>
        </CardFieldGroup>
        <CardFieldGroup>
          <CardLabel>First Licensed At Age</CardLabel>
          <CardText>{driver.ageFirstLicensed}</CardText>
        </CardFieldGroup>
      </CardContent>
    </Card>
  );
};

DriverCard.propTypes = {
  className: PropTypes.string,
  driver: PropTypes.shape({
    firstName: PropTypes.string,
    middleName: PropTypes.string,
    lastName: PropTypes.string,
    age: PropTypes.number.isRequired,
    gender: PropTypes.string.isRequired,
    maritalStatus: PropTypes.string,
    isPrimary: PropTypes.bool.isRequired,
    highestEducation: PropTypes.string.isRequired,
    ageFirstLicensed: PropTypes.number.isRequired,
    driversLicenseState: PropTypes.string.isRequired
  }).isRequired
};

DriverCard.defaultProps = {
  className: ''
};

export default DriverCard;
