/* eslint-disable react/no-find-dom-node */
/**
 * A version of material-ui's RootRef component that spreads any props its not using onto its children
 * https://github.com/mui-org/material-ui/blob/f8f787abd296a57ec15f1e2ea311b3e08318fef7/packages/material-ui/src/RootRef/RootRef.js
 */

import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';

function setRef(ref, value) {
  if (typeof ref === 'function') {
    ref(value);
  } else if (ref) {
    ref.current = value; // eslint-disable-line no-param-reassign
  }
}

export default class RootRef extends React.Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
    rootRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired
  };

  componentDidMount() {
    this.ref = ReactDOM.findDOMNode(this);
    setRef(this.props.rootRef, this.ref);
  }

  componentDidUpdate(prevProps) {
    const ref = ReactDOM.findDOMNode(this);

    if (prevProps.rootRef !== this.props.rootRef || this.ref !== ref) {
      if (prevProps.rootRef !== this.props.rootRef) {
        setRef(prevProps.rootRef, null);
      }

      this.ref = ref;
      setRef(this.props.rootRef, this.ref);
    }
  }

  componentWillUnmount() {
    this.ref = null;
    setRef(this.props.rootRef, null);
  }

  render() {
    const { rootRef, children, ...remainingProps } = this.props;
    return React.cloneElement(children, remainingProps);
  }
}
