import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import goToIcon from '../../assets/icons/go-to.svg';
import Button from '../button';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 24
  },
  icon: {
    height: 24,
    width: 24
  }
});

const CardButton = ({ className, ...props }) => {
  const styles = useStyles();

  return (
    <div className={clsx(styles.container, className)}>
      <Button {...props} />
      <img src={goToIcon} alt="Go to" className={styles.icon} />
    </div>
  );
};

CardButton.propTypes = {
  className: PropTypes.string
};

CardButton.defaultProps = {
  className: ''
};

export default CardButton;
