import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import theme from '../../theme';

const styles = {
  cardListItem: {
    '&:hover': {
      backgroundColor: theme.colors.linen
    },
    '@media(max-width: 414px)': {
      paddingLeft: 9
    }
  }
};

const CardListDivider = withStyles(styles)(({ classes, className }) => (
  <Divider className={clsx(classes.divider, className)} />
));

CardListDivider.propTypes = {
  className: PropTypes.string
};
CardListDivider.defaultProps = {
  className: ''
};

export default CardListDivider;
